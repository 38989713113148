import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Container,
    DialogContentContainer,
    DialogInfoColumn,
    DialogExampleExplanation,
} from './CameraSetupDialogs.style';

import DialogComponent from 'components/shared/DialogComponent/DialogComponent';

import { ReactComponent as CheckmarkIcon } from 'assets/icons/Picture_Steps_checkmark_icon.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/Picture_Steps_incorrect_cross_icon.svg';
import { ReactComponent as Step1Picture1 } from 'assets/images/Step_1_picture_1.svg';
import { ReactComponent as Step1Picture2 } from 'assets/images/Step_1_picture_2.svg';
import { ReactComponent as Step1Picture3 } from 'assets/images/Step_1_picture_3.svg';
import { ReactComponent as Step2Picture1 } from 'assets/images/Step_2_picture_1.svg';
import { ReactComponent as Step2Picture2 } from 'assets/images/Step_2_picture_2.svg';
import { ReactComponent as Step2Picture3 } from 'assets/images/Step_2_picture_3.svg';
import { ReactComponent as Step3Picture1 } from 'assets/images/Step_3_picture_1.svg';
import { ReactComponent as Step3Picture2 } from 'assets/images/Step_3_picture_2.svg';
import { ReactComponent as Step3Picture3 } from 'assets/images/Step_3_picture_3.svg';
import { ReactComponent as Step4Picture1 } from 'assets/images/Step_4_picture_1.svg';
import { ReactComponent as Step4Picture2 } from 'assets/images/Step_4_picture_2.svg';
import { ReactComponent as Step4Picture3 } from 'assets/images/Step_4_picture_3.svg';
import { DescWrapper } from '../CameraSetup/CameraSetup.style';

const CameraSetupDialogs = (props) => {
    const { t } = useTranslation();
    const {
        showStepOneDialog,
        closeStepOneDialog,
        showStepTwoDialog,
        closeStepTwoDialog,
        showStepThreeDialog,
        closeStepThreeDialog,
        showStepFourDialog,
        closeStepFourDialog,
        showHelpCamera,
        closeHelpCamera,
    } = props;

    return (
        <Container>
            {showHelpCamera && (
                <DialogComponent
                    isShown={showHelpCamera}
                    onClose={closeHelpCamera}
                    title={t('CAMERA_SETUP_VIEW.SET_UP_CAMERA.HELP_TITLE')}>
                    <DescWrapper className={'desc-modal'}>
                        <div>
                            {t('CAMERA_SETUP_VIEW.SET_UP_CAMERA.HELP_DESC')}
                        </div>
                    </DescWrapper>
                </DialogComponent>
            )}
            {showStepOneDialog && (
                <DialogComponent
                    isShown={showStepOneDialog}
                    onClose={closeStepOneDialog}
                    title={t(
                        'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_1.TITLE',
                    )}>
                    <DialogContentContainer>
                        <DialogInfoColumn>
                            <CheckmarkIcon
                                style={{ transform: 'scale(0.75)' }}
                            />
                            <Step1Picture1 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_1.GOOD_EXAMPLE',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                        <DialogInfoColumn>
                            <CrossIcon style={{ transform: 'scale(0.75)' }} />
                            <Step1Picture2 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_1.BAD_EXAMPLE_1',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                        <DialogInfoColumn>
                            <CrossIcon style={{ transform: 'scale(0.75)' }} />
                            <Step1Picture3 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_1.BAD_EXAMPLE_2',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                    </DialogContentContainer>
                </DialogComponent>
            )}

            {showStepTwoDialog && (
                <DialogComponent
                    isShown={showStepTwoDialog}
                    onClose={closeStepTwoDialog}
                    title={t(
                        'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_2.TITLE',
                    )}>
                    <DialogContentContainer>
                        <DialogInfoColumn>
                            <CheckmarkIcon
                                style={{ transform: 'scale(0.75)' }}
                            />
                            <Step2Picture1 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_2.GOOD_EXAMPLE',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                        <DialogInfoColumn>
                            <CrossIcon style={{ transform: 'scale(0.75)' }} />
                            <Step2Picture2 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_2.BAD_EXAMPLE_1',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                        <DialogInfoColumn>
                            <CrossIcon style={{ transform: 'scale(0.75)' }} />
                            <Step2Picture3 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_2.BAD_EXAMPLE_2',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                    </DialogContentContainer>
                </DialogComponent>
            )}

            {showStepThreeDialog && (
                <DialogComponent
                    isShown={showStepThreeDialog}
                    onClose={closeStepThreeDialog}
                    title={t(
                        'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_3.TITLE',
                    )}>
                    <DialogContentContainer>
                        <DialogInfoColumn>
                            <CheckmarkIcon
                                style={{ transform: 'scale(0.75)' }}
                            />
                            <Step3Picture1 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_3.GOOD_EXAMPLE',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                        <DialogInfoColumn>
                            <CrossIcon style={{ transform: 'scale(0.75)' }} />
                            <Step3Picture2 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_3.BAD_EXAMPLE_1',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                        <DialogInfoColumn>
                            <CrossIcon style={{ transform: 'scale(0.75)' }} />
                            <Step3Picture3 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_3.BAD_EXAMPLE_2',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                    </DialogContentContainer>
                </DialogComponent>
            )}

            {showStepFourDialog && (
                <DialogComponent
                    isShown={showStepFourDialog}
                    onClose={closeStepFourDialog}
                    title={t(
                        'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_4.TITLE',
                    )}>
                    <DialogContentContainer>
                        <DialogInfoColumn>
                            <CheckmarkIcon
                                style={{ transform: 'scale(0.75)' }}
                            />
                            <Step4Picture1 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_4.GOOD_EXAMPLE',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                        <DialogInfoColumn>
                            <CrossIcon style={{ transform: 'scale(0.75)' }} />
                            <Step4Picture2 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_4.BAD_EXAMPLE_1',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                        <DialogInfoColumn>
                            <CrossIcon style={{ transform: 'scale(0.75)' }} />
                            <Step4Picture3 />
                            <DialogExampleExplanation>
                                {t(
                                    'CAMERA_SETUP_VIEW.SET_UP_CAMERA_INFO_DIALOG_STEP_4.BAD_EXAMPLE_2',
                                )}
                            </DialogExampleExplanation>
                        </DialogInfoColumn>
                    </DialogContentContainer>
                </DialogComponent>
            )}
        </Container>
    );
};

export default CameraSetupDialogs;
