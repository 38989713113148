import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: relative;
`;
export const Text = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: #1aa6ad;
    width: 100%;
    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
    svg {
        width: 31px;
        height: 31px;
    }
    p {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 85%;
    }
`;
export const CopyTooltip = styled('div')`
    position: absolute;
    top: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 27px;
    padding: 0 10px;
    gap: 6px;
    font-weight: 500;
    color: white;
    font-size: 11px;
    line-height: 27px;
    background: #c8ced5;
    border-radius: 70px;
    cursor: pointer;
    transition: visibility 0.5s, color 0.5s, background-color 0.5s, height 0.5s,
        padding 0.5s ease-in-out;
`;
