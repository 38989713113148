import { createTheme } from '@material-ui/core/styles'

export const theme = {
    mainColor: '#00A5AE',
    darkerMainColor: '#00939B',
    textColor: '#435970',
    secondaryTextColor: '#70879D',
    backgroundColor: '#FFF',
    secondaryBackgroundColor: '#F7FBFF'
};

export const materialTheme = createTheme({
  palette: {
    primary: {
	  main: theme.mainColor,
    },
    secondary: {
        main: theme.mainColor,
    },
	contrastThreshold: 3,
	tonalOffset: 0,
  },
  typography: {
    useNextVariants: true,
  },
});