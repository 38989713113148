import UserAgentParser from 'ua-parser-js';
const { detect } = require('detect-browser');
const browser = detect();

const parser = new UserAgentParser();
parser.setUA(navigator.userAgent);
const userAgent = parser.getResult();

export function getViewportData() {
    return {
        width: window.screen.width,
        height: window.screen.height,
        pixelRatio: window.devicePixelRatio,
    };
}

export function getWindowData() {
    return {
        size: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        position: {
            x: window.screenLeft || window.screenX,
            y: window.screenTop || window.screenY,
        },
    };
}

export function getBrowserData() {
    return userAgent.browser
    
}

export function getDeviceData() {
    return {
        engine: userAgent.engine,
        os: userAgent.os
    }
}

export function getBrowserName() {
    return browser && browser.name;
}