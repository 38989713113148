import configActionTypes from './config.types';

export const configInitialState = {
  companies: {},
  scans: {},
  loading: true,
  error: null,
};

const configReducer = (state = configInitialState, action) => {
  switch (action.type) {
    case configActionTypes.STORE_SCAN_IDS: {
      const { companySlug, assessmentId, invitationId } = action.payload;
      return {
        ...state,
        scans: {
          ...state.scans,
          [invitationId]: {
            ...state.scans[invitationId],
            companySlug,
            assessmentId,
            invitationId,
          },
        },
      };
    }

    case configActionTypes.FETCH_COMPANY: {
      return {
        ...state,
        loading: true,
      };
    }

    case configActionTypes.FETCH_COMPANY_SUCCESS: {
      const { companySlug, companyName, defaultLanguage, logoUrl, country } =
        action.payload;
      return {
        ...state,
        companies: {
          ...state.companies,
          [companySlug]: {
            ...state.companies[companySlug],
            companyName,
            defaultLanguage,
            logoUrl,
            country,
          },
        },
        // loading: false,
      };
    }

    case configActionTypes.FETCH_COMPANY_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case configActionTypes.FETCH_CONFIG: {
      return {
        ...state,
        loading: true,
      };
    }

    case configActionTypes.FETCH_CONFIG_SUCCESS: {
      const {
        assessmentId,
        targetGroupId,
        targetGroupName,
        invitationId,
        companySlug,
        modules,
        config,
        language,
        assessmentName,
        description,
        autoAnalysis,
        dataCollection,
        isCompleted,
      } = action.payload;
      return {
        ...state,
        scans: {
          ...state.scans,
          [invitationId]: {
            ...state.scans[invitationId],
            targetGroupId,
            targetGroupName,
            assessmentId,
            companySlug,
            modules,
            config,
            language,
            assessmentName,
            description,
            autoAnalysis,
            dataCollection,
            isCompleted,
          },
        },
        loading: false,
      };
    }

    case configActionTypes.FETCH_CONFIG_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case configActionTypes.FINISH_SCAN_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error,
      };
    }

    default:
      return state;
  }
};

export default configReducer;
