import configActionTypes from './config.types';

export const storeScanIds = (companySlug, assessmentId, invitationId) => ({
    type: configActionTypes.STORE_SCAN_IDS,
    payload: { companySlug, assessmentId, invitationId },
});

export const fetchCompany = (companySlug, navigate) => ({
    type: configActionTypes.FETCH_COMPANY,
    payload: { companySlug, navigate },
});

export const fetchConfig = (assessmentId, targetGroupId, invitationId) => ({
    type: configActionTypes.FETCH_CONFIG,
    payload: { assessmentId, targetGroupId, invitationId },
});

export const finishScan = (assessmentId, invitationId) => ({
    type: configActionTypes.FINISH_SCAN,
    payload: { assessmentId, invitationId },
});
