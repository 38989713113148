import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './store';
import ErrorBoundary from 'components/shared/ErrorBoundary/ErrorBoundary';
import * as Sentry from '@sentry/browser';
import 'webrtc-adapter';
import { materialTheme, theme } from './config/theme';
import { APP_ENVIRONMENT } from 'utils/environment-variables';
import 'react-hook-consent/dist/styles/style.css';
import CookieConsentWrapper from './CookieConsentWrapper';

const container = document.getElementById('root');
const root = createRoot(container);

if (APP_ENVIRONMENT === 'PROD') {
    Sentry.init({
        dsn: 'https://4c845116927e4a36b8a0269988bcbea9@sentry.io/1474423',
        environment: APP_ENVIRONMENT,
        beforeBreadcrumb(breadcrumb, hint) {
            return breadcrumb.category === 'ui.click' ? null : breadcrumb;
        },
    });
}

export const renderApp = (props) => {
    root.render(
        <React.StrictMode>
            <ErrorBoundary>
                <Router>
                    <Provider store={store}>
                        <CookieConsentWrapper {...props} />
                    </Provider>
                </Router>
            </ErrorBoundary>
        </React.StrictMode>
    );
};

renderApp({ theme, materialTheme });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
