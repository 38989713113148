import styled from 'styled-components';
import {breakpoints} from "../../../App.style";

export const Container = styled.div`
    padding: 8px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4vw;
    }
`;
export const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    @media only screen and (min-width: ${breakpoints.desktop}) {
        width: 70%;
    }
`;
export const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    color: #42596f;
    span {
        color: #00a5ac;
    }
`;
export const Description = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: rgba(66, 89, 111, 0.85);
    &.copy-link{
        align-self: start;
        padding-inline-start: 1em;
    }
`;

export const Button = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 130px;
    height: 30px;
    background: #b8f0c7;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #1aa277;
    &.disabled {
        background: rgba(217, 217, 217, 0.6);
        color: rgba(66, 89, 111, 0.61);
    }
`;
export const BrowserWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    align-self: center;
`;
export const Browser = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #42596f;
    &.disabled {
        opacity: 0.7;
    }
`;
export const Header = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 2em;
`;

export const LogoContainer = styled.div`
    display: flex;
    width: 220px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2em;
    height: 40px;

    img {
        width: 50%;
    }
`;
export const LanguageSwitcherContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1244px;
    margin-top: 2em;
    align-items: flex-start;
    height: 40px;
`;
