export const APP_ENVIRONMENT: 'PROD' | 'DEV' | 'TEST' | 'LOCAL' =
    process.env.REACT_APP_ENVIRONMENT;
export const NEUROLYTICS_API = process.env.REACT_APP_NEUROLYTICS_API;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const SCAN_URL =
    process.env.REACT_APP_SCAN_URL || 'https://scan.neurolytics.ai';
export const LOGGER_HOST = process.env.REACT_APP_LOGGER_HOST;
export const CLARITY_PROJECT_ID = process.env.REACT_APP_CLARITY_PROJECT_ID;
