import { put, select, call } from 'redux-saga/effects';
import { cameraActions, cameraActionTypes } from './camera.actions';
import { uploadsActions } from '../uploads/uploads.actions';
import Logger from 'services/Logger';
import CameraService from 'services/CameraService';

export function* captureUserMedia() {
    try {
        const isApiSupported = yield call([
            CameraService,
            'isGetUserMediaSupported',
        ]);
        if (!isApiSupported) {
            Logger.logWarning(
                'CameraEffects - captureUserMedia - GetUserMedia is not supported',
                isApiSupported,
            );

            yield put({
                type: cameraActionTypes.CAPTURE_USER_MEDIA_FAILURE,
                payload: {
                    error: new Error(
                        'getUserMedia is not implemented in this browser',
                    ),
                },
            });
        } else {
            Logger.logInfo(
                'CameraEffects - captureUserMedia - GetUserMedia is supported',
                isApiSupported,
            );

            yield call([CameraService, 'startVideoStream']);
            yield put({
                type: cameraActionTypes.CAPTURE_USER_MEDIA_SUCCESS,
            });
        }
    } catch (error) {
        Logger.logError(
            'CameraEffects - captureUserMedia - Failed to start video stream',
            error,
        );
        yield put({
            type: cameraActionTypes.CAPTURE_USER_MEDIA_FAILURE,
            payload: { error: error },
        });
    }
}

export function* stopCapturingUserMedia() {
    try {
        yield call([CameraService, 'stopVideoStream']);
        Logger.logInfo(
          'CameraEffects - stopCapturingUserMedia - Stopped capturing user media',
        );
        yield put({
            type: cameraActionTypes.STOP_CAPTURING_USER_MEDIA_SUCCESS,
        });
    } catch (error) {
        Logger.logError(
            'CameraEffects - stopCapturingUserMedia - Failed to stop video stream',
            error,
        );
        yield put({
            type: cameraActionTypes.STOP_CAPTURING_USER_MEDIA_FAILURE,
            payload: { error: error },
        });
    }
}

export function* recordUserMedia(action) {
    const { invitationId, fileId, autoUpload } = action.payload;

    // Start capturing videoStream if it's not already captured and stored on the state
    if (!CameraService.videoStream) {
        Logger.logInfo(
            'CameraEffects - recordUserMedia - Video stream not found. Capturing a new stream.',
        );
        yield captureUserMedia();
    } else {
        Logger.logInfo(
            'CameraEffects - recordUserMedia - Found video stream. Skipped capturing a new stream.',
        );
    }

    if (CameraService.videoStream) {
        try {
            yield put({
                type: cameraActionTypes.SET_BLOB_DURATION,
                payload: { blobDuration: null },
            });
            yield call([CameraService, 'setBlob'], null);
            yield call([CameraService, 'startRecording']);
            Logger.logInfo('CameraEffects - Started recording user media', {
                invitationId,
                fileId,
            });
            yield put({
                type: cameraActionTypes.RECORD_USER_MEDIA_SUCCESS,
                payload: {
                    fileId,
                    autoUpload,
                },
            });
        } catch (error) {
            Logger.logError(
                `CameraEffects - recordUserMedia - Failed to record user media invitationId=${invitationId} fileId=${fileId} autoUpload=${autoUpload}`,
                error,
            );
            yield put({
                type: cameraActionTypes.RECORD_USER_MEDIA_FAILURE,
                payload: { error },
            });
        }
    } else {
        const error = new Error('Video stream not found');
        Logger.logError(
            `CameraEffects - recordUserMedia - Video stream not found invitationId=${invitationId} fileId=${fileId}`,
            error,
        );
        yield put({
            type: cameraActionTypes.RECORD_USER_MEDIA_FAILURE,
            payload: { error },
        });
    }
}

export function* stopRecordingUserMedia(action) {
    const { stopCapturing, invitationId } = action.payload;
    const cameraState = yield select((state) => state.camera);
    const { autoUpload } = cameraState;

    try {
        const recorderState = yield call([CameraService, 'getRecorderState']);

        // To prevent stopping the recorder while it's already stopped
        // e.g. When there is an autoStop timeout
        if (recorderState === 'recording') {
            yield call([CameraService, 'stopRecording']);
            Logger.logInfo(
                `CameraEffects - stopRecordingUserMedia - Stopped the recorder`,
            );
        } else {
            Logger.logWarning(
                `CameraEffects - stopRecordingUserMedia - Did not stop the recorder since the recorder state was ${recorderState}`,
            );
        }

        const blobDuration = yield call([CameraService, 'getBlobDuration']);
        Logger.logInfo(
            `CameraEffects - stopRecordingUserMedia - Blob duration=${blobDuration}`,
        );
        yield put({
            type: cameraActionTypes.SET_BLOB_DURATION,
            payload: { blobDuration: blobDuration },
        });

        const blob = yield call([CameraService, 'loadBlobFromRecorder']);
        if (blob) {
            const videoURL = URL.createObjectURL(blob);
            yield resetRecorder();

            if (stopCapturing === true) {
                yield stopCapturingUserMedia();
            } else {
                Logger.logInfo(
                    'CameraEffects - stopRecordingUserMedia - Skipped stopping capturing user media',
                );
            }

            Logger.logInfo(
                'CameraEffects - Successfully stopped recording user media',
                {
                    invitationId,
                },
            );

            yield put({
                type: cameraActionTypes.STOP_RECORDING_USER_MEDIA_SUCCESS,
                payload: { videoURL },
            });

            if (autoUpload) {
                yield put(cameraActions.dispatchBlobUpload(invitationId));
            }
        } else {
            const error = new Error('Blob not found');
            Logger.logError(
                `CameraEffects - stopRecordingUserMedia - Failed to stop recording user media. Blob not found. invitationId=${invitationId}`,
                error,
            );
            yield put({
                type: cameraActionTypes.STOP_RECORDING_USER_MEDIA_FAILURE,
                payload: { error: error },
            });
        }
    } catch (error) {
        Logger.logError(
            `CameraEffects - Failed to stop recording user media invitationId=${invitationId}`,
            error,
        );
        yield put({
            type: cameraActionTypes.STOP_RECORDING_USER_MEDIA_FAILURE,
            payload: { error: error },
        });
    }
}

export function* resetRecordingChunk(action) {
    const { newFileId, invitationId } = action.payload;

    try {
        yield stopRecordingUserMedia({
            payload: { stopCapturing: false, invitationId },
        });
        yield recordUserMedia({
            payload: {
                invitationId,
                fileId: newFileId,
                autoUpload: true,
            },
        });
        Logger.logInfo(
          `CameraEffects - resetRecordingChunk - Reset recording chunk newFileId=${newFileId}`
        );
    } catch (error) {
        Logger.logError(
            `CameraEffects - resetRecordingChunk - Failed to reset recording chunk invitationId=${invitationId}, newFileId=${newFileId}`,
            error,
        );
    }
}

export function* dispatchBlobUpload(action) {
    const { invitationId } = action.payload;
    const cameraState = yield select((state) => state.camera);
    const { fileId } = cameraState;
    const fileFormat = 'webm';
    const blob = yield call([CameraService, 'getBlob']);

    const uploadObject = {
        invitationId,
        fileType: 'video',
        fileId,
        file: blob,
        fileFormat,
    };

    yield put(uploadsActions.upload({ ...uploadObject, uniqueId: fileId }));
    yield call([CameraService, 'setBlob'], null);
    yield put({
        type: cameraActionTypes.SET_BLOB_DURATION,
        payload: { blobDuration: null },
    });
}

export function* resetRecorder() {
    try {
        if (!!CameraService.recorder) {
            yield call([CameraService, 'resetCameraRecorder']);
            Logger.logInfo(
              `CameraEffects - resetRecorder - The recorder reset`
            );

            yield put({ type: cameraActionTypes.RESET_RECORDER_SUCCESS });
        } else {
            Logger.logWarning(
              `CameraEffects - resetRecorder - Failed to reset the recorder. CameraService.recorder is not defined.`
            );
        }
    } catch (error) {
        Logger.logError(
            'CameraEffects - resetRecorder - Failed to reset recorder',
            error,
        );
        yield put({
            type: cameraActionTypes.RESET_RECORDER_FAILURE,
            payload: { error: error },
        });
    }
}
