import styled from 'styled-components';
import { breakpoints } from '../../../App.style';

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    img,
    .circleSkeleton {
        height: auto;
        max-width: 170px;
        @media only screen and (max-width: ${breakpoints.mobile}) {
            max-width: 100px;
        }
    }
`;