import {publicConfigActionTypes} from './publicConfig.types';

export const publicConfigInitialState = {
    name: '',
    language: 'nl-NL',
    loading: false,
    error: null,
};

const publicConfigReducer = (state = publicConfigInitialState, action) => {
    switch (action.type) {
        case publicConfigActionTypes.FETCH_PUBLIC_CONFIG: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case publicConfigActionTypes.FETCH_PUBLIC_CONFIG_SUCCESS: {
            const { name, language } = action.payload;
            return {
                ...state,
                name: name,
                language: language,
                loading: false,
                error: null,
            };
        }

        case publicConfigActionTypes.FETCH_PUBLIC_CONFIG_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                error: error,
            };
        }

        default:
            return state;
    }
};

export default publicConfigReducer;
