import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Container,
    Header,
    Section,
    LeftSection,
    RightSection,
    Button,
    Wrapper,
    Description,
    Title,
    ButtonWrapper,
    ButtonWithIcon,
    BrowserWrapper,
    Browser,
    Line,
    LanguageSwitcherContainer,
    LogoContainer,
} from './Style';

import neurolyticsLogo from 'assets/images/neurolytics-logo.png';
import { ReactComponent as ChromeSmallIcon } from 'assets/icons/chrome-small.svg';
import { ReactComponent as ChromeIcon } from 'assets/icons/chrome.svg';
import { ReactComponent as EdgeSmallIcon } from 'assets/icons/edge-small.svg';
import { ReactComponent as EdgeIcon } from 'assets/icons/edge.svg';
import { ReactComponent as FirefoxIcon } from 'assets/icons/firefox.svg';
import { ReactComponent as SafariIcon } from 'assets/icons/safari.svg';
import ClipboardCopy from '../CopyToClicpboard/CopyToClipboard';
import LanguageSwitcher from '../LanguageSwitcher';

const BrowserNotSupportedView = (props) => {
    const { t } = props;

    return (
        <Container>
            <LanguageSwitcherContainer>
                <LanguageSwitcher />
            </LanguageSwitcherContainer>
            <Header>
                <LogoContainer>
                    <img src={neurolyticsLogo} alt="Logo" />
                </LogoContainer>
            </Header>
            <Title>
                {t('ERRORS_VIEW.TITLE')}
                <span>{t('ERRORS_VIEW.BROWSER')}</span>
                {t('ERRORS_VIEW.IS')}
                <br></br>
                {t('ERRORS_VIEW.CURRENTLY')}
                <span>{t('ERRORS_VIEW.NOT_SUPPORTED')}</span>
            </Title>
            <Section>
                <LeftSection>
                    <Description>{t('ERRORS_VIEW.DESC_1')}</Description>
                    <Wrapper>
                        <Title className={'small'}>
                            {t('ERRORS_VIEW.SUBTITLE_1')}
                        </Title>
                        <Description>{t('ERRORS_VIEW.DESC_2')}</Description>
                    </Wrapper>
                    <Wrapper className={'sub'}>
                        <Description>{t('ERRORS_VIEW.DESC_3')}</Description>
                        <Description>
                            <ClipboardCopy copyText={window.location.href} />
                        </Description>
                    </Wrapper>
                    <Wrapper>
                        <Title className={'small'}>
                            {t('ERRORS_VIEW.SUBTITLE_2')}
                        </Title>
                        <Description>{t('ERRORS_VIEW.DESC_4')}</Description>
                        <Description>{t('ERRORS_VIEW.DESC_5')}</Description>
                    </Wrapper>
                    <ButtonWrapper>
                        <a
                            href={'https://www.google.com/chrome/'}
                            target="_blank"
                            rel="noreferrer">
                            <ButtonWithIcon>
                                <ChromeSmallIcon className={'icon-svg'} />
                                <p className={'button-text'}>
                                    {t('ERRORS_VIEW.LABEL_1')}
                                </p>
                            </ButtonWithIcon>
                        </a>
                        <a
                            href={
                                'https://www.microsoft.com/en-us/edge/download?form=MA13FJ'
                            }
                            target="_blank"
                            rel="noreferrer">
                            <ButtonWithIcon>
                                <EdgeSmallIcon className={'icon-svg'} />
                                <p className={'button-text'}>
                                    {t('ERRORS_VIEW.LABEL_2')}
                                </p>
                            </ButtonWithIcon>
                        </a>
                    </ButtonWrapper>
                </LeftSection>
                <RightSection>
                    <BrowserWrapper>
                        <Browser>
                            <ChromeIcon />
                            <p>{t('ERRORS_VIEW.LABEL_3')}</p>
                        </Browser>
                        <Browser>
                            <EdgeIcon />
                            <p>{t('ERRORS_VIEW.LABEL_4')}</p>
                        </Browser>
                    </BrowserWrapper>
                    <Button>{t('ERRORS_VIEW.LABEL_5')}</Button>
                    <br></br>
                    <BrowserWrapper className={'disabled'}>
                        <Browser>
                            <SafariIcon />
                            <p>{t('ERRORS_VIEW.LABEL_6')}</p>
                        </Browser>
                        <Browser>
                            <FirefoxIcon />
                            <p>{t('ERRORS_VIEW.LABEL_7')}</p>
                        </Browser>
                    </BrowserWrapper>
                    <Button className={'disabled'}>
                        {t('ERRORS_VIEW.LABEL_8')}
                    </Button>
                </RightSection>
            </Section>
            <Line></Line>
            <Section className={'footer'}>
                <LeftSection>
                    <Wrapper className={'disabled'}>
                        <Title className={'small'}>
                            {t('ERRORS_VIEW.SUBTITLE_3')}
                        </Title>
                        <Description>{t('ERRORS_VIEW.DESC_6')}</Description>
                    </Wrapper>
                </LeftSection>
            </Section>
        </Container>
    );
};

export default withTranslation()(BrowserNotSupportedView);
