import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import {
    Container,
    Header,
    Message,
    Footer,
    TextContainer,
    Link,
} from './DataCollectionAssessmentEndingView.style';

import neurolyticsLogo from 'assets/images/neurolytics-logo.png';
import poweredByNeurolyticsLogo from 'assets/images/powered-by-neurolytics.png';
import CompanyLogo from '../../../components/shared/CompanyLogo/CompanyLogo';
import withMediaQuery from '../../../hoc/MediaQuery/withMediaQuery';
import SuccessAnimation from '../../../components/SuccessAnimation/SuccessAnimation';

function DataCollectionAssessmentEndingView(props) {
    const { isMobileSize } = props;
    const { logoUrl } = useSelector((state) => state.config);
    const { assessmentId } = useParams();
    const assessmentIds = {
        prodProlificNl: 'f593017b-1944-4d20-a2aa-d43159aeb558',
        prodProlificEn: 'ac4c3cc5-9f77-434e-ab14-553af3da9991',
        prodProlificCognitiveEn: '0f3c0cd8-fc4a-48de-90a6-fbb179075ff8',
        prodActingNl: '97ab50f5-3a77-44d4-99bf-42a5e347f8ca',
        prodUUStudentNl: '',
        prodProlificPropertyManagementEn:
            '2301f51b-af99-4fa9-98af-597b755683cc',
        prodProlificPwcEn: '85380687-bfba-4a62-9e7f-a50b3e02985b',
        devProlificNl: '438d9bb0-338c-4ad1-a87c-1da36a0cc1dd',
        devProlificEn: '1341a82a-115f-4892-9de6-e304ca1856a9',
        devActingNl: '32ffd3e1-b52b-4b32-99c1-61d46e4f9d9d',
        devUUStudentNl: '',
        devProlificCognitiveEn: '76494585-dbd7-4d0b-aa3c-2b2e300bb815',
    };

    useEffect(() => {
        props.onScanFinished();
    }, []);

    return (
        <>
            <SuccessAnimation />
            <Container>
                <Header>
                    <CompanyLogo
                        logoUrl={logoUrl ? logoUrl : neurolyticsLogo}
                    />
                </Header>
                <TextContainer>
                    <Message>
                        <h1>{props.t('ENDING_VIEW.TITLE')}</h1>
                        <h3>
                            {props.t('ENDING_VIEW.DATA_COLLECTION.MESSAGE_1')}
                        </h3>

                        {(assessmentId === assessmentIds.prodProlificEn ||
                            assessmentId === assessmentIds.devProlificEn) && (
                            <>
                                <h3>
                                    To complete your participation you can use
                                    the following Prolific completion code{' '}
                                    <Link href="https://app.prolific.co/submissions/complete?cc=79A10EC6">
                                        79A10EC6
                                    </Link>
                                    .
                                </h3>

                                <h3>
                                    {props.t(
                                        'ENDING_VIEW.DATA_COLLECTION.MESSAGE_3',
                                    )}
                                </h3>
                            </>
                        )}

                        {assessmentId ===
                            assessmentIds.prodProlificPropertyManagementEn && (
                            <>
                                <h3>
                                    To complete your participation you can use
                                    the following Prolific completion code{' '}
                                    <Link href="https://app.prolific.co/submissions/complete?cc=C15VMNAO">
                                        C15VMNAO
                                    </Link>
                                    .
                                </h3>

                                <h3>
                                    {props.t(
                                        'ENDING_VIEW.DATA_COLLECTION.MESSAGE_3',
                                    )}
                                </h3>
                            </>
                        )}

                        {assessmentId === assessmentIds.prodProlificPwcEn && (
                            <>
                                <h3>
                                    To complete your participation you can use
                                    the following Prolific completion code{' '}
                                    <Link href="https://app.prolific.co/submissions/complete?cc=CWPQDA3I">
                                        CWPQDA3I
                                    </Link>
                                    .
                                </h3>

                                <h3>
                                    {props.t(
                                        'ENDING_VIEW.DATA_COLLECTION.MESSAGE_3',
                                    )}
                                </h3>
                            </>
                        )}

                        {(assessmentId ===
                            assessmentIds.prodProlificCognitiveEn ||
                            assessmentId ===
                                assessmentIds.devProlificCognitiveEn) && (
                            <>
                                <h3>
                                    To complete your participation you can use
                                    the following Prolific completion code{' '}
                                    <Link href="https://app.prolific.co/submissions/complete?cc=C172LMHR">
                                        C172LMHR
                                    </Link>
                                    .
                                </h3>

                                <h3>
                                    {props.t(
                                        'ENDING_VIEW.DATA_COLLECTION.MESSAGE_3',
                                    )}
                                </h3>
                            </>
                        )}

                        {(assessmentId === assessmentIds.prodProlificNl ||
                            assessmentId === assessmentIds.devProlificNl ||
                            assessmentId === assessmentIds.prodActingNl ||
                            assessmentId === assessmentIds.devActingNl) && (
                            <>
                                <h3>
                                    Om je deelname te voltooien kun je gebruik
                                    maken van de volgende Prolific completion
                                    code{' '}
                                    <Link href="https://app.prolific.co/submissions/complete?cc=392ECBA2">
                                        392ECBA2
                                    </Link>
                                    .
                                </h3>

                                <h3>
                                    {props.t(
                                        'ENDING_VIEW.DATA_COLLECTION.MESSAGE_3',
                                    )}
                                </h3>
                            </>
                        )}

                        <h3>
                            <Trans
                                t={props.t}
                                i18nKey="ENDING_VIEW.MESSAGE_2"
                            />
                        </h3>
                        <h3>
                            <Trans
                                t={props.t}
                                i18nKey="ENDING_VIEW.MESSAGE_3"
                            />
                        </h3>
                    </Message>{' '}
                </TextContainer>
                {isMobileSize && <div className="spacer"></div>}
                <Footer>
                    <img
                        src={poweredByNeurolyticsLogo}
                        alt="Neurolytics Logo"
                    />
                </Footer>
            </Container>
        </>
    );
}

export default withMediaQuery(
    withTranslation()(DataCollectionAssessmentEndingView),
);
