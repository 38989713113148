import { uploadsActionTypes } from './uploads.actions';

export const uploadsInitialState = {
    toUpload: [],
    uploading: [],
    uploaded: [],
    failed: [],
};

export default (state = uploadsInitialState, action) => {
    switch (action.type) {
        case uploadsActionTypes.PUT_FILE_ON_QUEUE: {
            return {
                ...state,
                toUpload: [...state.toUpload, action.payload],
            };
        }
        case uploadsActionTypes.UPLOADING: {
            const fileId = action.payload.uniqueId;

            const updatedQueue = [...state.toUpload].filter(
                (object) => object.uniqueId !== fileId
            );
            const updatedFailures = [...state.failed].filter(
                (object) => object.uniqueId !== fileId
            );

            return {
                ...state,
                toUpload: updatedQueue,
                uploading: [...state.uploading, action.payload],
                failed: updatedFailures
            };
        }
        case uploadsActionTypes.UPLOAD_SUCCESS: {
            const fileId = action.payload.uniqueId;
            const updatedToUploading = [...state.uploading].filter(
                (object) => object.uniqueId !== fileId
            );
            return {
                ...state,
                uploading: updatedToUploading,
                uploaded: [...state.uploaded, action.payload]
            };
        }
        case uploadsActionTypes.UPLOAD_FAILURE: {
            const fileId = action.payload.uniqueId;
            const updatedToUploading = [...state.uploading].filter(
                (object) => object.uniqueId !== fileId
            );
            return {
                ...state,
                uploading: updatedToUploading,
                failed: [...state.failed, action.payload]
            };
        }

        default:
            return state;
    }
};
