import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Message } from './TimerAlertModal.style';

import Timer from 'react-compound-timer';
import { withTranslation } from 'react-i18next';

function TimerAlertModal(props) {
    const { t } = props;
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(true);
    }, []);

    const handleClose = () => {
        setOpen(false);
        props.onComplete();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <Message>
                    <h1>
                        {t(
                            'COGNITIVE_ABILITIES_MODULE.TIMER_COUNTDOWN_COGNITIVE_ABILITIES_MODULE.TITLE'
                        )}
                    </h1>
                    <h3>
                        {t(
                            'COGNITIVE_ABILITIES_MODULE.TIMER_COUNTDOWN_COGNITIVE_ABILITIES_MODULE.MESSAGE'
                        )}
                    </h3>
                    <div className="timer">
                        <Timer
                            initialTime={props.time}
                            direction="backward"
                            checkpoints={[
                                {
                                    time: 0,
                                    callback: () => handleClose(),
                                },
                            ]}>
                            <Timer.Seconds formatValue={(value) => `${value}`} />
                        </Timer>
                    </div>
                </Message>
            </Dialog>
        </div>
    );
}

export default withTranslation()(TimerAlertModal);
