import { combineReducers } from 'redux';
import {
    default as cameraReducer,
    cameraInitialState,
} from './camera/camera.reducer';
import {
    default as publicConfigReducer,
    publicConfigInitialState,
} from './publicConfig/publicConfig.reducer';
import {
    default as configReducer,
    configInitialState,
} from './config/config.reducer';
import {
    default as preloaderReducer,
    preloaderInitialState,
} from './preloader/preloader.reducer';
import { default as authReducer, authInitialState } from './auth/auth.reducer';
import {
    default as uploadsReducer,
    uploadsInitialState,
} from './uploads/uploads.reducer';

export const initialState = {
    camera: cameraInitialState,
    config: configInitialState,
    publicConfig: publicConfigInitialState,
    preloader: preloaderInitialState,
    auth: authInitialState,
    uploads: uploadsInitialState,
};

export const reducer = combineReducers({
    camera: cameraReducer,
    config: configReducer,
    publicConfig: publicConfigReducer,
    preloader: preloaderReducer,
    auth: authReducer,
    uploads: uploadsReducer,
});
