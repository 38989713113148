import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import {
    Browser,
    BrowserWrapper,
    Button,
    Container,
    Description,
    Header,
    LanguageSwitcherContainer,
    LogoContainer,
    Section,
    Title,
} from './MobileNotSupportedView.style';

import neurolyticsLogo from 'assets/images/neurolytics-logo.png';
import { ReactComponent as LaptopIcon } from 'assets/icons/laptop.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg';
import LanguageSwitcher from '../LanguageSwitcher';
import { SCAN_URL } from '../../../utils/environment-variables';
import ClipboardCopy from '../CopyToClicpboard/CopyToClipboard';

const MobileNotSupportedView = (props) => {
    const { t } = props;

    return (
        <Container>
            <LanguageSwitcherContainer>
                <LanguageSwitcher />
            </LanguageSwitcherContainer>
            <Header>
                <LogoContainer>
                    <img src={neurolyticsLogo} alt="Logo" />
                </LogoContainer>
            </Header>
            <Section>
                <Title>
                    {t('MOBILE_VIEW.TITLE_1')}
                    <span>{t('MOBILE_VIEW.BROWSER')}</span>
                    {t('MOBILE_VIEW.IS')}
                    <span>{t('MOBILE_VIEW.NOT_SUPPORTED')}</span>
                </Title>
                <Description>{t('MOBILE_VIEW.DESC_1')}</Description>
                <Description>
                    <Trans i18nKey="MOBILE_VIEW.DESC_2"></Trans>
                </Description>
                <Description className={'copy-link'}>
                    <ClipboardCopy copyText={SCAN_URL} />
                </Description>
                <br></br>
                <br></br>
                <BrowserWrapper>
                    <Browser>
                        <LaptopIcon />
                        <p>{t('MOBILE_VIEW.LABEL_1')}</p>
                        <Button>{t('MOBILE_VIEW.LABEL_3')}</Button>
                    </Browser>
                    <Browser className={'disabled'}>
                        <MobileIcon />
                        <p>{t('MOBILE_VIEW.LABEL_2')}</p>
                        <Button className={'disabled'}>
                            {t('MOBILE_VIEW.LABEL_4')}
                        </Button>
                    </Browser>
                </BrowserWrapper>
            </Section>
        </Container>
    );
};

export default withTranslation()(MobileNotSupportedView);
