import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    VideoContainer,
    VideoLoadingPlaceholder,
    VideoControls,
    VideoActionsContainer,
    VideoProgressionContainer,
    VideoProgressionTimeContainer,
    VideoProgressionBarContainer,
    VideoProgressionBarWrap,
} from './VideoComponent.style';

import { RotateSpinLoader } from 'react-css-loaders';
import SessionAnalytics from 'services/SessionAnalytics';
import Logger from 'services/Logger';

import { ReactComponent as PlayIcon } from 'assets/icons/video-controls/play-button.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/video-controls/pause-button.svg';

const VideoComponent = (props) => {
    const {
        videoId,
        videoUrl,
        showControls,
        playAutomatically,
        onComplete,
        videoDuration,
        replayRecording,
        onStopPlayback,
        onStartPlaying,
        isVideoFullscreen,
    } = props;

    const [videoLoading, setVideoLoading] = useState(true);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [videoCurrentTime, setVideoCurrentTime] = useState('00:00');
    const [videoDurationTime, setVideoDurationTime] = useState(null);
    const [videoCurrentProgression, setVideoCurrentProgression] = useState(0);
    const [showVideo, setShowVideo] = useState(true);
    const [isFirstPlay, setIsFirstPlay] = useState(true);

    const videoRef = useRef(null);

    const updateVideoSrc = (videoUrl) => {
        if (videoRef.current) {
            videoRef.current.src = videoUrl;
        }
    };

    useEffect(() => {
        updateVideoSrc(videoUrl);
    }, [videoUrl]);

    useEffect(() => {
        dispatchAnalyticsEvent('start-loading');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (replayRecording === true && videoRef) {
            onReplay();
            setTimeout(() => {
                onStopPlayback();
            }, 1000);
        }
    }, [onStopPlayback, replayRecording]);

    const onReplay = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
    };

    const setDuration = (videoRef) => {
        if (videoRef.current.duration === Infinity) {
            setVideoDurationTime(videoDuration / 1000);
        } else {
            setVideoDurationTime(videoRef.current.duration);
        }
    };

    const setTimeProgressionAndCurrentTime = (event) => {
        const currentTime = event.target.currentTime;
        const currentProgression = Math.floor(
            (100 / (videoDuration / 1000)) * currentTime,
        );

        setVideoCurrentTime(getTimeString(currentTime));
        setVideoCurrentProgression(currentProgression);

        if (Math.floor(videoDurationTime) === Math.floor(currentTime)) {
            // props.videoDuration is slightly different from videoRef.current.duration
            // if a user records a very short video & tries to replay, the replay may complete before videoRef.current.duration !== Infinity
            // (see setDuration() above)
            // This would prevent the progression from ever reaching 100%. This if statement solves that issue.
            setVideoCurrentProgression(100);
        }
    };

    const getTimeString = (currentTime) => {
        const minutes = parseInt((currentTime / 60) % 60);
        const seconds = parseInt(currentTime % 60);
        if (currentTime === 0) return '00:00';
        return `${minutes < 10 ? `0${minutes}` : `${minutes}`}:${
            seconds < 10 ? `0${seconds}` : `${seconds}`
        }`;
    };

    const dispatchAnalyticsEvent = (eventId, data) => {
        const event = {
            timeStamp: Date.now(),
            timeString: new Date(Date.now()).toISOString(),
            eventType: eventId,
            data: data,
        };
        SessionAnalytics.onVideoEvent(videoId, event);
    };

    return (
        <Container isVideoFullscreen={isVideoFullscreen}>
            <VideoContainer
                visible={showVideo}
                isVideoFullscreen={isVideoFullscreen}>
                {!videoPlaying && videoLoading && (
                    <VideoLoadingPlaceholder visible={videoLoading}>
                        <RotateSpinLoader color="#fff" size="1" />
                    </VideoLoadingPlaceholder>
                )}
                <video
                    aria-label="video"
                    style={{
                        height: '100%',
                        minHeight: isVideoFullscreen ? '100vh' : '',
                    }}
                    src={videoUrl}
                    ref={videoRef}
                    type="video/mp4"
                    preload="true"
                    autoPlay={playAutomatically}
                    onCanPlayThrough={() => {
                        setShowVideo(true);
                        setVideoLoading(false);
                    }}
                    onDurationChangeCapture={() => {
                        setDuration(videoRef);
                    }}
                    onPlaying={() => {
                        setShowVideo(true);
                        setVideoLoading(false);
                        setVideoPlaying(true);
                        dispatchAnalyticsEvent('start-playing');
                    }}
                    onPlay={() => {
                        if (isFirstPlay && onStartPlaying) {
                            setIsFirstPlay(false);
                            onStartPlaying();
                        }
                    }}
                    onPause={() => {
                        setVideoPlaying(false);
                        dispatchAnalyticsEvent('paused');
                    }}
                    onStalled={() => {
                        setVideoLoading(false);
                        setVideoPlaying(false);
                        dispatchAnalyticsEvent('video-loading');
                    }}
                    onWaiting={() => {
                        setVideoLoading(true);
                        setVideoPlaying(false);
                        dispatchAnalyticsEvent('video-loading');
                    }}
                    onTimeUpdate={(event) => {
                        setTimeProgressionAndCurrentTime(event);
                    }}
                    onError={() =>
                        Logger.logError(
                            `VideoComponent - Playing video with url=${videoUrl}`,
                        )
                    }
                    onEnded={() => {
                        if (onComplete) onComplete();
                    }}
                />
                {showControls && (
                    <VideoControls aria-label="video controls">
                        <VideoActionsContainer>
                            {!videoPlaying && (
                                <PlayIcon
                                    id="play-icon"
                                    data-testid="play-button"
                                    onClick={() => {
                                        videoRef.current.play();
                                    }}
                                />
                            )}
                            {videoPlaying && (
                                <PauseIcon
                                    id="pause-icon"
                                    data-testid="pause-button"
                                    onClick={() => {
                                        videoRef.current.pause();
                                    }}
                                />
                            )}
                        </VideoActionsContainer>
                        <VideoProgressionContainer>
                            <VideoProgressionTimeContainer>
                                {videoCurrentTime && (
                                    <span
                                        id="video-current-time"
                                        role="timer"
                                        aria-label="current video time">
                                        {videoCurrentTime}
                                    </span>
                                )}
                                {videoDurationTime && (
                                    <span
                                        id="video-duration-time"
                                        role="timer"
                                        aria-label="video duration">
                                        {getTimeString(videoDurationTime)}
                                    </span>
                                )}
                            </VideoProgressionTimeContainer>
                            <VideoProgressionBarContainer>
                                <VideoProgressionBarWrap
                                    progression={videoCurrentProgression}>
                                    <div
                                        aria-label="video progress bar"
                                        style={{
                                            height: '3px',
                                            background: 'red',
                                            width: `${videoCurrentProgression}%`,
                                        }}
                                    />
                                </VideoProgressionBarWrap>
                            </VideoProgressionBarContainer>
                        </VideoProgressionContainer>
                    </VideoControls>
                )}
            </VideoContainer>
        </Container>
    );
};

export default VideoComponent;
