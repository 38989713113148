/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import {
    Container,
    TwoColumnsLayout,
    VideoColumn,
    Video,
    ContentColumn,
    Text,
    ConditionsContainer,
    ConditionsParagraph,
    ContentFooter,
    ButtonContainer,
    Header,
    ContainerWrapper,
} from './TermsAndConditions.style';
import { withTranslation, Trans } from 'react-i18next';

import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import RoundedCheckbox from 'components/shared/RoundedCheckbox/RoundedCheckbox';
import poweredByNeurolyticsLogo from '../../assets/images/powered-by-neurolytics.png';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import withMediaQuery from '../../hoc/MediaQuery/withMediaQuery';
import CompanyLogo from '../../components/shared/CompanyLogo/CompanyLogo';

const TermsAndConditions = (props) => {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const {
        t,
        companyName,
        language,
        introVideos,
        user,
        isTabletAndMobileSize,
    } = props;
    const { companySlug } = useParams();
    const companies = useSelector((state) => state.config.companies);
    const company = companies[companySlug];
    const logoUrl =
        company && company.logoUrl
            ? company.logoUrl
            : 'https://s3.eu-west-1.amazonaws.com/neurolytics.public/neurolytics/images/neurolytics-logo.png';

    const privacyLinkS3 = {
        'nl-NL':
            'https://s3-eu-west-1.amazonaws.com/neurolytics.public/docs/neurolytics-privacy-statement_nl-NL.pdf',
        'en-US':
            'https://s3-eu-west-1.amazonaws.com/neurolytics.public/docs/neurolytics-privacy-statement_en-US.pdf',
    };

    const termsLinkS3 = {
        'nl-NL':
            'https://s3-eu-west-1.amazonaws.com/neurolytics.public/docs/neurolytics-terms-and-conditions_nl-NL.pdf',
        'en-US':
            'https://s3-eu-west-1.amazonaws.com/neurolytics.public/docs/neurolytics-terms-and-conditions_en-US.pdf',
    };
    const defaultIntroVideos = {
        'nl-NL':
            'https://s3-eu-west-1.amazonaws.com/neurolytics.public/neurolytics/videos/neurolytics-intro-video-nl.mp4',
        'en-US':
            'https://s3-eu-west-1.amazonaws.com/neurolytics.public/neurolytics/videos/neurolytics-intro-video-en.mp4',
    };

    let privacyLink = privacyLinkS3[language];
    let termsLink = termsLinkS3[language];
    let introVideoUrl =
        introVideos !== null
            ? introVideos[language]
            : defaultIntroVideos[language];

    const onTermsCheck = (event) => {
        setTermsAccepted(event);
    };

    return (
        <ContainerWrapper>
            <Container>
                <Header>
                    <CompanyLogo logoUrl={logoUrl} />
                </Header>
                <TwoColumnsLayout>
                    {isTabletAndMobileSize && (
                        <ContentColumn>
                            <Text>
                                <h1>
                                    {t('TERMS_AND_CONDITIONS.TITLE', {
                                        user_first_name: user.given_name,
                                    })}
                                </h1>
                                <h2>
                                    {t('TERMS_AND_CONDITIONS.SUBTITLE', {
                                        company_name: companyName,
                                    })}
                                </h2>
                                <p>{t('TERMS_AND_CONDITIONS.MESSAGE1')}</p>
                                <p>{t('TERMS_AND_CONDITIONS.MESSAGE2')}</p>
                            </Text>
                        </ContentColumn>
                    )}
                    <VideoColumn>
                        <Video
                            src={introVideoUrl}
                            controls
                            controlsList="nodownload"
                        />
                    </VideoColumn>
                    {isTabletAndMobileSize && (
                        <ContentColumn>
                            <ConditionsContainer>
                                <RoundedCheckbox
                                    size={16}
                                    id="acceptConditions"
                                    onChange={onTermsCheck}
                                />
                                <ConditionsParagraph>
                                    <Trans i18nKey="TERMS_AND_CONDITIONS.TERMS_AGREEMENT_TEXT">
                                        I have read and agree with the
                                        <a
                                            href={privacyLink}
                                            target="_blank"
                                            rel="noreferrer">
                                            Privacy Statement
                                        </a>
                                        and
                                        <a
                                            href={termsLink}
                                            target="_blank"
                                            rel="noreferrer">
                                            Terms and Conditions
                                        </a>
                                    </Trans>
                                </ConditionsParagraph>
                            </ConditionsContainer>
                        </ContentColumn>
                    )}
                    {!isTabletAndMobileSize && (
                        <ContentColumn>
                            <Text>
                                <h1>
                                    {t('TERMS_AND_CONDITIONS.TITLE', {
                                        user_first_name: user.given_name,
                                    })}
                                </h1>
                                <h2>
                                    {t('TERMS_AND_CONDITIONS.SUBTITLE', {
                                        company_name: companyName,
                                    })}
                                </h2>
                                <p>{t('TERMS_AND_CONDITIONS.MESSAGE1')}</p>
                                <p>{t('TERMS_AND_CONDITIONS.MESSAGE2')}</p>
                            </Text>
                            <ConditionsContainer>
                                <RoundedCheckbox
                                    id="acceptConditions"
                                    onChange={onTermsCheck}
                                />
                                <ConditionsParagraph>
                                    <Trans i18nKey="TERMS_AND_CONDITIONS.TERMS_AGREEMENT_TEXT">
                                        I have read and agree with the
                                        <a
                                            href={privacyLink}
                                            target="_blank"
                                            rel="noreferrer">
                                            Privacy Statement
                                        </a>
                                        and
                                        <a
                                            href={termsLink}
                                            target="_blank"
                                            rel="noreferrer">
                                            Terms and Conditions
                                        </a>
                                    </Trans>
                                </ConditionsParagraph>
                            </ConditionsContainer>
                            <ContentFooter>
                                <ButtonContainer>
                                    <ShadowButton
                                        disabled={!termsAccepted}
                                        onClick={() => props.onComplete()}>
                                        {t(
                                            'TERMS_AND_CONDITIONS.CONTINUE_BUTTON',
                                        )}
                                    </ShadowButton>
                                </ButtonContainer>
                            </ContentFooter>
                        </ContentColumn>
                    )}
                </TwoColumnsLayout>
                {isTabletAndMobileSize && (
                    <>
                        <div className="spacer"></div>
                        <ContentFooter>
                            <ButtonContainer>
                                <ShadowButton
                                    size={'small'}
                                    disabled={!termsAccepted}
                                    onClick={() => props.onComplete()}>
                                    {t('TERMS_AND_CONDITIONS.CONTINUE_BUTTON')}
                                </ShadowButton>
                            </ButtonContainer>
                        </ContentFooter>
                    </>
                )}
                <img
                    className="power-logo"
                    src={poweredByNeurolyticsLogo}
                    alt="Neurolytics Logo"
                />
            </Container>
        </ContainerWrapper>
    );
};

export default withMediaQuery(withTranslation()(TermsAndConditions));
