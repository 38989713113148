import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {Container, CopyTooltip, Text} from './Style';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as WhiteCopyIcon } from 'assets/icons/white-copy.svg';

const ClipboardCopy = (props) => {
    const [isCopied, setIsCopied] = useState(false);
    const { t, copyText } = props;
    async function copyTextToClipboard(text) {
        return await navigator.clipboard.writeText(text);
    }

    const handleCopyClick = () => {
        copyTextToClipboard(copyText)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Container>
            <Text onClick={handleCopyClick}>
                {!isCopied && <WhiteCopyIcon />}
                {isCopied && <CopyIcon />}
                <p>{copyText}</p>
            </Text>

            {isCopied && (
                <CopyTooltip>
                    <svg
                        width="9"
                        height="7"
                        viewBox="0 0 9 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.28847 7C3.21154 6.99974 3.13548 6.9831 3.06502 6.95111C2.99456 6.91911 2.9312 6.87245 2.87889 6.81401L0.152085 3.80916C0.0501536 3.69663 -0.00445081 3.54677 0.000284134 3.39254C0.00501908 3.23831 0.0687055 3.09234 0.177333 2.98675C0.285961 2.88116 0.430632 2.8246 0.57952 2.8295C0.728407 2.83441 0.869316 2.90038 0.971248 3.01291L3.28286 5.56441L8.00146 0.217288C8.04934 0.155519 8.10891 0.104542 8.17651 0.0674895C8.24411 0.0304367 8.3183 0.00809002 8.39454 0.00182205C8.47077 -0.00444591 8.54744 0.00549722 8.61983 0.0310406C8.69223 0.056584 8.75881 0.0971882 8.81551 0.15036C8.8722 0.203531 8.9178 0.268147 8.94951 0.340239C8.98121 0.412331 8.99836 0.490376 8.99989 0.569582C9.00142 0.648787 8.9873 0.727481 8.9584 0.800829C8.9295 0.874177 8.88644 0.940631 8.83184 0.996109L3.70366 6.8082C3.65184 6.86769 3.58871 6.91544 3.51822 6.94845C3.44774 6.98145 3.37142 6.999 3.29408 7H3.28847Z"
                            fill="white"
                        />
                    </svg>
                    <p>{t('ERRORS_VIEW.COPIED')}</p>
                </CopyTooltip>
            )}
        </Container>
    );
};
export default withTranslation()(ClipboardCopy);
