import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Container,
    TwoColumnsLayout,
    ImageColumn,
    ContentColumn,
    ModuleImage,
    Text,
    InfoBlock,
    RoundedIcon,
    ContentFooter,
    ButtonContainer,
} from './InterviewIntroduction.style';
import speakingModuleImage from 'assets/images/speaking_module.png';
import { VolumeUpIcon, MobileVideoIcon } from 'evergreen-ui';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';

const InterviewIntroduction = (props) => {
    const { t } = useTranslation();

    return (
        <Container>
            <TwoColumnsLayout>
                <ImageColumn>
                    <ModuleImage src={speakingModuleImage} />
                </ImageColumn>
                <ContentColumn>
                    <Text>
                        <h1>{t('SPEAKING_MODULE.INTRODUCTION.TITLE')}</h1>
                        <p>
                            {t('SPEAKING_MODULE.INTRODUCTION.MESSAGE_1', {
                                number_of_interview_questions: props.numberOfQuestions,
                            })}
                        </p>
                        <p>{t('SPEAKING_MODULE.INTRODUCTION.MESSAGE_2')}</p>
                    </Text>
                    <InfoBlock>
                        <RoundedIcon>
                            <VolumeUpIcon size={25} color="white" />
                        </RoundedIcon>
                        <p>{t('SPEAKING_MODULE.INTRODUCTION.BLOCK_SOUND_ON')}</p>
                    </InfoBlock>
                    <InfoBlock>
                        <RoundedIcon>
                            <MobileVideoIcon size={25} color="white" />
                        </RoundedIcon>
                        <p>{t('SPEAKING_MODULE.INTRODUCTION.BLOCK_RECORDING_DURING_TASK')}</p>
                    </InfoBlock>
                    <ContentFooter>
                        <ButtonContainer>
                            <ShadowButton onClick={() => props.onNext()}>
                                {t('SPEAKING_MODULE.INTRODUCTION.START_TASK_BUTTON')}
                            </ShadowButton>
                        </ButtonContainer>
                    </ContentFooter>
                </ContentColumn>
            </TwoColumnsLayout>
        </Container>
    );
};

export default InterviewIntroduction;
