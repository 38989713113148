import styled from 'styled-components';

export const Header = styled.header`
    margin: 30px 0;
    h2,
    p {
        margin: 0;
        color: ${(props) => props.theme.textColor};
    }
`;

export const Footer = styled.footer`
    display: flex;
    width: 300px;
`;
