import styled, { css, keyframes } from 'styled-components';
import {breakpoints} from "../../../App.style";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.theme.backgroundColor};
    @media only screen and (max-height: 568px) {
        height: fit-content;
    }
`;
export const FormContainer = styled.div`
    display: flex;
    flex-flow: column;
    width: 80%;
    max-width: 1280px;
    height: 100%;
    align-items: center;

    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 90%;
        flex-direction: column;
    }
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInAnimationRule = css`
    ${fadeInAnimation} linear 0.5s;
`;

export const FadeIn = styled.span`
    animation: ${fadeInAnimationRule};
`;

export const Title = styled.h1`
    color: ${(props) => props.theme.textColor};
    width: 40%;
    text-align: center;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 70%;
    }
`;

export const ButtonContainer = styled.div`
    margin-top: 60px;
    width: 24%;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 40%;
    }
`;

export const ForgotPassword = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    color: ${(props) => props.theme.textColor};
    cursor: pointer;
    font-size: 0.9em;
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 2em;
`;

export const Message = styled.div`
    color: ${(props) => props.theme.textColor};
    padding-bottom: 1em;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 80px;
    padding-top: 10px;

    img {
        width: 220px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            width: 180px;
        }
    }
`;
