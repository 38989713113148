import rootSaga from "./sagas";
import { reducer, initialState } from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false
      }).concat(middleware),
    devTools: true,
    preloadedState
  })
}

export const store = setupStore(initialState);

export const runSagaMiddleware = () => {
  sagaMiddleware.run(rootSaga);
};

runSagaMiddleware();
