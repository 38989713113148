export const authActionTypes = {
    INIT_AUTH0: 'AUTH/INIT_AUTH0',
    INIT_AUTH0_SUCCESS: 'AUTH/INIT_AUTH0_SUCCESS',
    INIT_AUTH0_FAILURE: 'AUTH/INIT_AUTH0_FAILURE',
    INIT_LOGIN: 'AUTH/INIT_LOGIN',
    INIT_LOGIN_FAILURE: 'AUTH/INIT_LOGIN_FAILURE',
    STORE_USER: 'AUTH/STORE_USER',
    STORE_USER_SUCCESS: 'AUTH/STORE_USER_SUCCESS',
    STORE_USER_FAILURE: 'AUTH/STORE_USER_FAILURE',
    REGISTER_USER: 'AUTH/REGISTER_USER',
    REGISTER_USER_SUCCESS: 'AUTH/REGISTER_SUCCESS',
    REGISTER_USER_FAILURE: 'AUTH/REGISTER_USER_FAILURE',
    REFRESH_TOKEN: 'AUTH/REFRESH_TOKEN',
    REFRESH_TOKEN_SUCCESS: 'AUTH/REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_FAILURE: 'AUTH/REFRESH_TOKEN_FAILURE',
};

export const initAuth0 = () => ({ type: authActionTypes.INIT_AUTH0 });

export const initLogin = (companySlug = '', invitationId = '', mode) => ({
    type: authActionTypes.INIT_LOGIN,
    payload: { companySlug, invitationId, mode },
});

export const refreshToken = (companySlug, invitationId) => ({
    type: authActionTypes.REFRESH_TOKEN,
    payload: { companySlug, invitationId },
});

export const initLoginFailure = (error) => ({
    type: authActionTypes.INIT_LOGIN_FAILURE,
    payload: { error },
});

export const storeUser = () => ({ type: authActionTypes.STORE_USER });

export const registerUser = (targetGroupId, user) => ({
    type: authActionTypes.REGISTER_USER,
    payload: { targetGroupId, user },
});
