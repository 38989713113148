import React from 'react';
import { Title, CrossIconButton } from './DialogComponent.style';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CrossIcon } from 'evergreen-ui';

import { ThemeProvider } from '@material-ui/core/styles';

import { theme } from 'components/shared/DialogComponent/theme';

const DialogComponent = (props) => {
    const { isShown, onClose, title, children } = props;

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={isShown} onBackdropClick={onClose}>
                <DialogTitle id="alert-dialog-title">
                    <Title>
                        <span />
                        {title}

                        <CrossIconButton onClick={onClose}>
                            <CrossIcon size={20} />
                        </CrossIconButton>
                    </Title>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {children}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
};

export default DialogComponent;
