import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Container,
    TwoColumnsLayout,
    ImageColumn,
    ContentColumn,
    ModuleImage,
    Text,
    TasksList,
    Task,
    TaskNumber,
    TaskLabel,
    InfoBlock,
    RoundedIcon,
    ContentFooter,
    ButtonContainer,
} from './MentalTestIntroduction.style';
import cognitiveAbilitiesImage from 'assets/images/cognitive_abilities_module.png';

import generalKnowledgeIcon from 'assets/images/gen_knowledge_icon.png';
import logicIcon from 'assets/images/logic_icon.png';
import numericIcon from 'assets/images/numeric_icon.png';
import verbalIcon from 'assets/images/verbal_icon.png';

import { VolumeUpIcon, TimeIcon } from 'evergreen-ui';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';

const MentalTestIntroduction = (props) => {
    const { t } = useTranslation();

    return (
        <Container>
            <TwoColumnsLayout>
                <ImageColumn>
                    <ModuleImage src={cognitiveAbilitiesImage} />
                </ImageColumn>
                <ContentColumn>
                    <Text>
                        <h1>{t('COGNITIVE_ABILITIES_MODULE.INTRODUCTION.TITLE')}</h1>
                        <p>{t('COGNITIVE_ABILITIES_MODULE.INTRODUCTION.MESSAGE_1')}</p>
                        <p>{t('COGNITIVE_ABILITIES_MODULE.INTRODUCTION.MESSAGE_2')}</p>
                    </Text>
                    <TasksList>
                        <Task>
                            <TaskNumber>1</TaskNumber>
                            <TaskLabel>
                                {t(
                                    'COGNITIVE_ABILITIES_MODULE.INTRODUCTION.TASK_1_GENERAL_KNOWLEDGE'
                                )}
                            </TaskLabel>
                            <img alt="General knowledge icon" src={generalKnowledgeIcon} />
                        </Task>
                        <Task>
                            <TaskNumber>2</TaskNumber>
                            <TaskLabel>
                                {t(
                                    'COGNITIVE_ABILITIES_MODULE.INTRODUCTION.TASK_2_LOGICAL_REASONING'
                                )}
                            </TaskLabel>
                            <img alt="Logical reasoning icon" src={logicIcon} />
                        </Task>
                        <Task>
                            <TaskNumber>3</TaskNumber>
                            <TaskLabel>
                                {t(
                                    'COGNITIVE_ABILITIES_MODULE.INTRODUCTION.TASK_3_NUMERIC_REASONING'
                                )}
                            </TaskLabel>
                            <img alt="Numeric reasoning icon" src={numericIcon} />
                        </Task>
                        <Task>
                            <TaskNumber>4</TaskNumber>
                            <TaskLabel>
                                {t(
                                    'COGNITIVE_ABILITIES_MODULE.INTRODUCTION.TASK_4_VERBAL_REASONING'
                                )}
                            </TaskLabel>
                            <img alt="Verbal reasoning icon" src={verbalIcon} />
                        </Task>
                    </TasksList>
                    <InfoBlock>
                        <RoundedIcon>
                            <VolumeUpIcon size={25} color="white" />
                        </RoundedIcon>
                        <p>
                            {t(
                                'COGNITIVE_ABILITIES_MODULE.INTRODUCTION.BLOCK_RECORDING_DURING_TASK'
                            )}
                        </p>
                    </InfoBlock>
                    <InfoBlock>
                        <RoundedIcon>
                            <TimeIcon size={25} color="white" />
                        </RoundedIcon>
                        <p>
                            {t('COGNITIVE_ABILITIES_MODULE.INTRODUCTION.BLOCK_TIMER_DURING_TASK')}
                        </p>
                    </InfoBlock>
                    <ContentFooter>
                        <ButtonContainer>
                            <ShadowButton onClick={() => props.onNext()}>
                                {t('COGNITIVE_ABILITIES_MODULE.INTRODUCTION.START_TASK_BUTTON')}
                            </ShadowButton>
                        </ButtonContainer>
                    </ContentFooter>
                </ContentColumn>
            </TwoColumnsLayout>
        </Container>
    );
};

export default MentalTestIntroduction;
