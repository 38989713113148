import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ButtonContainer,
    Container,
    ContentColumn,
    ContentFooter,
    ImageColumn,
    ModuleImage,
    Text,
    TwoColumnsLayout,
    InfoBlock,
    RoundedIcon,
} from './CulturePreferencesIntro.style';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import questionnaireImage from 'assets/images/questionnaire_module.png';
import withMediaQuery from '../../../hoc/MediaQuery/withMediaQuery';

function CulturePreferencesIntro(props) {
    const { t } = useTranslation();
    const {
        onNext,
        isQuestionnaireOnlyScan,
        isTabletAndMobileSize,
        isMobileSize,
    } = props;

    return (
        <Container>
            {isTabletAndMobileSize && (
                <>
                    <TwoColumnsLayout>
                        <Text>
                            <h1>
                                {t('QUESTIONNAIRE_VIEW.INTRODUCTION.TITLE')}
                            </h1>
                        </Text>
                        <ImageColumn>
                            <ModuleImage src={questionnaireImage} />
                        </ImageColumn>
                    </TwoColumnsLayout>
                    <ContentColumn>
                        <div>
                            <Text>
                                <p>
                                    {t(
                                        'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE',
                                    )}
                                </p>
                                <p>
                                    {t(
                                        'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE_2',
                                    )}
                                </p>
                                <ul>
                                    <li>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE_3',
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE_4',
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE_5',
                                        )}
                                    </li>
                                </ul>
                            </Text>
                            {!isQuestionnaireOnlyScan && (
                                <InfoBlock>
                                    <RoundedIcon>
                                        <svg
                                            width="28px"
                                            height="28px"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.28033 2.21967C2.98744 1.92677 2.51257 1.92677 2.21967 2.21966C1.92678 2.51255 1.92677 2.98742 2.21967 3.28032L4.52132 5.58201C3.07724 5.9128 2 7.2056 2 8.75V19.25C2 21.0449 3.45507 22.5 5.25 22.5H15.7523C17.2959 22.5 18.5882 21.4238 18.9198 19.9807L24.7194 25.7805C25.0123 26.0734 25.4872 26.0734 25.7801 25.7805C26.073 25.4876 26.073 25.0127 25.7801 24.7198L3.28033 2.21967ZM17.5023 18.5632V19.25C17.5023 20.2165 16.7187 21 15.7523 21H5.25C4.2835 21 3.5 20.2165 3.5 19.25V8.75C3.5 7.7835 4.2835 7 5.25 7H5.93929L17.5023 18.5632Z"
                                                fill="#ffffff"
                                            />
                                            <path
                                                d="M8.68185 5.5L10.1818 7H15.7523C16.7188 7 17.5023 7.7835 17.5023 8.75V14.3205L19.0023 15.8206V12.1455L24.0021 8.72623V19.2765L19.1028 15.9212L24.1828 21.0012C24.8564 21.0372 25.5021 20.5119 25.5021 19.7508V8.25239C25.5021 7.24731 24.3761 6.65323 23.5464 7.22059L19.0023 10.3283V8.75C19.0023 6.95508 17.5472 5.5 15.7523 5.5H8.68185Z"
                                                fill="#ffffff"
                                            />
                                        </svg>
                                    </RoundedIcon>
                                    <p>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.INTRODUCTION.NOT_RECORDED',
                                        )}
                                    </p>
                                </InfoBlock>
                            )}
                        </div>
                        <ContentFooter>
                            <ButtonContainer>
                                <ShadowButton
                                    size={'small'}
                                    onClick={() => onNext()}>
                                    {t(
                                        'CULTURE_FIT_INTRODUCTION.START_TASK_BUTTON',
                                    )}
                                </ShadowButton>
                            </ButtonContainer>
                        </ContentFooter>
                    </ContentColumn>
                </>
            )}
            {!isTabletAndMobileSize && (
                <TwoColumnsLayout>
                    <ImageColumn>
                        <ModuleImage src={questionnaireImage} />
                    </ImageColumn>
                    <ContentColumn>
                        <div>
                            <Text>
                                <h1>
                                    {t('QUESTIONNAIRE_VIEW.INTRODUCTION.TITLE')}
                                </h1>
                                <p>
                                    {t(
                                        'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE',
                                    )}
                                </p>
                                <p>
                                    {t(
                                        'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE_2',
                                    )}
                                </p>
                                <ul>
                                    <li>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE_3',
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE_4',
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.INTRODUCTION.MESSAGE_5',
                                        )}
                                    </li>
                                </ul>
                            </Text>
                            {!isQuestionnaireOnlyScan && (
                                <InfoBlock>
                                    <RoundedIcon>
                                        <svg
                                            width="28px"
                                            height="28px"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.28033 2.21967C2.98744 1.92677 2.51257 1.92677 2.21967 2.21966C1.92678 2.51255 1.92677 2.98742 2.21967 3.28032L4.52132 5.58201C3.07724 5.9128 2 7.2056 2 8.75V19.25C2 21.0449 3.45507 22.5 5.25 22.5H15.7523C17.2959 22.5 18.5882 21.4238 18.9198 19.9807L24.7194 25.7805C25.0123 26.0734 25.4872 26.0734 25.7801 25.7805C26.073 25.4876 26.073 25.0127 25.7801 24.7198L3.28033 2.21967ZM17.5023 18.5632V19.25C17.5023 20.2165 16.7187 21 15.7523 21H5.25C4.2835 21 3.5 20.2165 3.5 19.25V8.75C3.5 7.7835 4.2835 7 5.25 7H5.93929L17.5023 18.5632Z"
                                                fill="#ffffff"
                                            />
                                            <path
                                                d="M8.68185 5.5L10.1818 7H15.7523C16.7188 7 17.5023 7.7835 17.5023 8.75V14.3205L19.0023 15.8206V12.1455L24.0021 8.72623V19.2765L19.1028 15.9212L24.1828 21.0012C24.8564 21.0372 25.5021 20.5119 25.5021 19.7508V8.25239C25.5021 7.24731 24.3761 6.65323 23.5464 7.22059L19.0023 10.3283V8.75C19.0023 6.95508 17.5472 5.5 15.7523 5.5H8.68185Z"
                                                fill="#ffffff"
                                            />
                                        </svg>
                                    </RoundedIcon>
                                    <p>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.INTRODUCTION.NOT_RECORDED',
                                        )}
                                    </p>
                                </InfoBlock>
                            )}
                        </div>
                        {isMobileSize && <div className="spacer"></div>}
                        <ContentFooter>
                            <ButtonContainer>
                                <ShadowButton onClick={() => onNext()}>
                                    {t(
                                        'CULTURE_FIT_INTRODUCTION.START_TASK_BUTTON',
                                    )}
                                </ShadowButton>
                            </ButtonContainer>
                        </ContentFooter>
                    </ContentColumn>
                </TwoColumnsLayout>
            )}
        </Container>
    );
}

export default withMediaQuery(CulturePreferencesIntro);
