import styled from 'styled-components';
import { breakpoints } from '../../../App.style';

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media only screen and (min-width: ${breakpoints.bigDesktop}) {
        background: #f5f5f5;
    }
    ${(props) =>
        props.isVideoFullscreen
            ? `
        position: absolute;
        top: 0;
        left: 0;
      `
            : ''};
`;

export const VideoContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    min-height: ${(props) => (props.isVideoFullscreen ? '100vh' : '100%')};
    justify-content: center;
    align-self: center;
    flex-direction: column;
    transition: 0.2s all ease-in;
`;

export const VideoLoadingPlaceholder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(67, 89, 112, 0.8);
    opacity: ${(props) => (props.visible ? 1 : 0)};
    z-index: ${(props) => (props.visible ? 5 : -5)};
    transition: 0.3s all ease-in-out;
`;

export const VideoControls = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
`;

export const VideoActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 25px;
    padding: 15px;

    > * {
        cursor: pointer;
    }
`;

export const VideoProgressionContainer = styled.div`
    flex: 1;
    padding: 5px;
    padding-right: 15px;
    transform: translateY(-10px);
`;

export const VideoProgressionTimeContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    padding-bottom: 10px;
`;

export const VideoProgressionBarContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
`;

export const VideoProgressionBarWrap = styled.div`
    position: relative;
    display: block;
    width: 100%;
    background: #cacaca;

    &:first-child {
        z-index: 10;
        transition: 0.1s all ease;
    }
`;
