import styled from 'styled-components';
import {breakpoints} from "../../../App.style";

export const Container = styled.div`
    position: relative;
    min-height: 100vh;
    margin: 0 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-height: 500px) {
        height: fit-content;
    }
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4vw;
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        padding: 3em 2em;
        min-height: unset;
        height: calc(100vh - 6em);
        gap: 1em;
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
        min-height: unset;
        height: calc(100vh - 6em);
    }
    .spacer {
        flex-grow: 1;
    }
`;

export const TwoColumnsLayout = styled.div`
    display: flex;
    flex-flow: row nowrap;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;

export const ImageColumn = styled.div`
    display: flex;
    position: relative;
    width: 45vw;
    min-width: 400px;
    max-width: 900px;
    height: auto;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 50%;
        min-width: unset;
    }
`;

export const ModuleImage = styled.img`
    width: 100%;
    height: auto;
`;

export const ContentColumn = styled.div`
    position: relative;
    flex: 1;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 5vw;
    color: ${(props) => props.theme.textColor};
    @media only screen and (max-width: ${breakpoints.tablet}) {
        min-width: unset;
        justify-content: flex-start;
        margin-left: 0;
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
        justify-content: space-between;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.textColor};
    h1,
    p {
        margin: 0;
        padding: 0;
    }

    p,
    ul {
        margin-top: 15px;
        line-height: 25px;
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
        h1 {
            font-size: 2em;
        }
        p {
            font-size: 1em;
            margin-top: 2em;
        }
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
        h1 {
            font-size: 1.5em;
        }
    }
`;

export const InfoBlock = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding: 15px;
    margin-bottom: 15px;
    background: #f4f4f4;
    align-items: center;
    margin-top: 2em;
    p {
        font-size: 0.9em;
        margin-left: 15px;
    }
`;

export const RoundedIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    background: ${(props) => props.theme.textColor};
`;

export const ContentFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 4em;
`;

export const ButtonContainer = styled.div`
    width: 200px;
    margin-top: 20px;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        width: 100%;
    }
`;
