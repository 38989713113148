import styled, { css, keyframes } from 'styled-components';
import { breakpoints } from '../../App.style';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.theme.backgroundColor};
    @media only screen and (max-height: 900px) {
        height: auto;
    }
    @media only screen and (max-height: 520px) {
        height: fit-content;
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
        min-height: unset;
        height: 100vh;
    }
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInAnimationRule = css`
    ${fadeInAnimation} linear 0.5s;
`;

export const FadeIn = styled.span`
    animation: ${fadeInAnimationRule};
`;

export const Title = styled.h1`
    color: ${(props) => props.theme.textColor};
    margin: 25px 0;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 1.1em;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    &.top-header {
        padding-top: 2em;
    }

    .user-name-title {
        @media only screen and (max-width: ${breakpoints.tablet}) {
            color: ${(props) => props.theme.mainColor};
            font-size: 1.1em;
            font-weight: normal;
        }
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
    }
`;

export const ScanListContainer = styled.div`
    overflow: auto;
    max-height: 50vh;
    .skeleton {
        padding: 20px;
        margin: 10px;
        transition: all 0.5s ease-in-out;
        border-radius: 14px;
        &:hover {
            transform: translateY(-5px);
            transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05),
                0 3px 6px rgba(0, 0, 0, 0.05);
        }
    }
`;

export const ScanButton = styled.div`
    padding: 20px;
    margin: 10px;
    width: 250px;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    transition: all 0.5s ease-in-out;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        width: auto;
    }
    &.enabled {
        &:hover {
            transform: translateY(-5px);
            transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05),
                0 3px 6px rgba(0, 0, 0, 0.05);
        }
    }
    &.disabled {
        opacity: 0.6;
    }
`;

export const CompanyName = styled.h3`
    color: ${(props) => props.theme.mainColor};
    font-size: 0.9em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 0.82em;
    }
`;

export const ScanName = styled.span`
    color: ${(props) => props.theme.textColor};
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 0.85em;
    }
`;

export const ScanSubTitle = styled.span`
    color: ${(props) => props.theme.textColor};
    font-size: 0.85em;
    font-weight: bold;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 0.75em;
    }
    @media only screen and (max-width: ${breakpoints.smallMobile}) {
        font-size: 0.65em;
    }
`;

export const ScanCardTitleWrapper = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ScanSubTitleValue = styled.span`
    color: ${(props) => props.theme.textColor};
    font-size: 0.85em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 0.75em;
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 144px;
    padding: 10px 0;
    .user-name {
        color: ${(props) => props.theme.textColor};
        font-size: 1em;
        font-weight: normal;
        padding: 10px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            color: ${(props) => props.theme.mainColor};
        }
    }
    .log-out {
        color: ${(props) => props.theme.textColor};
        font-size: 1.1em;
        font-weight: 500;
        cursor: pointer;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid ${(props) => props.theme.textColor};
        &:hover {
            color: ${(props) => props.theme.mainColor};
            border: 1px solid ${(props) => props.theme.mainColor};
        }
        @media only screen and (max-width: ${breakpoints.tablet}) {
            padding: 0.7em 3em;
            font-size: 0.8em;
            color: ${(props) => props.theme.mainColor};
            border: 1px solid ${(props) => props.theme.mainColor};
            border-radius: 6em;
            &:hover {
                color: ${(props) => props.theme.textColor};
                border: 1px solid ${(props) => props.theme.textColor};
            }
        }
    }
    img {
        width: 220px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            width: 180px;
        }
    }
`;
