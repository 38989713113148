import { cameraActionTypes } from './camera.actions';

export const cameraInitialState = {
    haveAccess: false,
    isCameraRecording: false,
    error: null,
    isCapturing: false,
    blobDuration: null,
    videoURL: null,
    fileId: null,
    autoUpload: true,
};

const cameraReducer = (state = cameraInitialState, action) => {
    switch (action.type) {
        case cameraActionTypes.CAPTURE_USER_MEDIA_SUCCESS: {
            return {
                ...state,
                haveAccess: true,
                isCapturing: true,
            };
        }

        case cameraActionTypes.CAPTURE_USER_MEDIA_FAILURE: {
            return {
                ...state,
                haveAccess: false,
                isCapturing: false,
                error: action.payload.error,
            };
        }

        case cameraActionTypes.STOP_CAPTURING_USER_MEDIA_SUCCESS: {
            return {
                ...state,
                isCapturing: false,
            };
        }

        case cameraActionTypes.STOP_CAPTURING_USER_MEDIA_FAILURE: {
            return {
                ...state,
                error: action.payload.error,
            };
        }

        case cameraActionTypes.RECORD_USER_MEDIA_SUCCESS: {
            const { fileId, autoUpload } = action.payload;
            return {
                ...state,
                isCameraRecording: true,
                fileId,
                autoUpload,
            };
        }

        case cameraActionTypes.RECORD_USER_MEDIA_FAILURE: {
            return {
                ...state,
                error: action.payload.error,
            };
        }

        case cameraActionTypes.STOP_RECORDING_USER_MEDIA_SUCCESS: {
            const { videoURL } = action.payload;

            return {
                ...state,
                isCameraRecording: false,
                videoURL,
            };
        }
        case cameraActionTypes.STOP_RECORDING_USER_MEDIA_FAILURE: {
            return {
                ...state,
                error: action.payload.error,
            };
        }

        case cameraActionTypes.RESET_RECORDER_SUCCESS: {
            return {
                ...state,
                isCameraRecording: false,
            };
        }
        case cameraActionTypes.RESET_RECORDER_FAILURE: {
            return {
                ...state,
                error: action.payload.error,
            };
        }

        case cameraActionTypes.SET_BLOB_DURATION: {
            const { blobDuration } = action.payload;
            return {
                ...state,
                blobDuration,
            };
        }

        default:
            return state;
    }
};

export default cameraReducer;
