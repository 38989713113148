import styled from 'styled-components';

export const Message = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 60px;
    transform: translateY(-10px);

    h1 {
        color: ${(props) => props.theme.textColor};
        margin: 0;
        font-size: 35px;
        text-align: center;
    }

    h3 {
        color: #8da3b9;
        font-weight: bold;
        font-size: 28px;
        margin: 5px 0;
        text-align: center;
    }

    .timer {
        color: red;
        font-weight: bold;
        font-size: 28px;
    }
`;

export const Title = styled.h1`
    color: ${(props) => props.theme.textColor};
    margin: 10px 0;
`;

export const Subtitle = styled.h3`
    color: ${(props) => props.theme.secondaryTextColor};
    font-weight: normal;
    margin: 0;
`;