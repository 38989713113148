export const uploadsActionTypes = {
    PUT_FILE_ON_QUEUE: 'PUT_FILE_ON_QUEUE',
    UPLOAD_NEXT_IN_QUEUE: 'UPLOAD_NEXT_IN_QUEUE',
    UPLOAD: 'UPLOAD',
    UPLOADING: 'UPLOADING',
    UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
    UPLOAD_FAILURE: 'UPLOAD_FAILURE'
};

export const uploadsActions = {
    upload: ({ invitationId, fileType, fileId, file, fileFormat, uniqueId }) => ({
        type: uploadsActionTypes.UPLOAD,
        payload: { invitationId, fileType, fileId, file, fileFormat, uniqueId },
    }),
    uploading: ({ fileType, fileId, file, fileFormat, uniqueId }) => ({
        type: uploadsActionTypes.UPLOADING,
        payload: { fileType, fileId, file, fileFormat, uniqueId },
    }),
    uploadNextInQueue: () => ({
        type: uploadsActionTypes.UPLOAD_NEXT_IN_QUEUE,
        payload: {},
    }),
};
