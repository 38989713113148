import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
    position: relative;
`;

const InputCheckbox = styled.input.attrs((props) => ({
    id: props.id,
    type: 'checkbox',
}))`
    visibility: hidden;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;

    &:checked + label {
        background-color: ${(props) => props.theme.mainColor};
        border-color: ${(props) => props.theme.mainColor};

        &:after {
            opacity: 1;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
        }
    }
`;

const CheckboxLabel = styled.label.attrs((props) => ({
    htmlFor: props.id,
}))`
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background-color: #fff;
    border: 2px solid #cacaca;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 300ms ease, colour 300ms ease,
        box-shadow 300ms ease, border 300ms ease;

    &:after {
        opacity: 0;
        content: '';
        position: absolute;
        top: 38%;
        left: 31%;
        width: 38%;
        height: 14%;
        border-left: 2px solid #a3a3a3;
        border-bottom: 2px solid #a3a3a3;
        transform: rotate(-45deg);
        z-index: 5;
    }
    &:hover {
        background-color: #00a5ae;
        opacity: 0.8;
    }
`;

function RoundedCheckbox(props) {
    const size = props.size ? props.size : 25;
    return (
        <CheckboxContainer>
            <InputCheckbox
                id={props.id}
                size={size}
                onChange={(event) => {
                    props.onChange(event.target.checked);
                }}
            />
            <CheckboxLabel id={props.id} size={size} />
        </CheckboxContainer>
    );
}

export default RoundedCheckbox;
