export const cameraActionTypes = {
    CAPTURE_USER_MEDIA: 'CAMERA/CAPTURE_USER_MEDIA',
    CAPTURE_USER_MEDIA_SUCCESS: 'CAMERA/CAPTURE_USER_MEDIA_SUCCESS',
    CAPTURE_USER_MEDIA_FAILURE: 'CAMERA/CAPTURE_USER_MEDIA_FAILURE',

    STOP_CAPTURING_USER_MEDIA: 'CAMERA/STOP_CAPTURING_USER_MEDIA',
    STOP_CAPTURING_USER_MEDIA_SUCCESS:
        'CAMERA/STOP_CAPTURING_USER_MEDIA_SUCCESS',
    STOP_CAPTURING_USER_MEDIA_FAILURE:
        'CAMERA/STOP_CAPTURING_USER_MEDIA_FAILURE',

    RECORD_USER_MEDIA: 'CAMERA/RECORD_USER_MEDIA',
    RECORD_USER_MEDIA_SUCCESS: 'CAMERA/RECORD_USER_MEDIA_SUCCESS',
    RECORD_USER_MEDIA_FAILURE: 'CAMERA/RECORD_USER_MEDIA_FAILURE',

    STOP_RECORDING_USER_MEDIA: 'CAMERA/STOP_RECORDING_USER_MEDIA',
    STOP_RECORDING_USER_MEDIA_SUCCESS:
        'CAMERA/STOP_RECORDING_USER_MEDIA_SUCCESS',
    STOP_RECORDING_USER_MEDIA_FAILURE:
        'CAMERA/STOP_RECORDING_USER_MEDIA_FAILURE',

    DISPATCH_BLOB_UPLOAD: 'CAMERA/DISPATCH_BLOB_UPLOAD',

    RESET_RECORDER: 'CAMERA/RESET_RECORDER',
    RESET_RECORDER_SUCCESS: 'CAMERA/RESET_RECORDER_SUCCESS',
    RESET_RECORDER_FAILURE: 'CAMERA/RESET_RECORDER_FAILURE',

    SET_BLOB_DURATION: 'CAMERA/SET_BLOB_DURATION',

    RESET_RECORDING_CHUNK: 'CAMERA/RESET_RECORDING_CHUNK',
};

export const cameraActions = {
    captureUserMedia: () => ({ type: cameraActionTypes.CAPTURE_USER_MEDIA }),
    stopCapturingUserMedia: () => ({
        type: cameraActionTypes.STOP_CAPTURING_USER_MEDIA,
    }),

    recordUserMedia: (invitationId, fileId, autoUpload = true) => ({
        type: cameraActionTypes.RECORD_USER_MEDIA,
        payload: { invitationId, fileId, autoUpload },
    }),
    stopRecordingUserMedia: (invitationId, stopCapturing = true) => ({
        type: cameraActionTypes.STOP_RECORDING_USER_MEDIA,
        payload: { stopCapturing, invitationId },
    }),

    setBlobDuration: (blobDuration) => ({
        type: cameraActionTypes.SET_BLOB_DURATION,
        payload: { blobDuration },
    }),

    dispatchBlobUpload: (invitationId) => ({
        type: cameraActionTypes.DISPATCH_BLOB_UPLOAD,
        payload: { invitationId },
    }),

    resetRecordingChunk: (newFileId, invitationId) => ({
        type: cameraActionTypes.RESET_RECORDING_CHUNK,
        payload: { newFileId, invitationId },
    }),
};
