import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadsActions } from '../../../store/uploads/uploads.actions';
import ErrorView from '../../../views/ErrorView';
import QuestionnaireController from '../../Questionnaire/QuestionnaireController/QuestionnaireController';
import SessionAnalytics from 'services/SessionAnalytics';
import Logger from 'services/Logger';

class CustomModuleController extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progressionView: {
                id: 'progression-view',
                type: 'progression-view',
                steps: this.getAssessmentProgressionSteps(props.modules),
                active: -1,
            },
            currentModule: props.modules[0],
            currentModuleIndex: 0,
            error: null,
        };
    }
    componentDidMount() {
        SessionAnalytics.onModuleNavigation(this.props.modules[0]);
        Logger.logInfo('CustomModuleController - Initializing controller', {
            modules: this.props.modules,
            state: this.state,
        });
    }

    getAssessmentProgressionSteps(modules) {
        const steps = [];
        modules.forEach((module) => {
            const step = { id: 'questionnaire', label: module.label };
            steps.push(step);
        });
        return steps;
    }

    updateModule() {
        const { currentModule, currentModuleIndex } = this.state;
        const nextModule = this.props.modules[currentModuleIndex + 1];

        if (nextModule) {
            Logger.logInfo('CustomModuleController - Updating module', {
                currentModule,
                currentModuleIndex,
                nextModule: { id: nextModule.id, type: nextModule.type },
            });
            SessionAnalytics.onModuleNavigation(nextModule, currentModule);
            this.setState({
                currentModule: nextModule,
                currentModuleIndex: currentModuleIndex + 1,
            });
        } else if (!nextModule) {
            this.props.onComplete();
        }
    }

    submitQuestionnaire(form, questionnaireId) {
        const params = {
            invitationId: this.props.invitationId,
            file: form,
            fileType: 'submission',
            fileId: questionnaireId + '-questionnaire',
        };
        Logger.logInfo(
            'AssessmentController - Submitting questionnaire',
            params,
        );
        this.props.upload(params);
        this.updateModule();
    }

    render() {
        const { language } = this.props;
        const { currentModule, error } = this.state;
        if (!error) {
            if (currentModule.type === 'assessment-questionnaire') {
                return (
                    <QuestionnaireController
                        language={language}
                        module={currentModule}
                        isQuestionnaireOnlyScan={this.props.isQuestionnaireOnlyScan}
                        onQuestionnaireComplete={(form, questionnaireId) =>
                            this.submitQuestionnaire(form, questionnaireId)
                        }
                        modules={this.props.modules}
                    />
                );
            } else {
                return <h2>Something went wrong</h2>;
            }
        } else {
            return <ErrorView error={error} />;
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    upload: (params) => dispatch(uploadsActions.upload(params)),
});

export default connect(null, mapDispatchToProps)(CustomModuleController);
