import React, { Component } from 'react';
import styled from 'styled-components';
import {breakpoints} from "../../../App.style";

const ButtonCommonStyles = styled.button.attrs((props) => ({
    'aria-label': props.ariaLabel ? props.ariaLabel : 'button',
}))`
    position: relative;
    background: ${(props) => (!props.disabled ? props.theme.mainColor : '#ccc')};
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    border: none;
    &:focus {
        outline: 0;
    }
    &:first-letter {
        text-transform: capitalize;
    }
    &:disabled{
        background: #E4EAED;
        color: #8D9BA9;
    }
`;
const Button = styled(ButtonCommonStyles)`
    width: 100%;
    padding: 15px 30px;
    border-radius: 24px;
    transition: all 0.2s ease-in-out;
`;

const ActiveButton = styled(Button)`
    cursor: pointer;
    box-shadow: 0 3px 6px 0 rgba(67, 89, 112, 0.32);

    &:hover {
        background: ${(props) => props.theme.darkerMainColor};
        transition: all 0.2s ease-in;
        box-shadow: 0 3px 6px 0 rgba(67, 89, 112, 0.32);
    }
`;

const SmallButton = styled(ButtonCommonStyles)`
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    border-radius: 100px;
    transition: all 0.2s ease-in-out;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        height: 44px;
        border-radius: 100px;
        font-size: 1em;
    }
    @media only screen and (max-width: ${breakpoints.smallMobile}) {
        font-size: 0.9em;
    }
`;

const ActiveSmallButton = styled(SmallButton)`
    cursor: pointer;
    box-shadow: 0 3px 6px 0 rgba(67, 89, 112, 0.32);
    &:focus {
        outline: 0;
        transition: box-shadow 0.2s ease-in;
    }
    &:hover {
        background: #00939b;
        box-shadow: 0 3px 6px 0 rgba(67, 89, 112, 0.32);
        transition: all 0.2s ease-in;
    }
`;

class ShadowButton extends Component {
    constructor(props) {
        super(props);
        this.size = props.size ? props.size : 'default';
    }
    renderButton() {
        if (!this.props.disabled) {
            return (
                <ActiveButton {...this.props} onClick={(event) => this.props.onClick(event)}>
                    {this.props.children}
                </ActiveButton>
            );
        } else {
            return (
                <Button {...this.props} onClick={(event) => this.props.onClick(event)}>
                    {this.props.children}
                </Button>
            );
        }
    }

    renderSmallButton() {
        if (!this.props.disabled) {
            return (
                <ActiveSmallButton {...this.props} onClick={(event) => this.props.onClick(event)}>
                    {this.props.children}
                </ActiveSmallButton>
            );
        } else {
            return (
                <SmallButton {...this.props} onClick={(event) => this.props.onClick(event)}>
                    {this.props.children}
                </SmallButton>
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.size !== 'small' ? this.renderButton() : this.renderSmallButton()}
            </React.Fragment>
        );
    }
}

export default ShadowButton;
