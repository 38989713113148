import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Container,
    Text,
    ProgressionContainer,
} from './UploadingProgressionView.style';
import LinearProgress from '@material-ui/core/LinearProgress';
import { uploadsActions } from 'store/uploads/uploads.actions';
import { withStyles } from '@material-ui/core/styles';
import Logger from 'services/Logger';

const Loader = withStyles(() => ({
    root: {
        // height: 10,
        // borderRadius: 5
    },
}))(LinearProgress);

const UploadingProgressionView = (props) => {
    const { invitationId, t, onComplete } = props;
    const [completedPercentage, setCompletedPercentage] = useState(5);
    const [bufferPercentage, setBufferPercentage] = useState(
        Math.random() * 10 + 5,
    );
    const [failuresRetries, setFailuresRetries] = useState(0);
    const dispatch = useDispatch();
    const { toUpload, uploading, failed } = useSelector(
        (state) => state.uploads,
    );
    const isUploadCompleted =
        toUpload.length === 0 && uploading.length === 0 && failed.length === 0;

    const setAllFinished = useCallback(() => {
        Logger.logInfo(
            'UploadingProgressionView - Upload progress set completed',
        );
        setCompletedPercentage(100);
        setBufferPercentage(100);
        onComplete();
    }, [onComplete]);

    useEffect(() => {
        if (uploading.length < 2) {
            dispatch(uploadsActions.uploadNextInQueue());
        }
    }, [dispatch, uploading.length]);

    useEffect(() => {
        if (failed.length > 0) {
            failed.forEach((file) => {
                dispatch(uploadsActions.upload({ ...file, invitationId }));
            });
            setFailuresRetries((failuresRetries) => failuresRetries + 1);
        }
    }, [dispatch, failed, invitationId]);

    useEffect(() => {
        if (failuresRetries >= 30) {
            setAllFinished();
        }
    }, [failuresRetries, setAllFinished]);

    useEffect(() => {
        Logger.logInfo('UploadingProgressionView - Starting uploading view');
        const uploadTimeout = 1000 * 60 * 30; // max time to retain user
        const checkUploadsTimeout = setTimeout(() => {
            Logger.logWarning(
                `UploadingProgressionView - Upload Timeout after ${uploadTimeout}ms`,
            );
            setAllFinished();
        }, uploadTimeout);
        return clearTimeout(checkUploadsTimeout);
    }, [setAllFinished]);

    useEffect(() => {
        Logger.logInfo('UploadingProgressionView - Uploading...', {
            toUpload,
            uploading,
            failed,
        });
    }, [failed, toUpload, uploading]);

    useEffect(() => {
        setCompletedPercentage((completedPercentage) => {
            return completedPercentage < 90
                ? completedPercentage + 10
                : completedPercentage;
        });
        setBufferPercentage((bufferPercentage) => {
            return bufferPercentage < 95
                ? bufferPercentage + 10
                : bufferPercentage;
        });
    }, [uploading]);

    useEffect(() => {
        if (isUploadCompleted) {
            setAllFinished();
        }
    }, [isUploadCompleted, setAllFinished]);

    return (
        <Container>
            <Text>
                <h1>{t('UPLOADING_PROGRESSION_VIEW.TITLE')}</h1>
                <h2>{t('UPLOADING_PROGRESSION_VIEW.MESSAGE_1')}</h2>
                <h2>{t('UPLOADING_PROGRESSION_VIEW.MESSAGE_2')}</h2>
            </Text>
            <ProgressionContainer>
                <Loader
                    variant="buffer"
                    value={completedPercentage}
                    valueBuffer={bufferPercentage}
                />
            </ProgressionContainer>
        </Container>
    );
};

export default withTranslation()(UploadingProgressionView);
