import React, { useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import {
    Container,
    CameraSetupViewContent,
    ButtonContainer,
    InstructionImagesContainer,
    InstructionImagesColumn,
    InstructionImagesTitle,
    IntroductionImage,
    Paragraph,
    CameraSetupTitle,
    AllowAccessMoreInformation,
    InfoIconContainer,
} from './CameraSetupView.style';

import ShadowButton from 'components/shared/ShadowButton/ShadowButton';

import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InfoSignIcon } from 'evergreen-ui';

import WellLitIcon from 'assets/images/Well-Lit_Icon_SM_v04.png';
import NoDistractionsIcon from 'assets/images/Distractions_Icon_SM_v04.png';
import NoHandsInFaceIcon from 'assets/images/Hands-Food_Icon_SM_v04.png';
import CameraSetup from 'components/shared/CameraSetup/CameraSetup';

function CameraSetupView(props) {
    const { videoStream, t, onAccept, onComplete, cameraError } = props;
    const [showAllowCameraAccess, setShowAllowCameraAccess] = useState(true);
    const [
        showInstructionsEnvironmentSetup,
        setshowInstructionsEnvironmentSetup,
    ] = useState(false);
    const [showCameraSetup, setShowCameraSetup] = useState(false);

    const handleClickAllowCameraAccess = () => {
        onAccept();
        setShowAllowCameraAccess(false);
        setshowInstructionsEnvironmentSetup(true);
    };

    const handleClickIntroductionCameraSetup = () => {
        setshowInstructionsEnvironmentSetup(false);
        setShowCameraSetup(true);
    };

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 300,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);
    return (
        <Container showCameraSetup={showCameraSetup}>
            {showAllowCameraAccess && (
                <CameraSetupViewContent>
                    <CameraSetupTitle>
                        {t(
                            'CAMERA_SETUP_VIEW.ALLOW_BROWSER_ACCESS_CAMERA.TITLE',
                        )}
                    </CameraSetupTitle>
                    <Paragraph>
                        {t(
                            'CAMERA_SETUP_VIEW.ALLOW_BROWSER_ACCESS_CAMERA.MESSAGE',
                        )}
                    </Paragraph>
                    <ButtonContainer>
                        <ShadowButton onClick={handleClickAllowCameraAccess}>
                            {t(
                                'CAMERA_SETUP_VIEW.ALLOW_BROWSER_ACCESS_CAMERA.CONTINUE_BUTTON',
                            )}
                        </ShadowButton>
                    </ButtonContainer>
                </CameraSetupViewContent>
            )}
            {showInstructionsEnvironmentSetup && (
                <CameraSetupViewContent>
                    <CameraSetupTitle>
                        {t(
                            'CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.TITLE',
                        )}
                    </CameraSetupTitle>
                    <Paragraph>
                        {t(
                            'CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.MESSAGE',
                        )}
                    </Paragraph>
                    <InstructionImagesContainer>
                        <InstructionImagesColumn>
                            <InstructionImagesTitle>
                                {t(
                                    'CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.ENVIRONMENT_SETUP_1',
                                )}
                            </InstructionImagesTitle>
                            <IntroductionImage
                                alt="Well-lit and quiet room"
                                src={WellLitIcon}
                            />
                        </InstructionImagesColumn>
                        <InstructionImagesColumn>
                            <InstructionImagesTitle>
                                {t(
                                    'CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.ENVIRONMENT_SETUP_2',
                                )}
                            </InstructionImagesTitle>
                            <IntroductionImage
                                alt="No Distractions"
                                src={NoDistractionsIcon}
                            />
                        </InstructionImagesColumn>

                        <InstructionImagesColumn>
                            <InstructionImagesTitle>
                                {t(
                                    'CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.ENVIRONMENT_SETUP_3',
                                )}
                            </InstructionImagesTitle>
                            <IntroductionImage
                                alt="No hands in face or eating and drinking"
                                src={NoHandsInFaceIcon}
                            />
                        </InstructionImagesColumn>
                    </InstructionImagesContainer>
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                {cameraError === null &&
                                    (videoStream ? (
                                        t(
                                            'CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.TOOLTIP_SUCCESS',
                                        )
                                    ) : (
                                        <React.Fragment>
                                            <AllowAccessMoreInformation>
                                                {t(
                                                    'CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.TOOLTIP_GIVE_ACCESS',
                                                )}
                                            </AllowAccessMoreInformation>
                                        </React.Fragment>
                                    ))}
                                {cameraError && (
                                    <React.Fragment>
                                        <AllowAccessMoreInformation>
                                            <Trans
                                                i18nKey="CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.TOOLTIP_ERROR_MESSAGE_1"
                                                components={[<strong />]}
                                            />
                                        </AllowAccessMoreInformation>
                                        <AllowAccessMoreInformation>
                                            <Trans
                                                i18nKey="CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.TOOLTIP_ERROR_MESSAGE_2"
                                                components={[<strong />]}
                                            />
                                        </AllowAccessMoreInformation>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        }
                        placement="top">
                        <InfoIconContainer>
                            <InfoSignIcon size={20} />
                        </InfoIconContainer>
                    </HtmlTooltip>
                    <ButtonContainer>
                        <ShadowButton
                            disabled={!videoStream}
                            onClick={handleClickIntroductionCameraSetup}>
                            {t(
                                'CAMERA_SETUP_VIEW.INSTRUCTIONS_FOR_GOOD_ENVIRONMENT_SETUP.CONTINUE_BUTTON',
                            )}
                        </ShadowButton>
                    </ButtonContainer>
                </CameraSetupViewContent>
            )}
            {showCameraSetup && (
                <CameraSetup
                    onComplete={onComplete}
                    videoStream={videoStream}
                />
            )}
        </Container>
    );
}

export default withTranslation()(CameraSetupView);
