import React, { useEffect } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import Stepper from 'components/shared/Stepper/Stepper';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import {
    Layout,
    TextContainer,
    ButtonContainer,
    Wrapper,
} from './AssessmentProgressionView.style';

import i18next from 'i18next';
import Logger from 'services/Logger';
import withMediaQuery from "../../hoc/MediaQuery/withMediaQuery";

const AssessmentProgressionView = (props) => {
    const { t,isMobileSize } = useTranslation();

    useEffect(() => {
        Logger.logInfo(`AssessmentProgressionView - Props updated`, {
            activeStep: props.activeStep,
            steps: props.steps,
        });
    });

    const renderTitle = () => {
        if (props.activeStep !== 0) {
            const lastStep = props.steps[props.activeStep - 1];

            if (lastStep.id === 'culture-fit')
                return t(
                    'ASSESSMENT_PROGRESSION_VIEW.WHEN_YOU_COMPLETED_A_TASK.TITLE',
                    {
                        TASK_NAME: t(
                            'ASSESSMENT_PROGRESSION_VIEW.TASK_LABELS.CULTURE_FIT',
                        ),
                    },
                );
            if (lastStep.id === 'culture-fit-questionnaire')
                return t(
                    'ASSESSMENT_PROGRESSION_VIEW.WHEN_YOU_COMPLETED_A_TASK.TITLE',
                    {
                        TASK_NAME: t(
                            'ASSESSMENT_PROGRESSION_VIEW.TASK_LABELS.CULTURE_FIT_QUESTIONNAIRE',
                        ),
                    },
                );
            if (lastStep.id === 'speaking')
                return t(
                    'ASSESSMENT_PROGRESSION_VIEW.WHEN_YOU_COMPLETED_A_TASK.TITLE',
                    {
                        TASK_NAME: t(
                            'ASSESSMENT_PROGRESSION_VIEW.TASK_LABELS.SPEAKING',
                        ),
                    },
                );
            if (lastStep.id === 'mental-test')
                return t(
                    'ASSESSMENT_PROGRESSION_VIEW.WHEN_YOU_COMPLETED_A_TASK.TITLE',
                    {
                        TASK_NAME: t(
                            'ASSESSMENT_PROGRESSION_VIEW.TASK_LABELS.MENTAL_TEST',
                        ),
                    },
                );
            if (lastStep.id === 'questionnaire')
                return t(
                    'ASSESSMENT_PROGRESSION_VIEW.WHEN_THERE_ARE_NO_MORE_TASKS.TITLE',
                    {
                        TASK_NAME: lastStep.label[i18next.language],
                    },
                );
        }
    };

    const totalAssessmentTime = props.steps.reduce(
        (accumulator, currentStep) => {
            if (currentStep.id === 'culture-fit') return accumulator + 5;
            else if (currentStep.id === 'culture-fit-questionnaire')
                return accumulator + 5;
            else if (currentStep.id === 'speaking') return accumulator + 10;
            else if (currentStep.id === 'mental-test') return accumulator + 10;
            else if (currentStep.id === 'questionnaire')
                return accumulator + 10;
            else return accumulator;
        },
        0,
    );

    const renderMessage = () => {
        // if is only module and it's last module
        if (props.activeStep === 1 && props.steps.length === 1) {
            return t(
                'ASSESSMENT_PROGRESSION_VIEW.WHEN_YOU_COMPLETED_THE_ONLY_TASK.MESSAGE',
            );
        }

        if (props.steps.length > 1 && props.activeStep !== props.steps.length) {
            return t(
                'ASSESSMENT_PROGRESSION_VIEW.WHEN_YOU_COMPLETED_A_TASK.MESSAGE',
            );
        }

        // if there are multiple modules and is the last one
        if (
            props.activeStep > 1 &&
            props.steps.length > 1 &&
            props.activeStep === props.steps.length
        ) {
            return t(
                'ASSESSMENT_PROGRESSION_VIEW.WHEN_THERE_ARE_NO_MORE_TASKS.MESSAGE',
            );
        }
    };

    const getButtonPlaceholder = () => {
        // if is only module and it's last module
        if (props.activeStep === 0) {
            return t(
                'ASSESSMENT_PROGRESSION_VIEW.WHEN_IS_INTRODUCTION.START_SCAN_BUTTON',
            );
        }

        if (
            props.activeStep > 0 &&
            props.steps.length > 1 &&
            props.activeStep !== props.steps.length
        ) {
            return t(
                'ASSESSMENT_PROGRESSION_VIEW.WHEN_YOU_COMPLETED_A_TASK.CONTINUE_BUTTON',
            );
        }

        // if there are multiple modules and is the last one
        if (props.activeStep === props.steps.length) {
            return t(
                'ASSESSMENT_PROGRESSION_VIEW.WHEN_THERE_ARE_NO_MORE_TASKS.CONTINUE_BUTTON',
            );
        }
    };

    const renderContent = () => {
        if (props.activeStep === 0) return startingMessage();
        else {
            return (
                <React.Fragment>
                    <h1>{renderTitle()}</h1>
                    <p>{renderMessage()}</p>
                </React.Fragment>
            );
        }
    };

    const startingMessage = () => {
        return (
            <React.Fragment>
                <h1>
                    {t(
                        'ASSESSMENT_PROGRESSION_VIEW.WHEN_IS_INTRODUCTION.TITLE',
                    )}
                </h1>
                <h2>
                    {t(
                        'ASSESSMENT_PROGRESSION_VIEW.WHEN_IS_INTRODUCTION.SUBTITLE',
                        {
                            total_assessment_time: totalAssessmentTime,
                        },
                    )}
                </h2>
                <p>
                    {' '}
                    {t(
                        'ASSESSMENT_PROGRESSION_VIEW.WHEN_IS_INTRODUCTION.MESSAGE_2',
                    )}
                </p>
            </React.Fragment>
        );
    };

    return (
        <Layout>
            <Wrapper>
                <TextContainer>{renderContent()}</TextContainer>
                <Stepper steps={props.steps} activeStep={props.activeStep} />
            </Wrapper>
            {isMobileSize && <div className="spacer"></div>}
            <ButtonContainer>
                <ShadowButton
                    size={isMobileSize ? 'small' : 'default'}
                    onClick={() => props.onComplete()}>
                    {getButtonPlaceholder()}
                </ShadowButton>
            </ButtonContainer>
        </Layout>
    );
};

export default withMediaQuery(withTranslation()(AssessmentProgressionView));
