import { preloadResource } from './preloader';
import SessionAnalytics from 'services/SessionAnalytics';

export function getInternetSpeed() {
    return new Promise(async (resolve, reject) => {
        const resource =
            'https://s3-eu-west-1.amazonaws.com/neurolytics.public/images/logo-neurolytics-high-quality.jpg';

        const startTime = new Date();
        try {
            const request = await preloadResource(resource);
            const endTime = new Date();

            const duration = (endTime - startTime) / 1000;
            const bitsLoaded = request.size * 8;
            const speedBps = (bitsLoaded / duration).toFixed(2);
            const speedKbps = (speedBps / 1024).toFixed(2);
            const speedMbps = (speedKbps / 1024).toFixed(2);

            const output = {
                kbps: +speedKbps,
                mbps: +speedMbps,
            };

            SessionAnalytics.onInternetSpeedTestEvent(output);
            resolve(output);
        } catch (error) {
            reject(error);
        }
    });
}
