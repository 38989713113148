import styled from 'styled-components';

export const PreviewContainer = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    height: 100%;
    align-items: center;
    video {
        width: 100%;
        height: 100%;
        background: black;
    }
`;

export const ButtonContainer = styled.div`
    border-radius: 33.721px;
    background: rgba(66, 89, 111, 0.85);
    padding: 8px;
    position: absolute;
    bottom: 20px;
    right: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    span {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        @media only screen and (max-width: 1024px) {
            font-size: 0.8em;
        }
    }
    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
`;
export const BrightnessMetrics = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    font-weight: bold;
    color: ${(props) => props.theme.mainColor};
`;

export const FaceBrightnessMetrics = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    padding-top: 30px;
    font-weight: bold;
    color: ${(props) => props.theme.mainColor};
`;

export const FaceDetectedMessage = styled.span`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    font-weight: bold;
    color: #46c40c;
`;

export const FaceNotDetectedMessage = styled.span`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    font-weight: bold;
    color: #c91404;
`;

export const FaceCenteredMessage = styled.span`
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    font-weight: bold;
    color: #ffd42e;
`;

export const FpsMetrics = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    font-weight: bold;
    color: ${(props) => props.theme.mainColor};
`;
