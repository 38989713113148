const configActions = {
    STORE_SCAN_IDS: 'CONFIG/STORE_SCAN_IDS',
    FETCH_COMPANY: 'CONFIG/FETCH_COMPANY',
    FETCH_COMPANY_SUCCESS: 'CONFIG/FETCH_COMPANY_SUCCESS',
    FETCH_COMPANY_FAILURE: 'CONFIG/FETCH_COMPANY_FAILURE',
    FETCH_CONFIG: 'CONFIG/FETCH_CONFIG',
    FETCH_CONFIG_SUCCESS: 'CONFIG/FETCH_CONFIG_SUCCESS',
    FETCH_CONFIG_FAILURE: 'CONFIG/FETCH_CONFIG_FAILURE',
    FINISH_SCAN: 'CONFIG/FINISH_SCAN',
    FINISH_SCAN_SUCCESS: 'CONFIG/FINISH_SCAN_SUCCESS',
    FINISH_SCAN_FAILURE: 'CONFIG/FINISH_SCAN_FAILURE'
}

export default configActions;
