import styled from 'styled-components';
import { breakpoints } from '../../../App.style';

export const Container = styled.div`
    padding: 8px 16px;
    @media only screen and (max-width: ${breakpoints.smallDesktop}) {
        padding: 8px 40px;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 1vw 8vw;
    }
`;
export const Section = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8%;
    padding: 24px 0 64px 0;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        flex-direction: column;
    }
    @media only screen and (max-width: 900px) {
        gap: 8%;
    }
`;
export const LeftSection = styled.div`
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 28px;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
        padding: 0 0 4em 0;
    }
`;
export const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
`;
export const Title = styled.div`
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    color: #42596f;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 2em;
    }
    span {
        color: #00a5ac;
    }
    &.small {
        font-weight: 700;
        font-size: 16px;
        line-height: 27px;
        color: rgba(66, 89, 111, 0.85);
    }
`;
export const Description = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    color: rgba(66, 89, 111, 0.85);
`;
export const Wrapper = styled.div`
    &.disabled {
        opacity: 0.7;
    }
    &.sub {
        padding: 0 24px;
        width: 80%;
    }
`;
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
`;
export const ButtonWithIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 24px;
    gap: 12px;
    height: 40px;
    background: white;
    box-shadow: 0 0 24px rgba(65, 89, 112, 0.24);
    border-radius: 100px;
    color: #415970;
    &:hover {
        background: #415970;
        box-shadow: 0 2px 24px rgba(65, 89, 112, 0.4);
        cursor: pointer;
        color: white;
    }
    .icon-svg {
        width: 32px;
        height: 32px;
    }
    .button-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            line-height: 1.5em;
            font-size: 0.7em;
        }
    }
`;
export const Header = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 2em;
`;

export const LogoContainer = styled.div`
    display: flex;
    width: 300px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2em;
    height: 40px;

    img {
        width: 50%;
    }
`;
export const LanguageSwitcherContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1244px;
    margin-top: 2em;
    align-items: flex-start;
    height: 40px;
`;

export const Button = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    gap: 10px;
    width: 100%;
    height: 48px;
    background: #b8f0c7;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #1aa277;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 1em;
    }
    &.disabled {
        background: rgba(217, 217, 217, 0.6);
        color: rgba(66, 89, 111, 0.61);
        opacity: 0.7;
    }
`;
export const BrowserWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    &.disabled {
        opacity: 0.7;
    }
`;
export const Browser = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #42596f;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        line-height: 1.5em;
        font-size: 0.8em;
    }
`;
export const Line = styled.div`
    border-top: 1px solid rgba(66, 89, 111, 0.6);
    margin: auto;
    padding: 16px 0;
    opacity: 0.7;
`;
