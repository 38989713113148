import styled, { css, keyframes } from 'styled-components';
import { breakpoints } from '../../../App.style';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: flex-start;
    align-items: center;
    background: ${(props) => props.theme.backgroundColor};
    @media only screen and (max-width: ${breakpoints.mobile}) {
        justify-content: flex-start;
        height: auto;
    }
    @media only screen and (max-height: 500px) {
        height: auto;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.bigDesktop}) {
        padding: 0 4vw;
    }
    .spacer {
        flex-grow: 1;
    }
`;

export const LanguageSwitcherContainer = styled.div`
    justify-self: end;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 82%;
    max-width: 1280px;
    justify-content: space-between;
    align-items: baseline;
    height: 100%;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        .questions {
            width: 90%;
        }
    }
`;

export const AsideBlock = styled.aside`
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    padding-bottom: 120px;
    width: 45%;
    max-width: 500px;
    justify-content: start;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 90%;
        height: auto;
        margin-right: 0;
        padding-bottom: 0;
        max-width: none;
    }
`;

export const FormBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
    max-width: 650px;
    justify-content: start;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 90%;
        height: auto;
        max-width: none;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
        flex-direction: column;
        height: auto;
    }
`;

const fadeInAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeInAnimationRule = css`
    ${fadeInAnimation} linear 0.5s;
`;

export const FadeIn = styled.span`
    animation: ${fadeInAnimationRule};
`;

export const Title = styled.h2`
    color: ${(props) => props.theme.textColor};
    margin: 10px 0;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 1.5em;
        text-align: left;
        margin: 0 0 1em 0;
    }
`;

export const FormTitle = styled.h1`
    color: ${(props) => props.theme.textColor};
    margin: 10px 0;
    font-size: 1.3em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 1.5em;
        text-align: center;
    }
`;

export const Headline = styled.h3`
    color: ${(props) => props.theme.textColor};
    font-weight: normal;
    margin: 0 0 20px 0;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 1em;
        text-align: left;
        .result {
            padding: 0 1.5em;
        }
    }
`;

export const Subtitle = styled.h2`
    color: ${(props) => props.theme.textColor};
    font-weight: bold;
    font-size: 1.2em;
    margin: 10px 0 8px 0;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 1em;
        text-align: left;
    }
`;

export const Description = styled.p`
    color: ${(props) => props.theme.textColor};
    font-weight: normal;
    margin: 0 0 15px 0;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 1em;
        text-align: left;
    }
    a {
        color: #13a6ad;
    }
`;

export const FieldContainer = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    border-radius: 100px;
    background: #fff;
    margin: 10px 0;
    box-shadow: 0 14px 39px -28px rgba(54, 129, 199, 1);
    overflow: hidden;
    border: 1px solid rgba(141, 155, 169, 1);
    &.has-error {
        border: 1px solid rgba(255, 87, 86, 1);
    }
    &.is-completed {
        border: 1px solid rgba(26, 166, 173, 1);
    }
    &:hover:not(.disabled) {
        border: 1px solid rgba(84, 106, 126, 1);
    }
    &.disabled {
        border: 1px solid rgba(141, 155, 169, 1);
    }

    label {
        position: absolute;
        width: 60px;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        width: 20px;
        path {
            fill: rgba(84, 106, 126, 1);
        }
        &.has-error {
            path {
                fill: rgba(255, 87, 86, 1);
            }
        }
        &.is-completed {
            path {
                fill: rgba(26, 166, 173, 1);
            }
        }
        &.disabled {
            path {
                fill: rgba(84, 106, 126, 1);
            }
        }
        @media only screen and (max-width: ${breakpoints.mobile}) {
            width: 1em;
        }
    }

    @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 1em;
        height: 2.5em;
    }
`;

export const ButtonContainer = styled.div`
    margin-top: 1em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        margin-bottom: 1em;
    }
`;

export const Input = styled.input.attrs((props) => ({
    id: props.id,
    name: props.name,
    autocomplete: props.autocomplete,
    type: props.type ? props.type : 'text',
    placeholder: props.placeholder,
    value: props.value,
}))`
    width: 100%;
    height: 100%;
    padding: 0 20px 0 60px;
    color: ${(props) => props.theme.textColor};
    outline: 0;
    border: none;
    background: none;

    &::placeholder {
        color: ${(props) => props.theme.secondaryTextColor};
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        background-color: rgba(242, 244, 245, 1);
    }

    @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 1em;
    }
`;

export const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    width: 100%;
    padding: 2em 0;
    .registration-logo{
        justify-self: center;
    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 1em;
    color: #435970;
    font-weight: bold;
    padding: 16px 0 0 16px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    width: 100%;
    img {
        width: 220px;
        @media only screen and (max-width: ${breakpoints.mobile}) {
            width: 180px;
        }
    }
`;

export const ErrorMessage = styled.span`
    color: rgba(255, 87, 86, 1);
    opacity: ${(props) => (props.visible ? 1 : 0)};
    user-select: ${(props) => (props.visible ? 'auto' : 'none')};
    display: inline-block;
    height: 40px;
    margin-top: 10px;
    margin-left: 20px;
    transition: 0.2s ease-in all;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 0.8em;
    }
`;

export const ShowPasswordSwitch = styled.span`
    color: ${(props) => props.theme.textColor};
    margin-left: 10px;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        .MuiFormControlLabel-root .MuiTypography-root {
            font-size: 0.9em;
        }
    }
`;

export const InfoTooltip = styled('div')`
    font-size: 13px;
    position: absolute;
    top: calc(100% - 34px);
    left: 28px;
    width: 7vw;
    visibility: hidden;
    color: transparent;
    background-color: transparent;
    padding: 16px;
    border-radius: 18px;
    transition: visibility 0.5s, color 0.5s, background-color 0.5s,
        padding 0.5s ease-in-out;
`;
export const InfoIcon = styled('div')`
    font-size: 19px;
    font-weight: bold;
    color: white;
    width: 24px;
    height: 24px;
    background-color: #435970;
    border-radius: 50%;
    text-align: center;
    &:hover {
        background-color: #849cb1;
        cursor: pointer;
    }
`;

export const InfoIconWrapper = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 4px;
    & ${InfoIcon}:hover + ${InfoTooltip} {
        visibility: visible;
        color: #435970;
        border: 1px solid #849cb1;
        line-height: 26px;
        padding: 16px;
        border-radius: 18px;
        background-color: #f0f6fb;
        cursor: pointer;
    }
`;

export const FieldWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
export const ValidationErrorMessage = styled('div')`
    color: rgba(255, 87, 86, 1);
    padding-left: 32px;
    font-size: 14px;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 0.8em;
    }
`;
