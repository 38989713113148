import { getCultureFitVideoId, getInterviewVideoId } from './module-identificators';
import { getInternetSpeed } from './internet-quality';

// could be either video or image
export function preloadResource(resourceUrl) {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', resourceUrl, true);
        request.responseType = 'blob';

        request.onload = (data) => {
            if (data.target.status === 200) {
                resolve(data.target.response);
            }
        };

        request.onerror = (error) => {
            reject(error);
        };

        request.send();
    });
}

export async function getVideosToPreload(modules) {
    let resolution;
    try {
        const internet = await getInternetSpeed();
        resolution = +internet.mbps < 100 ? 'low_res' : 'high_res';
    } catch (error) {
        resolution = 'high_res';
    }

    const getVideosFromCultureFit = () => {
        const cultureFitModule = modules.find((module) => module.type === 'culture-fit-video');

        if (cultureFitModule) {
            return [
                { videoId: getCultureFitVideoId(), videoUrl: cultureFitModule.videos[resolution] },
            ];
        } else return [];
    };

    const getVideosFromInterviewModule = () => {
        const interviewModule = modules.find((module) => module.type === 'interview-module');

        if (interviewModule) {
            const questionsWithVideo = [...interviewModule.questions].filter(
                (question) => question.videos
            );
            return questionsWithVideo.map((question) => ({
                videoId: getInterviewVideoId(question.questionId),
                videoUrl: question.videos[resolution],
            }));
        } else return [];
    };

    const cultureFitVideos = getVideosFromCultureFit();
    const interviewModuleVideos = getVideosFromInterviewModule();

    return [...cultureFitVideos, ...interviewModuleVideos];
}
