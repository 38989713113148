import styled from 'styled-components';
import {breakpoints} from "../../../App.style";

export const CameraSetupComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 75vw;
    height: 100vh;
    justify-content: center;
    @media only screen and (max-height: 800px) {
        height: fit-content;
    }
`;
export const Container = styled.div`
    margin: 4% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const TwoColumnsLayout = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    gap: 4%;
`;

export const VideoColumn = styled.div`
    display: flex;
    min-width: 200px;
    max-width: 800px;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 50%;
`;

export const CameraComponentContainer = styled.div`
    width: 100%;
    height: auto;
`;
export const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContentColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    color: ${(props) => props.theme.textColor};
    width: 40%;
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.textColor};

    h1,
    p {
        margin: 0;
        padding: 0;
    }

    p {
        margin-top: 15px;
    }
`;

export const StepsList = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 5%;
    list-style: none;
    margin: 32px 0;
    padding: 0;
    @media only screen and (max-width: 1024px) {
        flex-wrap: wrap;
        margin: 16px 0;
        gap: 3%;
    }
`;

export const Step = styled.li`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    align-items: center;
    max-width: 120px;
`;

export const StepLabel = styled.span`
    display: flex;
    justify-content: center;
    font-size: 0.9em;
    font-weight: bold;
    max-width: 120px;
    line-height: 1.2em;
    margin-bottom: 5px;
    @media only screen and (max-width: 1024px) {
        font-size: 0.7em;
    }
`;

export const IconWrapper = styled.div`
    max-width: 100px;
    margin: auto;
    @media only screen and (max-width: 1024px) {
        max-width: 60px;
    }
    .camera-setup-icon {
        transform: scale(0.75);
        width: 100%;
        height: 100%;
    }
`;

export const StepExplanation = styled.div`
    font-size: 0.9em;
    margin: 0;
    text-align: center;
    @media only screen and (max-width: 1024px) {
        font-size: 0.7em;
    }
`;
export const StepInfoIcon = styled.div`
    padding: 0;
    margin: 15px 0 0 0;
    &:hover {
        cursor: pointer;
        svg {
            color: #00a5ae;
        }
    }
`;

export const ContentFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    justify-self: end;
    gap: 24px;
`;

export const ButtonContainer = styled.div`
    width: 200px;
    align-self: end;
`;

export const Line = styled.div`
    width: 100%;
    border-top: 1px solid #42596f99;
`;
export const DescWrapper = styled.div`
    color: rgba(65, 89, 112, 0.6);
    font-size: 16px;
    line-height: 24px;
    width: 50%;
    margin: 32px 0;
    align-self: start;
    background-color: white;
    padding: 8px 0;
    border-radius: 8px;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 0.8em;
    }
    &.desc-modal {
        width: 100%;
        margin: 0;
        color: #435970;
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 0.8em;
        }
    }
    .desc-title-setup {
        font-size: 20px;
        font-weight: 700;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1em;
        }
    }
    .desc-setup {
        padding-top: 16px;
    }
`;
export const ConditionsWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #f4f4f4;
`;

export const ConditionsParagraph = styled.span`
    padding-left: 8px;
    color: #415970;
    font-size: 0.85em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 0.7em;
    }
`;
