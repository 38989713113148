import React from 'react';
import styled from 'styled-components';
import {breakpoints} from "../App.style";

export const LayoutContainer = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background: ${(props) => props.theme.backgroundColor};
    align-items: center;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding-left: 2vw;
        padding-right: 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding-left: 4vw;
        padding-right: 4vw;    }
`;

export const Layout = styled.div`
    display: flex;
    flex-direction: column;
    width: 80vw;
    max-width: 680px;
    min-height: 100vh;
    justify-content: center;
`;

export function AppLayout(props) {
    return (
        <LayoutContainer>
            <Layout>
                {props.children}
            </Layout>
        </LayoutContainer>
    )
}