import styled from 'styled-components';

export const VideoLoadingPlaceholder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(67, 89, 112, 0.8);
    opacity: ${(props) => (props.visible ? 1 : 0)};
    z-index: ${(props) => (props.visible ? 5 : -5)};
    transition: 0.3s all ease-in-out;
`;