import React from 'react';
import {
    Container,
    ContentLayout,
    Image,
    Message,
    ButtonContainer,
    Footer,
} from './CustomLayout.style';
import { withTranslation } from 'react-i18next';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import questionIcon from 'assets/images/questions_icon.png';

export function CustomLayout(props) {
    const { t } = props;
    const renderTitle = (title, uniqueKey) => {
        return (
            <h1 key={uniqueKey} className="layout-title">
                {t(title.translations_key)}
            </h1>
        );
    };

    const renderSubtitle = (subtitle, uniqueKey) => {
        return (
            <h2 key={uniqueKey} className="layout-subtitle">
                {t(subtitle.translations_key)}
            </h2>
        );
    };

    const renderParagraph = (paragraph, uniqueKey) => {
        return (
            <p key={uniqueKey} className="layout-paragraph">
                {t(paragraph.translations_key)}
            </p>
        );
    };

    const renderText = (text, uniqueKey) => {
        return (
            <span key={uniqueKey} className="layout-text">
                {t(text.translations_key)}
            </span>
        );
    };

    const renderList = (items, uniqueKey) => {
        const listItems = renderLayout(items, uniqueKey);

        return (
            <ul key={uniqueKey} className="layout-list">
                {listItems.map((item, index) => (
                    <li key={`${uniqueKey}-${index}`}>{item}</li>
                ))}
            </ul>
        );
    };

    const renderLayout = (items, parentKey = 0) => {
        return items.map((layoutItem, index) => {
            const createId = (item) => `${parentKey}-${item}-${index}`;
            switch (layoutItem.type) {
                case 'title': {
                    return renderTitle(layoutItem, createId('title'));
                }
                case 'subtitle': {
                    return renderSubtitle(layoutItem, createId('subtitle'));
                }
                case 'paragraph': {
                    return renderParagraph(layoutItem, createId('paragraph'));
                }
                case 'text': {
                    return renderText(layoutItem, createId('text'));
                }
                case 'list': {
                    return renderList(layoutItem.list_items, createId('list'));
                }
                default:
                    return '';
            }
        });
    };
    return (
        <Container>
            <ContentLayout>
                <Image src={questionIcon} alt="questions icons" />
                <Message>{renderLayout(props.layout)}</Message>
            </ContentLayout>
            <Footer>
                <ButtonContainer>
                    <ShadowButton onClick={() => props.onNext()}>
                        {t('QUESTIONNAIRE_VIEW.INTRODUCTION.CONTINUE_BUTTON')}
                    </ShadowButton>
                </ButtonContainer>
            </Footer>
        </Container>
    );
}

export default withTranslation()(CustomLayout);
