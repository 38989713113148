import styled from 'styled-components';

export const Container = styled.div``;

export const DialogContentContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
`;

export const DialogInfoColumn = styled.div`
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-flow: column nowrap;
    // justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    padding: 0 10px;
    svg {
        padding-bottom: 20px;
    }
`;

export const DialogExampleExplanation = styled.div`
    width: 250px;
`;
