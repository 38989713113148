/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import { Container, Header, Message, Footer, ButtonContainer } from './ErrorView.style';

import neurolyticsLogo from 'assets/images/neurolytics-logo.png';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import CameraService from 'services/CameraService';

const ErrorView = (props) => {
    const { t, error } = props;

    const renderMessage = () => {
        if (error) {
            switch (props.error.code) {
                case 'BrowserNotSupported': {
                    return (
                        <Message>
                            <h1>{t('ERRORS_VIEW.BROWSER_NOT_SUPPORTED.TITLE')}</h1>
                            <h3>{t('ERRORS_VIEW.BROWSER_NOT_SUPPORTED.EXPLANATION')}</h3>
                        </Message>
                    );
                }
                case 'RequirementsNotSatisfied': {
                    return (
                        <Message>
                            <h1>{t('ERRORS_VIEW.REQUIREMENTS_NOT_SATISFIED.TITLE')}</h1>
                            <h3>{t('ERRORS_VIEW.REQUIREMENTS_NOT_SATISFIED.EXPLANATION')}</h3>
                        </Message>
                    );
                }
                case 'PermissionDenied': {
                    return (
                        <React.Fragment>
                            <Message>
                                <h1>{t('ERRORS_VIEW.PERMISSION_DENIED.TITLE')}</h1>
                                <h3>{t('ERRORS_VIEW.PERMISSION_DENIED.EXPLANATION')}</h3>
                            </Message>
                            <ButtonContainer>
                                <ShadowButton
                                    onClick={() => {
                                        CameraService.askPermission();
                                    }}>
                                    {t('ERRORS_VIEW.PERMISSION_DENIED.TRY_AGAIN_BUTON')}
                                </ShadowButton>
                            </ButtonContainer>
                        </React.Fragment>
                    );
                }
                case 'DevicesNotFound': {
                    return (
                        <Message>
                            <h1>{t('ERRORS_VIEW.DEVICES_NOT_FOUND_MAC.TITLE')}</h1>
                            {props.error.system === 'Windows 10' && (
                                <React.Fragment>
                                    <h3>{t('ERRORS_VIEW.DEVICES_NOT_FOUND_WINDOWS10.SUBTITLE')}</h3>
                                    <h3>
                                        <Trans
                                            {...t}
                                            i18nKey="ERRORS_VIEW.DEVICES_NOT_FOUND_WINDOWS10.EXPLANATION">
                                            "EXPLANATION": "If this doesn’t fix the problem, try
                                            troubleshooting
                                            <a
                                                href="https://support.microsoft.com/en-us/help/13753/windows-10-camera-does-not-work"
                                                target="_blank">
                                                here
                                            </a>
                                            .
                                        </Trans>
                                    </h3>
                                </React.Fragment>
                            )}
                            {props.error.system === 'Mac OS' && (
                                <React.Fragment>
                                    <h3>
                                        <Trans
                                            {...t}
                                            i18nKey="ERRORS_VIEW.DEVICES_NOT_FOUND_MAC.EXPLANATION">
                                            In the Mac Apple menu select ‘System Preferences’ and
                                            click on ‘Security & Privacy’ > ‘Privacy’ > ‘Camera’ and
                                            select the checkbox of the web browser to give camera
                                            access. Then click below on “Try again”, if this doesn’t
                                            fix the problem, reboot your computer or try
                                            troubleshooting
                                            <a
                                                href="https://support.apple.com/nl-nl/guide/mac-help/mchlf6d108da/mac"
                                                target="_blank">
                                                here
                                            </a>
                                            .
                                        </Trans>
                                    </h3>
                                </React.Fragment>
                            )}
                        </Message>
                    );
                }
                case 'IncorrectURL': {
                    return (
                        <Message>
                            <h1>{t('ERRORS_VIEW.INCORRECT_URL.TITLE')}</h1>
                            <h3>{t('ERRORS_VIEW.INCORRECT_URL.SUBTITLE')}</h3>
                        </Message>
                    );
                }
                case 'WrongInvitationId': {
                    return (
                        <Message>
                            <h1>{t('ERRORS_VIEW.WRONG_INVITATION_ID.TITLE')}</h1>
                            <h3>
                                <Trans {...t} i18nKey="ERRORS_VIEW.WRONG_INVITATION_ID.SUBTITLE">
                                    Please check the URL to make sure that it's the same link as the
                                    one you received in your email. Or, you can click
                                    <a href="/" target="_blank">
                                        here
                                    </a>
                                    to see the scans you've been invited to complete.
                                </Trans>
                            </h3>
                        </Message>
                    );
                }
                default: {
                    return (
                        <Message>
                            <h1>{t('ERRORS_VIEW.DEFAULT_ERROR.TITLE')}</h1>
                            <h3>
                                <Trans {...t} i18nKey="ERRORS_VIEW.DEFAULT_ERROR.EXPLANATION" />
                            </h3>
                        </Message>
                    );
                }
            }
        }
    };

    return (
        <Container>
            <Header />
            {renderMessage()}
            <Footer>
                <img src={neurolyticsLogo} alt="Neurolytics Logo" />
            </Footer>
        </Container>
    );
};

export default withTranslation()(ErrorView);
