const updateEveryQuestion = (questionnaire, questionMatchFunction) => {
    const updatedPages = questionnaire.pages.map((page) => {
        const updatedGrid = page.grid.map((question) => {
            return questionMatchFunction(question);
        });
        return { ...page, grid: updatedGrid };
    });

    return { ...questionnaire, pages: updatedPages };
};
const setUniqueIdPerPage = (questionnaire) => {
    let pageNumberCount = 0;
    const updatedPages = questionnaire.pages.map((page) => {
        pageNumberCount++;
        return { ...page, id: pageNumberCount };
    });
    return { ...questionnaire, pages: updatedPages };
};

const countAndSetQuestionIndexes = (questionnaire) => {
    let questionCount = 0;
    const updatedQuestionnaire = updateEveryQuestion(questionnaire, (question) => {
        if (question.questions) {
            const questions = question.questions.map((q) => {
                questionCount++;
                return { ...q, questionIndex: questionCount };
            });
            return { ...question, questions };
        } else {
            questionCount++;
            return {
                ...question,
                questionIndex: questionCount,
            };
        }
    });

    return updatedQuestionnaire;
};
const prepareLikertQuestionForRendering = (question) => {
    const scaleValues = [];
    const { from, to } = question.scale;
    if (from < to) {
        for (let i = from; i < to + 1; i += question.steps) {
            const skipCurrentIteration =
                question.skips && question.skips.some((number) => number === i);
            if (!skipCurrentIteration) {
                scaleValues.push(i);
            }
        }
    } else {
        for (let i = from; i > to - 1; i -= question.steps) {
            const skipCurrentIteration =
                question.skips && question.skips.some((number) => number === i);
            if (!skipCurrentIteration) {
                scaleValues.push(i);
            }
        }
    }

    return { ...question, scaleValues };
};

const updateLikertsQuestions = (questionnaire) => {
    const updatedQuestionnaire = updateEveryQuestion(questionnaire, (question) => {
        if (question.type === 'likert-scale') {
            return prepareLikertQuestionForRendering(question);
        } else {
            return question;
        }
    });

    return updatedQuestionnaire;
};

export const setQuestionnaireReadyForRendering = (questionnaire) => {
    const withPagesIds = setUniqueIdPerPage(questionnaire);
    const withIndexes = countAndSetQuestionIndexes(withPagesIds);
    const withLikertQuestions = updateLikertsQuestions(withIndexes);
    return withLikertQuestions;
};

export const buildForm = (page) => {
    const object = {};
    const previousAnswers = page.answers;
    page.grid.forEach((gridItem) => {
        if (gridItem.type !== 'statement/multiple-choice-grid') {
            const previousAnswer = previousAnswers[gridItem.id];
            if (gridItem.type === 'checkbox') {
                let checkboxOptions = {};
                gridItem.options.forEach((option) => {
                    const optionId = `${option.id}`;
                    const previousValue =
                        previousAnswer && previousAnswer[optionId]
                            ? previousAnswer[optionId]
                            : undefined;
                    checkboxOptions[optionId] = previousValue
                        ? previousValue
                        : false;
                });
                object[gridItem.id] = checkboxOptions;
            } else {
                object[gridItem.id] = previousAnswer ? previousAnswer : '';
            }
        } else {
            gridItem.questions.forEach((question) => {
                const previousValue = previousAnswers[question.id];
                object[question.id] = previousValue ? previousValue : null;
            });
        }
    });
    return object;
};
