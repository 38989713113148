import React, { useState } from 'react';
import {
    Container,
    TwoColumnsLayout,
    VideoColumn,
    Video,
    ContentColumn,
    Text,
    ConditionsContainer,
    InformedConsentContainer,
    ConditionsParagraph,
    ContentFooter,
    ButtonContainer,
    ContainerWrapper,
    Header,
} from './DataCollectionTermsConditions.style';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import RoundedCheckbox from 'components/shared/RoundedCheckbox/RoundedCheckbox';
import poweredByNeurolyticsLogo from '../../../assets/images/powered-by-neurolytics.png';
import CompanyLogo from '../../../components/shared/CompanyLogo/CompanyLogo';

const privacyLinkS3 = {
    'nl-NL':
        'https://s3-eu-west-1.amazonaws.com/neurolytics.public/docs/neurolytics-privacy-statement_nl-NL.pdf',
    'en-US':
        'https://s3-eu-west-1.amazonaws.com/neurolytics.public/docs/neurolytics-privacy-statement_en-US.pdf',
};

const termsLinkS3 = {
    'nl-NL':
        'https://s3-eu-west-1.amazonaws.com/neurolytics.public/docs/neurolytics-terms-and-conditions_nl-NL.pdf',
    'en-US':
        'https://s3-eu-west-1.amazonaws.com/neurolytics.public/docs/neurolytics-terms-and-conditions_en-US.pdf',
};
const introVideos = {
    'nl-NL':
        'https://s3.eu-west-1.amazonaws.com/neurolytics.public/data-collection/videos/data-collection-intro.mp4',
    'en-US':
        'https://s3.eu-west-1.amazonaws.com/neurolytics.public/data-collection/videos/data-collection-intro-en-US.mp4',
};

const dataCollectionConsent32 = {
    'nl-NL':
        'https://s3.eu-west-1.amazonaws.com/neurolytics.public/data-collection/files/info_and_consent_nl-NL.pdf',
    'en-US':
        'https://s3.eu-west-1.amazonaws.com/neurolytics.public/data-collection/files/info_and_consent_en-US.pdf',
};

const DataCollectionTermsAndConditions = (props) => {
    const { t, user } = props;
    const language = i18next.language;
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [informedConsentAccepted, setInformedConsentAccepted] =
        useState(false);
    const logoUrl =
        'https://s3.eu-west-1.amazonaws.com/neurolytics.public/neurolytics/images/neurolytics-logo.png';

    const privacyLink = privacyLinkS3[language]
        ? privacyLinkS3[language]
        : privacyLinkS3['nl-NL'];
    const termsLink = termsLinkS3[language]
        ? termsLinkS3[language]
        : termsLinkS3['nl-NL'];
    const introVideoUrl = introVideos[language]
        ? introVideos[language]
        : introVideos['nl-NL'];
    const dataCollectionConsent = dataCollectionConsent32[language]
        ? dataCollectionConsent32[language]
        : dataCollectionConsent32['nl-NL'];

    const onTermsCheck = (event, id) => {
        if (id === 'acceptConditions') {
            setTermsAccepted(event);
        } else if (id === 'acceptInformedConsent') {
            setInformedConsentAccepted(event);
        }
    };

    return (
        <ContainerWrapper>
            <Container>
                <Header>
                    <CompanyLogo logoUrl={logoUrl} />
                </Header>
                <TwoColumnsLayout>
                    <VideoColumn>
                        <Video
                            src={introVideoUrl}
                            controls
                            controlsList="nodownload"
                        />
                    </VideoColumn>
                    <ContentColumn>
                        <Text>
                            <h1>
                                {t('TERMS_AND_CONDITIONS.TITLE', {
                                    user_first_name: user.given_name,
                                })}
                            </h1>
                            <h2>
                                {t(
                                    'TERMS_AND_CONDITIONS.DATA_COLLECTION.SUBTITLE',
                                )}
                            </h2>
                            <p>
                                {t(
                                    'TERMS_AND_CONDITIONS.DATA_COLLECTION.EXPLANATION_TEXT_1',
                                )}
                            </p>
                            <p>
                                {t(
                                    'TERMS_AND_CONDITIONS.DATA_COLLECTION.EXPLANATION_TEXT_2',
                                )}
                            </p>
                            <p>
                                {t(
                                    'TERMS_AND_CONDITIONS.DATA_COLLECTION.EXPLANATION_TEXT_3',
                                )}
                            </p>
                        </Text>
                        <ConditionsContainer>
                            <RoundedCheckbox
                                id="acceptConditions"
                                onChange={(event) =>
                                    onTermsCheck(event, 'acceptConditions')
                                }
                            />
                            <ConditionsParagraph>
                                <Trans i18nKey="TERMS_AND_CONDITIONS.TERMS_AGREEMENT_TEXT">
                                    I have read and agree with the
                                    <a
                                        href={privacyLink}
                                        target="_blank"
                                        rel="noreferrer">
                                        Privacy Statement
                                    </a>
                                    and
                                    <a
                                        href={termsLink}
                                        target="_blank"
                                        rel="noreferrer">
                                        Terms and Conditions
                                    </a>
                                </Trans>
                            </ConditionsParagraph>
                        </ConditionsContainer>
                        <InformedConsentContainer>
                            <RoundedCheckbox
                                id="acceptInformedConsent"
                                onChange={(event) =>
                                    onTermsCheck(event, 'acceptInformedConsent')
                                }
                            />
                            <ConditionsParagraph>
                                <Trans i18nKey="TERMS_AND_CONDITIONS.DATA_COLLECTION.ADDITIONAL_TERMS_AGREEMENT_TEXT">
                                    I have read and agree with the
                                    <a
                                        href={dataCollectionConsent}
                                        target="_blank"
                                        rel="noreferrer">
                                        informed consent document
                                    </a>
                                </Trans>
                            </ConditionsParagraph>
                        </InformedConsentContainer>
                        <ContentFooter>
                            <ButtonContainer>
                                <ShadowButton
                                    disabled={
                                        !termsAccepted ||
                                        !informedConsentAccepted
                                    }
                                    onClick={() => props.onComplete()}>
                                    {t('TERMS_AND_CONDITIONS.CONTINUE_BUTTON')}
                                </ShadowButton>
                            </ButtonContainer>
                        </ContentFooter>
                    </ContentColumn>
                </TwoColumnsLayout>
                <img
                    className="power-logo"
                    src={poweredByNeurolyticsLogo}
                    alt="Neurolytics Logo"
                />
            </Container>
        </ContainerWrapper>
    );
};

export default withTranslation()(DataCollectionTermsAndConditions);
