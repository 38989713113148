import React from 'react';

import { ProgressSpinnerContainer } from './ProgressSpinner.style';

import CircularProgress from '@material-ui/core/CircularProgress';

function ProgressSpinner() {
    return (
        <ProgressSpinnerContainer>
            <CircularProgress style={{ color: '#435970' }} size={50} />
        </ProgressSpinnerContainer>
    );
}

export default ProgressSpinner;
