import styled from 'styled-components';
import { breakpoints } from '../../../App.style';

// import provisionalThumbnail from 'assets/images/thumbnail.png';
// background: url(${provisionalThumbnail}) no-repeat center center fixed;

export const Container = styled.div`
    position: relative;
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    @media only screen and (max-height: ${(props) =>
            props.showCameraSetup ? '800px' : '500px'}) {
        height: fit-content;
    }
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4vw;
    }
`;

export const Paragraph = styled.p`
    text-align: left;
    margin: 10px 0;
`;

export const CameraSetupTitle = styled.h2`
    width: 100%;
    font-weight: bold;
    text-align: left;
    margin: 10px 0;
`;

export const CameraSetupViewContent = styled.div`
    width: 70vw;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.textColor};
`;

export const ButtonContainer = styled.div`
    width: 250px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    align-self: center;
`;

export const InstructionImagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
`;

export const InstructionImagesColumn = styled.div`
    margin: 25px 20px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
    gap: 8px;
`;

export const InstructionImagesTitle = styled.div`
    width: 150px;
    font-weight: bold;
    text-align: center;
`;

export const IntroductionImage = styled.img`
    width: 100%;
    max-width: 175px;
    height: auto;
`;

export const AllowAccessMoreInformation = styled.div`
    width: 275px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    font-style: italic;
    font-weight: normal;
    margin: 10px 0;
`;

export const InfoIconContainer = styled.div`
    padding: 0;
    margin: 15px 0 0 0;
    &:hover {
        svg {
            color: #00a5ae;
        }
    }
`;
