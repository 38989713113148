import React from 'react';
import { SuccessAnimationWrapper } from './style';

const SuccessAnimation = () => {
    const fireworks = document.getElementById('fireworks');

    setTimeout(function () {
        if (fireworks) fireworks.style.display = 'none';
    }, 30000);

    return (
        <SuccessAnimationWrapper>
            <div id="fireworks" className="fireworks"></div>
        </SuccessAnimationWrapper>
    );
};
export default SuccessAnimation;
