import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Container,
    TwoColumnsLayout,
    ImageColumn,
    ContentColumn,
    ModuleImage,
    Text,
    List,
    ListItem,
    ContentFooter,
    ButtonContainer,
} from './DataCollectionIntro.style';
import questionnaireImage from 'assets/images/questionnaire_module.png';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';

const DataCollectionIntro = (props) => {
    const { onNext, questionnaires } = props;
    const { t } = useTranslation();

    return (
        <Container>
            <TwoColumnsLayout>
                <ImageColumn>
                    <ModuleImage src={questionnaireImage} />
                </ImageColumn>
                <ContentColumn>
                    <Text>
                        <h1>
                            {t(
                                'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.TITLE',
                            )}
                        </h1>
                        <p>
                            {t(
                                'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.MESSAGE_1', {questionnaires_count: questionnaires.length}
                            )}
                        </p>
                        <List>
                            {questionnaires.includes('self-evaluation') && (
                                <ListItem>
                                    {t(
                                        'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.QUESTIONNAIRE_TITLE_1',
                                    )}
                                </ListItem>
                            )}

                            {questionnaires.includes('company-video') && (
                                <ListItem>
                                    {t(
                                        'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.QUESTIONNAIRE_TITLE_2',
                                    )}
                                </ListItem>
                            )}

                            {questionnaires.includes('general-interest') && (
                                <ListItem>
                                    {t(
                                        'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.QUESTIONNAIRE_TITLE_3',
                                    )}
                                </ListItem>
                            )}

                            {questionnaires.includes(
                                'feelings-and-emotions',
                            ) && (
                                <ListItem>
                                    {t(
                                        'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.QUESTIONNAIRE_TITLE_4',
                                    )}
                                </ListItem>
                            )}

                            {questionnaires.includes('strategies') && (
                                <ListItem>
                                    {t(
                                        'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.QUESTIONNAIRE_TITLE_5',
                                    )}
                                </ListItem>
                            )}

                            {questionnaires.includes('demographic-info') && (
                                <ListItem>
                                    {t(
                                        'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.QUESTIONNAIRE_TITLE_6',
                                    )}
                                </ListItem>
                            )}
                        </List>
                        <p>
                            {t(
                                'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.MESSAGE_2',
                            )}
                        </p>
                        <p>
                            {t(
                                'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.MESSAGE_3',
                            )}
                        </p>
                    </Text>

                    <ContentFooter>
                        <ButtonContainer>
                            <ShadowButton onClick={() => onNext()}>
                                {t(
                                    'DATA_COLLECTION_QUESTIONNAIRE_MODULE.INTRODUCTION.START_TASK_BUTTON',
                                )}
                            </ShadowButton>
                        </ButtonContainer>
                    </ContentFooter>
                </ContentColumn>
            </TwoColumnsLayout>
        </Container>
    );
};

export default DataCollectionIntro;
