import {
    getBrowserData,
    getDeviceData,
    getViewportData,
    getWindowData,
} from 'utils/device-data';
import { store } from '../store';
import { uploadsActions } from 'store/uploads/uploads.actions';

import Logger from 'services/Logger';
import cloneDeep from 'lodash/cloneDeep';

class SessionAnalytics {
    constructor() {
        this.sessionId = Math.random().toString(36).substring(7);

        this.session = {
            sessionId: this.sessionId,
            analyticsVersion: '1.0.4',
            userAgent: navigator.userAgent,
            browser: getBrowserData(),
            internetSpeed: {},
            viewport: getViewportData(),
            window: getWindowData(),
            device: getDeviceData(),
            language: navigator.language,
            visitAt: new Date(Date.now()).toISOString(),
            navigation: [],
            events: {
                clicks: [],
                window: [],
                videos: {
                    cultureFitVideo: [],
                },
                timer: [],
            },
            // megabytesUploaded: 0,
        };

        this.clickListener = this.clickListener.bind(this);
        document.onclick = this.clickListener;
    }

    onLogin(user) {
        this.session = Object.assign({}, this.session, {
            user: { id: user.id, email: user.email },
            // loggedInAt: new Date(Date.now()).toISOString(),
        });
        this.userId = user.id;
    }

    onScanStart(invitationId) {
        this.session = Object.assign({}, this.session, {
            loggedInAt: new Date(Date.now()).toISOString(),
        });
        this.invitationId = invitationId;
    }

    onTimerStart(moduleId) {
        const timerEvent = {
            id: moduleId,
            startedAt: new Date(Date.now()).toISOString(),
            page: 1,
        };
        this.session.events.timer = [...this.session.events.timer, timerEvent];
        this.submitData();
    }

    onTimerPageFinished() {
        const timerEvents = this.session.events.timer;
        const previousTimerEvent = timerEvents[timerEvents.length - 1];
        const fieldsToAdd = {
            finishedAt: new Date(Date.now()).toISOString(),
            totalTime: Date.now() - Date.parse(previousTimerEvent.startedAt),
        };
        const updatedEvent = Object.assign({}, previousTimerEvent, fieldsToAdd);
        this.session.events.timer = this.session.events.timer.map((module) => {
            if (previousTimerEvent.page === module.page) {
                return updatedEvent;
            } else return module;
        });

        const newPage = {
            id: previousTimerEvent.id,
            startedAt: new Date(Date.now()).toISOString(),
            page: previousTimerEvent.page + 1,
        };
        this.session.events.timer.push(newPage);

        this.submitData();
    }

    onTimerModuleEnd() {
        const timerEvents = this.session.events.timer;
        const previousTimerEvent = timerEvents[timerEvents.length - 1];
        const fieldsToAdd = {
            finishedAt: new Date(Date.now()).toISOString(),
            totalTime: Date.now() - Date.parse(previousTimerEvent.startedAt),
        };
        const updatedEvent = Object.assign({}, previousTimerEvent, fieldsToAdd);
        this.session.events.timer = this.session.events.timer.map((module) => {
            if (previousTimerEvent.page === module.page) {
                return updatedEvent;
            } else return module;
        });

        this.submitData();
    }

    onModuleNavigation(nextModule, previousModule) {
        const previousModuleId = previousModule && previousModule.id;

        if (previousModuleId) {
            const previous = this.session.navigation.find(
                (module) => module.id === previousModuleId,
            );

            const fieldsToAdd = {
                finishedAt: new Date(Date.now()).toISOString(),
                totalTime: Date.now() - Date.parse(previous.startedAt),
            };
            const previousUpdated = Object.assign({}, previous, fieldsToAdd);

            Logger.logInfo('Session - Module finished', previousUpdated);

            const navigation = this.session.navigation.map((module) => {
                if (module.id === previousModuleId) {
                    return previousUpdated;
                } else return module;
            });

            this.session = Object.assign({}, this.session, { navigation });
        }

        this.session.navigation.push({
            id: nextModule.id ? nextModule.id : undefined,
            type: nextModule.type ? nextModule.type : undefined,
            startedAt: new Date(Date.now()).toISOString(),
        });
        if (nextModule.type === 'assessment-ending') {
            this.session = Object.assign({}, this.session, {
                finishedAt: new Date(Date.now()).toISOString(),
                totalTime: Date.now() - Date.parse(this.session.loggedInAt),
            });
        }
        if (
            nextModule.type === 'assessment-questionnaire' ||
            nextModule.type === 'culture-fit-video' ||
            nextModule.type === 'interview-module' ||
            nextModule.type === 'assessment-ending'
        ) {
            this.submitData();
        }
    }

    clickListener(event) {
        const element = event.target;
        const label =
            element && element.attributes && element.attributes['aria-label'];
        const screens = this.session.navigation;
        const screenId =
            screens.length > 0 ? screens[screens.length - 1].id : null;
        const clickData = {
            screenId: screenId ? screenId : 'login',
            text: element.textContent,
            elementTagName: element.tagName,
            id: element.id && element.id !== '' ? element.id : undefined,
            label: label && label.value ? label.value : undefined,
            timeStamp: Date.now(),
            timeString: new Date(Date.now()).toISOString(),
            x: event.clientX,
            y: event.clientY,
        };

        this.session = {
            ...this.session,
            events: {
                ...this.session.events,
                clicks: [...this.session.events.clicks, clickData],
            },
        };
    }

    onInternetSpeedTestEvent(data) {
        this.session = Object.assign({}, this.session, {
            internetSpeed: data,
        });
        Logger.logInfo('Session - Internet speed measured', {
            internetSpeed: data,
        });
    }

    onWindowEvent(event) {
        this.session = {
            ...this.session,
            events: {
                ...this.session.events,
                window: [...this.session.events.window, event],
            },
        };
        //this.submitData();
    }

    onCultureFitVideoEvent(event) {
        const videoEvents = this.session.events.cultureFitVideo;
        videoEvents.push(event);
        this.session = Object.assign({}, this.session, {
            events: { ...this.session.events, cultureFitVideo: videoEvents },
        });
        // this.submitData();
    }

    onVideoEvent(videoId, event) {
        const previousEvents = this.session.events.videos[videoId];
        const videoEvents = previousEvents ? previousEvents : [];
        videoEvents.push(event);
        this.session = Object.assign({}, this.session, {
            events: {
                ...this.session.events,
                videos: {
                    ...this.session.events.videos,
                    [videoId]: videoEvents,
                },
            },
        });
        //this.submitData();
    }

    onError(error) {
        const errors = this.session.errors;
        error.push(error);
        this.session = Object.assign({}, this.session, { errors });

        this.submitData();
    }

    submitData() {
        const sessionFile = cloneDeep(this.session);
        const params = {
            invitationId: this.invitationId,
            file: sessionFile,
            fileType: 'session',
            fileId: `session-${this.sessionId}`,
        };
        store.dispatch(uploadsActions.upload(params));
    }
}

const instance = new SessionAnalytics();

export default instance;
