import { RecordRTCPromisesHandler } from 'recordrtc';
// import Logger from 'services/Logger';
//import * as faceapi from 'face-api.js';
// import { generateRandomString } from '@utils';

class CameraService {
    constructor() {
        if (this.constructor.instance) {
            return this.constructor.instance;
        }
        this.constructor.instance = this;

        this.videoStream = null;
        this.blob = null;
        this.recorder = null;
        this.constrains = {
            video: {
                // width: { min: 640, max: 960 },
                // height:{ min: 480, max: 720 },
                width: 800,
                height: 600,
                frameRate: {
                    min: 15,
                    ideal: 30,
                    max: 40,
                },
            },
            audio: true,
        };
        this.eventProperties = { bubbles: true, composed: true };
    }

    // getBestMimeTypeAndVideoFormat() {
    //     let options = { format: 'video/mp4', mimeType: 'video/mp4;codecs="mp4v.20.8"' };
    //     try {
    //         if (!MediaRecorder.isTypeSupported(options.mimeType)) {
    //             options = { format: 'video/webm', mimeType: 'video/webm;codecs=vp9' };
    //             if (!MediaRecorder.isTypeSupported(options.mimeType)) {
    //                 options = { format: 'video/webm', mimeType: 'video/webm;' };
    //                 if (!MediaRecorder.isTypeSupported(options.mimeType)) {
    //                     options = { format: 'video/mp4;', mimeType: 'video/mp4' };
    //                     if (!MediaRecorder.isTypeSupported(options.mimeType)) {
    //                         options = { mimeType: '' };
    //                     }
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         Logger.logError(`CameraService`, error);
    //     }
    //
    //     return options;
    // }

    // onGetUserMediaError(error) {
    //     const isWindows10 = navigator.userAgent.indexOf('Windows 10') !== -1;
    //     const isMac = navigator.userAgent.indexOf('Mac') !== -1;
    //     const system = isWindows10 ? 'Windows 10' : isMac ? 'Mac OS' : 'Unknown';
    //     if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
    //         this.dispatchEvent('onError', { code: 'DevicesNotFound', system: system });
    //     } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
    //         const isFirefox = typeof InstallTrigger !== 'undefined';
    //         this.dispatchEvent('onError', {
    //             code: 'DevicesNotFound',
    //             system: system,
    //             isFirefox: isFirefox,
    //         });
    //     } else if (
    //         error.name === 'OverconstrainedError' ||
    //         error.name === 'ConstraintNotSatisfiedError'
    //     ) {
    //         this.dispatchEvent('onError', { code: 'RequirementsNotSatisfied' });
    //     } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
    //         this.dispatchEvent('onError', { code: 'PermissionDenied' });
    //     } else {
    //         this.dispatchEvent('onError', { code: 'UnknownError' });
    //     }
    //     Logger.logError(`CameraService`, error);
    // }

    dispatchEvent(type, data = {}) {
        const event = new CustomEvent(
            'camera-events',
            Object.assign({}, this.eventProperties, {
                detail: { type: type, data: data },
            }),
        );
        document.dispatchEvent(event);
    }

    /**
     * Check if browser supports getUserMedia
     *
     * @returns {boolean}
     */
    isGetUserMediaSupported() {
        return !!(
            navigator.mediaDevices && navigator.mediaDevices.getUserMedia
        );
    }

    /**
     * Handle user media capture
     *
     * @since 0.2.0
     */
    async startVideoStream() {
        this.videoStream = await navigator.mediaDevices.getUserMedia(
            this.constrains,
        );
    }

    /**
     * Stops user media real-time capturing
     *
     * @since 0.2.0
     */
    async stopVideoStream() {
        await this.videoStream.getTracks().forEach((track) => track.stop());
        this.videoStream = null;
    }

    getVideoStream() {
        return this.videoStream;
    }

    /**
     * Handle user media recording
     *
     * @since 0.2.0
     */
    async startRecording() {
        this.dispatchEvent('onRecording');
        this.startRecordingAt = Date.now();
        this.recorder = new RecordRTCPromisesHandler(this.videoStream, {
            type: 'video',
            mimeType: 'video/webm',
            // audio: false,
            disableLogs: true,
        });
        await this.recorder.startRecording();
    }

    /**
     * Stops user media recording
     *
     * @since 0.2.0
     */
    async stopRecording() {
        await this.recorder.stopRecording();
    }

    getBlobDuration() {
        const blobDuration = Date.now() - this.startRecordingAt;
        this.startRecordingAt = null;
        return blobDuration;
    }

    async resetCameraRecorder() {
        this.recorder.blobDuration = null;
        await this.recorder.reset();
    }

    async getRecorderState() {
        return await this.recorder.getState();
    }

    async getRecorderBlob() {
        return await this.recorder.getBlob();
    }

    getBlob() {
        return this.blob;
    }

    setBlob(blob) {
        this.blob = blob;
    }

    async loadBlobFromRecorder() {
        const blob = await this.getRecorderBlob();
        this.setBlob(blob);
        return blob;
    }

    // getFPS(video) {
    //     const frames =
    //         video.webkitDecodedFrameCount ||
    //         video.mozPaintedFrames ||
    //         video.renderedFramesPerSecond;
    //     if (!frames) {
    //         console.log('Get video FPS not supported');
    //         return;
    //     } else {
    //         const currentTime = new Date().getTime();
    //         const deltaTime = (currentTime - this.startTime) / 1000;
    //         const totalTime = (currentTime - this.initialTime) / 1000;
    //         this.startTime = currentTime;
    //
    //         const decodedAverageFrames = (frames - this.decodedFrames) / deltaTime;
    //         const averageFramesPerSecond = frames / totalTime;
    //         this.decodedFrames = frames;
    //
    //         return {
    //             current: decodedAverageFrames.toFixed(2),
    //             average: averageFramesPerSecond,
    //         };
    //     }
    // }

    // validateImageBrightness(videoRef) {
    //     // return new Promise(async (resolve) => {
    //     //     const { canvas, width, height } = this.takePicture(videoRef);
    //     //     const { faceCanvas, faceBox } = await this.getFaceCanvas(videoRef);
    //     //     const backgroundBrightness = getAverageBrightness(canvas, width, height);
    //     //     if (!faceCanvas || !faceBox) {
    //     //         resolve({ backgroundBrightness });
    //     //         return;
    //     //     }
    //     //     const faceBrightness = getAverageBrightness(faceCanvas, faceBox.width, faceBox.height);
    //     //     resolve({ backgroundBrightness, faceBrightness });
    //     // })
    // }

    // takePicture(videoRef) {
    //     const canvas = document.createElement('canvas');
    //     if (canvas && videoRef) {
    //         const optimalWidth =
    //             videoRef.videoWidth > 800 ? videoRef.videoWidth / 2 : videoRef.videoWidth;
    //         const optimalHeight =
    //             videoRef.videoHeight > 600 ? videoRef.videoHeight / 2 : videoRef.videoHeight;
    //
    //         canvas.setAttribute('id', `canvas-${generateRandomString(5)}`);
    //         canvas.setAttribute('width', optimalWidth);
    //         canvas.setAttribute('height', optimalHeight);
    //
    //         canvas.getContext('2d').drawImage(videoRef, 0, 0, optimalWidth, optimalHeight);
    //         const image = canvas.toDataURL('image/png');
    //         return { canvas, image, width: optimalWidth, height: optimalHeight };
    //     } else return null;
    // }

    // getFaceCanvas(videoRef) {
    //     // return new Promise(async (resolve) => {
    //     //     const { canvas } = this.takePicture(videoRef);
    //     //     try {
    //     //         const faceDetection = await faceapi.detectSingleFace(canvas, new faceapi.TinyFaceDetectorOptions())
    //     //         if (faceDetection && faceDetection.box) {
    //     //             const faceCanvas = document.createElement('canvas');
    //     //             const faceBox = faceDetection.box;
    //     //             faceCanvas.setAttribute('id', `canvas-${generateRandomString(5)}`);
    //     //             faceCanvas.setAttribute('width', faceDetection.box.width);
    //     //             faceCanvas.setAttribute('height', faceDetection.box.height);
    //     //             faceCanvas.getContext('2d').drawImage(canvas,
    //     //                 faceDetection.box.x, faceDetection.box.y,
    //     //                 faceDetection.imageWidth, faceDetection.imageHeight,
    //     //                 0, 0,
    //     //                 faceDetection.imageWidth, faceDetection.imageHeight
    //     //             );
    //     //             const faceImage = faceCanvas.toDataURL("image/png");
    //     //             resolve({ faceCanvas, faceImage, faceBox });
    //     //         }
    //     //         else {
    //     //             resolve({})
    //     //         }
    //     //     }
    //     //     catch (error) {
    //     //         resolve({})
    //     //     }
    //     // });
    // }

    // isFaceCentered(videoRef) {
    //     return new Promise(async (resolve) => {
    //         const { canvas } = this.takePicture(videoRef);
    //         const { faceCanvas, faceBox } = await this.getFaceCanvas(videoRef);
    //
    //         if (faceCanvas && faceBox && canvas) {
    //             const isCentered =
    //                 faceBox.x > canvas.width / 4 && faceBox.x < (canvas.width / 6) * 3;
    //             resolve(isCentered);
    //         } else {
    //             resolve(false);
    //         }
    //     });
    // }
}

export default new CameraService();
