import { put, select, call } from 'redux-saga/effects';
import { uploadsActionTypes } from './uploads.actions';
import { selectConfigState } from '../config/config.selectors';
import Logger from 'services/Logger';
import NeurolyticsAPI  from 'services/NeurolyticsAPI';

function* submissionsDirectory(userId, invitationId) {
    const configState = yield select(selectConfigState);
    return `${configState.scans[invitationId].config.bucket_directory}/${configState.scans[invitationId].targetGroupId}/${userId}/assessment-submissions`;
}

function* videosDirectory(userId, invitationId) {
    const configState = yield select(selectConfigState);
    return `${configState.scans[invitationId].config.bucket_directory}/${configState.scans[invitationId].targetGroupId}/${userId}/assessment-videos`;
}

function* sessionsDirectory(userId, invitationId) {
    const configState = yield select(selectConfigState);
    return `${configState.scans[invitationId].config.bucket_directory}/${configState.scans[invitationId].targetGroupId}/${userId}/sessions`;
}

function* setUploadParams(data, userId) {
    switch (data.fileType) {
        case 'video': {
            const videoDir = yield videosDirectory(userId, data.invitationId);
            const fileName = `${data.fileId}.${data.fileFormat}`;
            const fileKey = `${videoDir}/${fileName}`;
            return {
                ...data,
                fileName,
                fileKey,
                contentType: `video/${data.fileFormat}`,
            };
        }
        case 'submission': {
            const fileName = `${data.fileId}.answers.json`;
            const submissionsDir = yield submissionsDirectory(userId, data.invitationId);
            const fileKey = `${submissionsDir}/${fileName}`;
            return {
                ...data,
                fileName,
                fileKey,
                contentType: 'application/json',
            };
        }
        case 'session': {
            const fileName = `${data.fileId}.json`;
            const sessionsDir = yield sessionsDirectory(userId, data.invitationId);
            const fileKey = `${sessionsDir}/${fileName}`;
            return {
                ...data,
                fileName,
                fileKey,
                contentType: 'application/json',
            };
        }
        default:
            return null;
    }
}

export function* upload(action) {
    const authState = yield select((state) => state.auth);
    const userId = authState.user.id;

    if (action.payload) {
        const uploadParams = yield setUploadParams(action.payload, userId);

        // Add video files to the queue
        if (uploadParams.fileType === 'video') {
            Logger.logInfo('UploadsEffects - Adding file to queue: ' + uploadParams.fileId);

            yield put({
                type: uploadsActionTypes.PUT_FILE_ON_QUEUE,
                payload: { ...uploadParams, uniqueId: uploadParams.fileName },
            });
        }
        // Upload non-video files right away
        else {
            Logger.logInfo('UploadsEffects - Uploading file ' + uploadParams.fileName);
            try {
                yield uploadWithPresignedUrlFlow(uploadParams);
            } catch (error) {
                Logger.logWarning(
                    `UploadsEffects - Failed to immediate upload non-video file ` +
                        uploadParams.fileName,
                    error
                );
            }
        }

        // Upload the next file on the queue
        yield uploadNextInQueue();
    }
}

function mapUploadedFilesForLogs(uploadsState) {
    const state = { ...uploadsState };
    const mappedState = {};
    ['toUpload', 'uploading', 'uploaded', 'failed'].forEach((key) => {
        mappedState[key] = state[key].map((object) => ({
            fileId: object.fileId,
            fileType: object.fileType,
            uniqueId: object.uniqueId,
        }));
    });
    return mappedState;
}

export function* uploadNextInQueue() {
    const uploadsState = yield select((state) => state.uploads);

    Logger.logInfo('UploadsEffects - uploadNextInQueue', {
        uploadsState: mapUploadedFilesForLogs(uploadsState),
    });

    if (
        uploadsState.uploading.length < 2 &&
        (uploadsState.toUpload.length > 0 || uploadsState.failed.length > 0)
    ) {
        const nextInQueue =
            uploadsState.failed.length > 0 ? uploadsState.failed[0] : uploadsState.toUpload[0];

        if (nextInQueue) {
            try {
                yield uploadWithPresignedUrlFlow(nextInQueue);
            } catch (error) {
                Logger.logError(
                    `UploadsEffects - Failed to upload file ${nextInQueue.fileName}`,
                    error
                );
            }
        }
    }
}

function* uploadWithPresignedUrlFlow(params) {
    const objectForLogging = { ...params, file: {} };
    let response;

    yield put({
        type: uploadsActionTypes.UPLOADING,
        payload: { ...params, uniqueId: params.fileName },
    });

    try {
        response = yield call([NeurolyticsAPI,'uploadFile'], params);
    } catch (error) {
        Logger.logWarning(`UploadsEffects - File was not uploaded`, {
            fileId: params.fileId,
            params: objectForLogging,
        });

        // retry
        yield put({
            type: uploadsActionTypes.UPLOAD_FAILURE,
            payload: { ...params, uniqueId: params.fileName },
        });
        throw error;
    }

    if (response) {
        Logger.logInfo(`UploadsEffects - File uploaded`, {
            fileId: params.fileId,
            params: objectForLogging,
        });
        yield put({
            type: uploadsActionTypes.UPLOAD_SUCCESS,
            payload: { ...params, uniqueId: params.fileName },
        });
        return response;
    } else {
        Logger.logWarning(`UploadsEffects - File was not uploaded`, {
            fileId: params.fileId,
            params: objectForLogging,
        });

        // retry
        yield put({
            type: uploadsActionTypes.UPLOAD_FAILURE,
            payload: { ...params, uniqueId: params.fileName },
        });
        throw new Error(`File was not uploaded: ${params.fileName}`);
    }
}
