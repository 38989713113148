import { authActionTypes } from './auth.actions';

export const authInitialState = {
    user: null,
    targetGroupId: null,
    loading: false,
    error: null,
    registeredUser: null,
    isRegistering: false
};

const authReducer = (state = authInitialState, action) => {
    switch (action.type) {
        case authActionTypes.INIT_AUTH0: {
            return {
                ...state,
                loading: true,
            };
        }

        case authActionTypes.INIT_AUTH0_SUCCESS: {
            return {
                ...state,
                error: null,
                loading: false,
            };
        }

        case authActionTypes.INIT_AUTH0_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                loading: false,
            };
        }

        case authActionTypes.INIT_LOGIN_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                error,
            };
        }

        case authActionTypes.STORE_USER_SUCCESS: {
            const { user } = action.payload;
            return {
                ...state,
                user,
                error: null,
                loading: false,
            };
        }

        case authActionTypes.STORE_USER: {
            return {
                ...state,
                loading: true,
            };
        }

        case authActionTypes.STORE_USER_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                loading: false,
            };
        }

        case authActionTypes.REGISTER_USER: {
            return {
                ...state,
                isRegistering: true,
            };
        }

        case authActionTypes.REGISTER_USER_SUCCESS: {
            const { registeredUser } = action.payload;
            return {
                ...state,
                registeredUser,
                error: null,
                isRegistering: false,
            };
        }

        case authActionTypes.REGISTER_USER_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                isRegistering: false,
            };
        }

        default:
            return state;
    }
};

export default authReducer;
