import { useDispatch, useSelector } from 'react-redux';
import { auth0Client } from 'Auth';
import { storeUser } from '../store/auth/auth.actions';
import { selectAuthState } from '../store/auth/auth.selectors';

const useAuth = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(selectAuthState);

    if (auth0Client) {
        auth0Client.isAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated && !user) {
                dispatch(storeUser());
            }
        });
    }

    const onRedirectCallback = async () => {
        try {
            if (auth0Client) {
                const { appState } = await auth0Client.handleRedirectCallback();
                const isAuthenticated: boolean =
                    await auth0Client.isAuthenticated();
                if (isAuthenticated && !user) {
                    dispatch(storeUser());
                }
                return appState;
            }
        } catch (error) {
            console.log('Authentication Error:', error);
        }
    };

    return { onRedirectCallback };
};

export default useAuth;
