import React from 'react';
import styles from './ProgressSpinner.module.css';

import CircularProgress from '@material-ui/core/CircularProgress';

function ProgressSpinner() {
    return (
        <div className={styles.dashboard_circular_progress_spinner}>
            <CircularProgress style={{ color: '#435970' }} />
        </div>
    );
}

export default ProgressSpinner;
