export const preloaderActionTypes = {
    PRELOAD_VIDEO: 'PRELOAD_VIDEO',
    PRELOAD_VIDEO_FETCHING: 'PRELOAD_VIDEO/FETCHING',
    PRELOAD_VIDEO_IN_QUEUE: 'PRELOAD_VIDEO/IN_QUEUE',
    PRELOAD_VIDEO_DONE: 'PRELOAD_VIDEO_DONE',
    PRELOAD_VIDEO_FAILURE: 'PRELOAD_VIDEO_FAILURE',
    DISCARD_RESOUCE: 'DISCARD_RESOUCE',
    DISCARD_RESOUCE_DONE: 'DISCARD_RESOUCE_DONE',
};

export const preloaderActions = {
    preloadVideo: (videoId, videoUrl) => ({ type: preloaderActionTypes.PRELOAD_VIDEO, payload: {videoId, videoUrl} }),
    discardResource: (resourceType, resourceId) => ({ type: preloaderActionTypes.DISCARD_RESOUCE, payload: {resourceType, resourceId} }),
};
