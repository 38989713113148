import React from 'react';
import Logger from 'services/Logger';

class ErrorBoundary extends React.Component {
    static getDerivedStateFromError(error) {
        Logger.logError('ErrorBoundary', error);
    }
    componentDidCatch(error, info) {
        Logger.catchComponentError(error, info);
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;
