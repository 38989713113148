import React from 'react';
import { Header, Footer } from './ModuleIntroductionView.style';
import { AppLayout } from 'styles/shared.style';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';

function ModuleIntroductionView(props) {
    return (
        <AppLayout>
            <Header>
                {props.children}
            </Header>

            <Footer>
                <ShadowButton onClick={() => props.onNext()}>{props.buttonPlaceholder}</ShadowButton>
            </Footer>
        </AppLayout>
    );
}

export default ModuleIntroductionView;
