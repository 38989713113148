import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
    palette: {
        text: {
            secondary: '#435970',
        },
    },
    overrides: {
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 900,
                width: 900,
            },
        },
        MuiDialogTitle: {
            root: {
                background: '#435970',
                padding: 20,
            },
        },
        MuiDialogContent: {
            root: {
                padding: 20,
            },
        },
        MuiTypography: {
            h6: {
                color: '#fff',
                fontWeight: 700,
            },
        },
        MuiDialogActions: {
            root: {
                margin: 0,
                paddingTop: 25,
                paddingRight: 0,
                paddingBottom: 0,
                paddingLeft: 0,
            },
        },
    },

    shape: {
        borderRadius: 15,
    },
});
