import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCameraState } from 'store/camera/camera.selectors';
import { selectPreloaderVideos } from 'store/preloader/preloader.selectors';
import { cameraActions } from 'store/camera/camera.actions';
import { useTranslation } from 'react-i18next';
import {
    Question,
    RecorderDescription,
    WatchVideoAgainContainer,
    WatchVideoAgainMessage,
    RecorderContainer,
    RecorderFooter,
    ButtonContainer,
    VideoLoadingPlaceholder,
} from './InterviewController.styled';
import { AppLayout } from 'styles/shared.style';

import InterviewIntroduction from '../InterviewIntroduction/InterviewIntroduction';
import VideoComponent from 'components/shared/VideoComponent/VideoComponent';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import InterviewRecorder from '../InterviewRecorder/InterviewRecorder';
import ModuleIntroductionView from 'components/shared/ModuleIntroductionView/ModuleIntroductionView';

import { ReactComponent as BackButton } from 'assets/icons/video-controls/back-button.svg';
import { getInterviewVideoId } from 'utils/module-identificators';
import { RotateSpinLoader } from 'react-css-loaders';
import CameraService from '../../../services/CameraService';

const videoModuleId = (currentQuestion) =>
    getInterviewVideoId(currentQuestion.questionId);
const buildQuestion = (question, questionIndex) => ({
    ...question,
    submitted: false,
    playingVideo: !!question.videos,
    shouldDisplayQuestionSubmitted: questionIndex !== 0,
});

const InterviewController = (props) => {
    const { invitationId, module, onComplete } = props;

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [introductionAccepted, setIntroductionAccepted] = useState(false);
    const lastQuestion = module.questions[module.questions.length - 1];
    const [currentQuestion, setCurrentQuestion] = useState(
        buildQuestion(module.questions[0], 0),
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cameraState = useSelector(selectCameraState);
    const preloader = useSelector(selectPreloaderVideos);
    const blob = CameraService.getBlob();

    // Start capturing user media for written questions
    useEffect(() => {
        if (
            introductionAccepted &&
            !currentQuestion.shouldDisplayQuestionSubmitted &&
            !currentQuestion.videos &&
            !blob
        ) {
            dispatch(cameraActions.captureUserMedia());
        }
    }, [currentQuestion, introductionAccepted]); // eslint-disable-line react-hooks/exhaustive-deps -- fallow only the changes of the current question to start capturing

    const onRecordingAnswer = () => {
        dispatch(
            cameraActions.recordUserMedia(
                invitationId,
                `interview-${currentQuestionIndex + 1}`,
                false,
            ),
        );
    };

    const onStopRecordingAnswer = () => {
        dispatch(cameraActions.stopRecordingUserMedia(invitationId));
    };

    const onQuestionVideoComplete = () => {
        dispatch(cameraActions.captureUserMedia());
        setCurrentQuestion({
            ...currentQuestion,
            playingVideo: false,
        });
    };

    const watchVideoAgain = () => {
        setCurrentQuestion({
            ...currentQuestion,
            playingVideo: true,
        });
    };

    const onSubmitQuestion = () => {
        dispatch(cameraActions.dispatchBlobUpload(invitationId));
        updateQuestion();
    };

    const updateQuestion = () => {
        if (currentQuestionIndex + 1 === module.questions.length) {
            setCurrentQuestion(null);
            setCurrentQuestionIndex(currentQuestionIndex + 1);

            onComplete();
        } else {
            const nextQuestionIndex = currentQuestionIndex + 1;
            const nextQuestion = buildQuestion(
                module.questions[nextQuestionIndex],
                nextQuestionIndex,
            );

            setCurrentQuestion(nextQuestion);
            setCurrentQuestionIndex(nextQuestionIndex);
        }
    };

    const renderInterviewRecorder = () => {
        return (
            <AppLayout>
                {/* Button: Watch again */}
                {!!blob &&
                    !cameraState.isCameraRecording &&
                    !!currentQuestion.videos && (
                        <WatchVideoAgainContainer>
                            <BackButton
                                id="back-button"
                                className="InterviewController__BackButton"
                                onClick={() => watchVideoAgain()}
                            />
                            <WatchVideoAgainMessage
                                onClick={() => watchVideoAgain()}>
                                {t(
                                    'SPEAKING_MODULE.INTERVIEW_RECORDER.WATCH_QUESTION_AGAIN_MESSAGE',
                                )}
                            </WatchVideoAgainMessage>
                        </WatchVideoAgainContainer>
                    )}

                {/* Question text */}
                <Question>{currentQuestion.question}</Question>
                <RecorderDescription>
                    {t('SPEAKING_MODULE.INTERVIEW_RECORDER.MESSAGE')}
                </RecorderDescription>

                <React.Fragment>
                    {/* Recorder */}
                    <RecorderContainer>
                        <InterviewRecorder
                            videoStream={CameraService.videoStream}
                            blob={CameraService.getBlob()}
                            cameraState={cameraState}
                            onRecord={() => onRecordingAnswer()}
                            onStopRecording={() => onStopRecordingAnswer()}
                        />
                    </RecorderContainer>

                    {/* Button: Submit answer */}
                    <RecorderFooter>
                        {!!blob && (
                            <ButtonContainer>
                                <ShadowButton
                                    onClick={() => {
                                        onSubmitQuestion();
                                    }}>
                                    {t(
                                        'SPEAKING_MODULE.INTERVIEW_RECORDER.SUBMIT_BUTTON',
                                    )}
                                </ShadowButton>
                            </ButtonContainer>
                        )}
                    </RecorderFooter>
                </React.Fragment>
            </AppLayout>
        );
    };

    const renderVideoQuestion = () => {
        const videoId = videoModuleId(currentQuestion);
        const videoPreloaded = preloader[videoId];
        if (videoPreloaded && videoPreloaded.ready) {
            return (
                <VideoComponent
                    videoId={videoId}
                    videoUrl={videoPreloaded.url}
                    playAutomatically={true}
                    onComplete={() => onQuestionVideoComplete()}
                    isVideoFullscreen={true}
                />
            );
        } else {
            return (
                <VideoLoadingPlaceholder>
                    <RotateSpinLoader color="#fff" size="2" />
                </VideoLoadingPlaceholder>
            );
        }
    };

    const onAcceptQuestionIntroduction = () => {
        setCurrentQuestion({
            ...currentQuestion,
            shouldDisplayQuestionSubmitted: false,
        });
    };

    return (
        <React.Fragment>
            {!introductionAccepted && (
                <InterviewIntroduction
                    numberOfQuestions={module.questions.length}
                    onNext={() => setIntroductionAccepted(true)}
                />
            )}
            {introductionAccepted && currentQuestion && (
                <React.Fragment>
                    {currentQuestion.shouldDisplayQuestionSubmitted &&
                        currentQuestion.questionId !==
                            lastQuestion.questionId && (
                            <ModuleIntroductionView
                                buttonPlaceholder={t(
                                    'SPEAKING_MODULE.NEXT_QUESTION_INTRODUCTION.NEXT_BUTTON',
                                )}
                                onNext={() => onAcceptQuestionIntroduction()}>
                                <h2>
                                    {t(
                                        'SPEAKING_MODULE.NEXT_QUESTION_INTRODUCTION.TITLE',
                                    )}
                                </h2>
                                <p>
                                    {t(
                                        'SPEAKING_MODULE.NEXT_QUESTION_INTRODUCTION.MESSAGE',
                                    )}
                                </p>
                            </ModuleIntroductionView>
                        )}
                    {currentQuestion.shouldDisplayQuestionSubmitted &&
                        currentQuestion.questionId ===
                            lastQuestion.questionId && (
                            <ModuleIntroductionView
                                buttonPlaceholder={t(
                                    'SPEAKING_MODULE.LAST_QUESTION_INTRODUCTION.NEXT_BUTTON',
                                )}
                                onNext={() => onAcceptQuestionIntroduction()}>
                                <h2>
                                    {t(
                                        'SPEAKING_MODULE.LAST_QUESTION_INTRODUCTION.TITLE',
                                    )}
                                </h2>

                                <p>
                                    {t(
                                        'SPEAKING_MODULE.LAST_QUESTION_INTRODUCTION.MESSAGE',
                                    )}
                                </p>
                            </ModuleIntroductionView>
                        )}
                    {!currentQuestion.shouldDisplayQuestionSubmitted &&
                        currentQuestion.videos &&
                        currentQuestion.playingVideo &&
                        renderVideoQuestion()}
                    {!currentQuestion.shouldDisplayQuestionSubmitted &&
                        (!currentQuestion.videos ||
                            !currentQuestion.playingVideo) &&
                        renderInterviewRecorder()}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default InterviewController;
