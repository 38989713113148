import styled from 'styled-components';

// export const Container = styled.footer`
//     display: flex;
//     flex-direction: column;

//     color: ${props => props.theme.textColor};
//     h1 {
//         margin: 10px 0;
//     }
//     h2,
//     h3 {
//         margin: 0;
//         color: ${props => props.theme.secondaryTextColor};
//     }
//     h3 {
//         font-weight: normal;
//     }
//     h2, h3, span, p {
//         margin: 0;
//         margin-bottom: 10px;
//     }
// `;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
`;

export const ContentLayout = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;

export const Image = styled.img`
    width: 120px;
    height: 120px;
`;

export const Message = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.textColor};
    margin-left: 40px;

    h1 {
        margin-top: 5px;
    }
    h1,
    p {
        margin-bottom: 15px;
    }
`;
export const Footer = styled.footer`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

export const ButtonContainer = styled.footer`
    display: flex;
    width: 200px;
    margin-top: 40px;
`;
