import { getBrowserName } from './device-data';
import { isMobileOrTablet } from './detect-mobile';

export const detectIfBrowserIsSupported = () => {
    const browser = getBrowserName();
    const isDeviceMobileOrTablet = isMobileOrTablet();

    switch (browser) {
        case 'edge':
            return false;
        case 'ie':
            return false;
        case 'safari':
            return isDeviceMobileOrTablet;
        case 'firefox':
            return isDeviceMobileOrTablet;
        default:
            return true;
    }
};