import styled from 'styled-components';
import {breakpoints} from "../../App.style";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    animation: fadeIn 1s;

    justify-content: space-between;
    align-items: center;
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4vw;
    }
`;

export const Message = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
        color: ${(props) => props.theme.textColor};
        margin: 10px 0;
    }

    h3 {
        color: #8da3b9;
        font-weight: normal;
        margin: 0;
        text-align: center;
        a {
            color: ${(props) => props.theme.mainColor};
        }
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100px;
    padding: 10px 0;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100px;
    padding: 10px 0;

    span {
        color: ${(props) => props.theme.textColor};
        font-weight: bold;
        user-select: none;
    }

    img {
        width: 140px;
        margin-left: 20px;
    }
`;

export const ButtonContainer = styled.div`
    width: 300px;
`;
