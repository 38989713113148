import React from 'react';
import {
    LogoContainer,
} from './CompanyLogo.style';
import Skeleton from '@material-ui/lab/Skeleton';

const CompanyLogo = (props) => {
    const { logoUrl } = props;

    return (
        <LogoContainer>
            {!logoUrl && (
                <Skeleton
                    className="circleSkeleton"
                    variant="circle"
                    width={150}
                    height={150}
                />
            )}
            {logoUrl && <img src={logoUrl} alt="Logo" />}
        </LogoContainer>
    );
};

export default CompanyLogo;
