import React, { useEffect, useState } from 'react';
import {breakpoints} from "../../App.style";

const withMediaQuery = (Component) => {
    return (props) => {
        const [isMobileSize, setIsMobileSize] = useState(
            window.matchMedia(`(max-width: ${breakpoints.mobile})`).matches,
        );
        const [isTabletAndMobileSize, setIsTabletAndMobileSize] = useState(
            window.matchMedia(`(max-width: ${breakpoints.tablet})`).matches,
        );

        useEffect(() => {
            const handleTabletChange = (e) => {
                setIsTabletAndMobileSize(e.matches);
            };
            const handleMobileChange = (e) => {
                setIsMobileSize(e.matches);
            };

            const tabletMediaQuery = window.matchMedia(`(max-width: ${breakpoints.tablet})`);
            const mobileMediaQuery = window.matchMedia(`(max-width: ${breakpoints.mobile})`);

            const handleMediaQueryChange = (e) => {
                if (e.media === `(max-width: ${breakpoints.tablet})`) {
                    handleTabletChange(e);
                } else if (e.media === `(max-width: ${breakpoints.mobile})`) {
                    handleMobileChange(e);
                }
            };

            if (tabletMediaQuery.addEventListener) {
                tabletMediaQuery.addEventListener('change', handleMediaQueryChange);
            } else {
                tabletMediaQuery.addListener(handleMediaQueryChange);
            }

            if (mobileMediaQuery.addEventListener) {
                mobileMediaQuery.addEventListener('change', handleMediaQueryChange);
            } else {
                mobileMediaQuery.addListener(handleMediaQueryChange);
            }

            return () => {
                if (tabletMediaQuery.removeEventListener) {
                    tabletMediaQuery.removeEventListener('change', handleMediaQueryChange);
                } else {
                    tabletMediaQuery.removeListener(handleMediaQueryChange);
                }

                if (mobileMediaQuery.removeEventListener) {
                    mobileMediaQuery.removeEventListener('change', handleMediaQueryChange);
                } else {
                    mobileMediaQuery.removeListener(handleMediaQueryChange);
                }
            };
        }, []);

        return (
            <Component
                {...props}
                isMobileSize={isMobileSize}
                isTabletAndMobileSize={isTabletAndMobileSize}
            />
        );
    };
};

export default withMediaQuery;
