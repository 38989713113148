import { put, select, call } from 'redux-saga/effects';
import {
    authActionTypes,
    refreshToken,
    storeUser as storeUserAction,
} from './auth.actions';
import NeurolyticsAPI from 'services/NeurolyticsAPI';
import createAuth0Client from '@auth0/auth0-spa-js';
import {
    AUTH0_DOMAIN,
    AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE,
} from 'utils/environment-variables';
import { auth0Client, setAuth0Client } from 'Auth';

export function* initAuth0() {
    try {
        if (auth0Client === null) {
            const domain = AUTH0_DOMAIN;
            const clientId = AUTH0_CLIENT_ID;
            const audience = AUTH0_AUDIENCE;

            if (domain && clientId && audience) {
                const client = yield call(createAuth0Client, {
                    domain: domain,
                    client_id: clientId,
                    audience: audience,
                    redirect_uri: window.location.origin,
                    issuer: `https://${domain}/`,
                });
                yield call(setAuth0Client, client);
                yield put({
                    type: authActionTypes.INIT_AUTH0_SUCCESS,
                });
            } else {
                yield put({
                    type: authActionTypes.INIT_AUTH0_FAILURE,
                    payload: {
                        error: Error(
                            'Failed to initialize auth0 client due to missing parameters',
                        ),
                    },
                });
            }
        }
    } catch (error) {
        yield put({
            type: authActionTypes.INIT_AUTH0_FAILURE,
            payload: { error },
        });
    }
}

export function* initLogin(action) {
    if (auth0Client !== null) {
        const { companySlug, invitationId, mode } = action.payload;
        let loginOptions = {};

        if (companySlug && invitationId) {
            const { companies, scans } = yield select((state) => state.config);
            const { assessmentId } = scans[invitationId];
            const { logoUrl } = companies[companySlug];
            loginOptions = {
                appState: {
                    companySlug: companySlug,
                    assessmentId: assessmentId,
                    invitationId: invitationId,
                },
                logoUrl: logoUrl,
            };
        }
        if (mode) {
            loginOptions.action = mode;
        }

        yield auth0Client.loginWithRedirect(loginOptions);
    }
}

export function* storeUser() {
    try {
        if (auth0Client) {
            const isAuthenticated = yield auth0Client.isAuthenticated();
            if (isAuthenticated) {
                const user = yield auth0Client.getUser();
                const namespace = 'https://neurolytics.ai/';

                if (user[`${namespace}id`]) {
                    user.id = user[`${namespace}id`];
                    delete user[`${namespace}id`];
                }
                if (user[`${namespace}roles`]) {
                    user.roles = user[`${namespace}roles`];
                    delete user[`${namespace}roles`];
                }
                if (user[`${namespace}permissions`]) {
                    user.permissions = user[`${namespace}permissions`];
                    delete user[`${namespace}permissions`];
                }
                if (user[`${namespace}target_group_permissions`]) {
                    user.target_group_permissions =
                        user[`${namespace}target_group_permissions`];
                    delete user[`${namespace}target_group_permissions`];
                }
                if (user[`${namespace}company_id`]) {
                    user.company_id = user[`${namespace}company_id`];
                    delete user[`${namespace}company_id`];
                }
                if (user[`${namespace}scans`]) {
                    user.scans = user[`${namespace}scans`];
                    delete user[`${namespace}scans`];
                }

                yield put({
                    type: authActionTypes.STORE_USER_SUCCESS,
                    payload: { user },
                });
            }
        }
    } catch (error) {
        yield put({
            type: authActionTypes.STORE_USER_FAILURE,
            payload: { error },
        });
    }
}

export function* registerUser(action) {
    const { targetGroupId, user } = action.payload;
    try {
        const registeredUser = yield call(
            NeurolyticsAPI.registerUser,
            targetGroupId,
            user,
        );
        if (registeredUser.error) {
            yield put({
                type: authActionTypes.REGISTER_USER_FAILURE,
                payload: { error: registeredUser.error },
            });
        } else {
            yield put({
                type: authActionTypes.REGISTER_USER_SUCCESS,
                payload: { registeredUser },
            });
            yield put(
                refreshToken(
                    registeredUser.companySlug,
                    registeredUser.invitationId,
                ),
            );
        }
    } catch (error) {
        yield put({
            type: authActionTypes.REGISTER_USER_FAILURE,
            payload: { error: { errorCode: 'UnexpectedError' } },
        });
    }
}

export function* refreshTokenEffect(action) {
    const { companySlug, invitationId } = action.payload;
    try {
        const response = yield auth0Client.getTokenSilently({
            ignoreCache: true,
        });
        if (response) {
            yield put(storeUserAction());
            yield put({
                type: authActionTypes.REFRESH_TOKEN_SUCCESS,
                payload: { companySlug, invitationId },
            });
        } else {
            yield put({
                type: authActionTypes.REFRESH_TOKEN_FAILURE,
                payload: { error: { errorCode: 'UnexpectedError' } },
            });
        }
    } catch (error) {
        yield put({
            type: authActionTypes.REFRESH_TOKEN_FAILURE,
            payload: { error: { errorCode: 'UnexpectedError' } },
        });
    }
}
