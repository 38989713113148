import styled from 'styled-components';
import {breakpoints} from "../../../App.style";

export const Container = styled.div`
    padding: 0 !important;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        .MuiButtonBase-root {
            font-size: 0.9em;
        }
    }
`;
