import React, { useEffect } from 'react';
import { ConsentProvider } from 'react-hook-consent';
import { useTranslation } from 'react-i18next';
import App from './App';
import debounce from 'lodash/debounce';
import { getWindowData } from './utils/device-data';
import SessionAnalytics from './services/SessionAnalytics';
import { useDispatch, useSelector } from 'react-redux';
import { auth0Client } from './Auth';
import { initAuth0 } from './store/auth/auth.actions';
import { detectIfBrowserIsSupported } from './utils/detectIfBrowserIsSupported';
import { CLARITY_PROJECT_ID } from './utils/environment-variables';

const CookieConsentWrapper = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    // Detect browser, windows size and init auth
    useEffect(() => {
        detectIfBrowserIsSupported();
        window.addEventListener('resize', debounce(onResize, 1000));
        detectWindowMovement();
    }, []);

    useEffect(() => {
        if (!auth0Client) {
            dispatch(initAuth0());
        }
    }, [auth0Client, dispatch]);

    useEffect(() => {
        if (user && user.id) {
            SessionAnalytics.onLogin(user);
        }
    }, [user]);

    const detectWindowMovement = () => {
        let windowPosition = { x: window.screenX, y: window.screenY };
        document.addEventListener('mouseout', () => {
            const position = { x: window.screenX, y: window.screenY };

            if (
                position.x !== windowPosition.x ||
                position.y !== windowPosition.y
            ) {
                const windowData = getWindowData();
                onWindowEvent('windowMove', windowData);
                windowPosition = { x: window.screenX, y: window.screenY };
            }
        });
    };

    const onResize = () => {
        const windowData = getWindowData();
        onWindowEvent('windowResize', windowData);
    };

    const onWindowEvent = (eventId, data) => {
        const event = {
            timeStamp: Date.now(),
            timeString: new Date(Date.now()).toISOString(),
            eventType: eventId,
            data: data,
        };
        SessionAnalytics.onWindowEvent(event);
    };

    const consentOptions = {
        services: [
            {
                id: 'essential-cookies',
                name: `${t('COOKIE_CONSENT.COOKIE_DESCRIPTION.ESSENTIALS')}`,
                description: `${t(
                    'COOKIE_CONSENT.COOKIE_DESCRIPTION.ESSENTIALS_DESC',
                )}`,
                cookies: [
                    // https://auth0.com/docs/manage-users/cookies/authentication-api-cookies
                    { pattern: 'auth0' },
                    { pattern: '_legacy_auth0' },
                    { pattern: 'auth0_compat' },
                    { pattern: 'auth0-mf' },
                    { pattern: 'auth0-mf_compat' },
                    { pattern: 'a0_users:sess' },
                    { pattern: 'a0_users:sess.sig' },
                    { pattern: 'did' },
                    { pattern: 'did_compat' },
                    { pattern: '__cf_bm' },
                ],
                mandatory: true,
            },
            {
                id: 'recommended-cookies',
                name: `${t('COOKIE_CONSENT.COOKIE_DESCRIPTION.RECOMMENDED')}`,
                description: `${t(
                    'COOKIE_CONSENT.COOKIE_DESCRIPTION.RECOMMENDED_DESC',
                )}`,
                scripts: [
                    {
                        id: 'cookie-clarity-init',
                        code: `
                                (function(c,l,a,r,i,t,y){
                                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                })(window, document, "clarity", "script", "${CLARITY_PROJECT_ID}");
                        `,
                    },
                ],
                cookies: [
                    // https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-list
                    { pattern: '_clck' },
                    { pattern: '_clsk' },
                    { pattern: 'CLID' },
                    { pattern: 'ANONCHK' },
                    { pattern: 'MR' },
                    { pattern: 'MUID' },
                    { pattern: 'SM' },
                ],
                mandatory: false,
            },
        ],
        theme: 'light',
    };

    return (
        <ConsentProvider options={consentOptions}>
            <App {...props} />
        </ConsentProvider>
    );
};

export default CookieConsentWrapper;
