import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VideoLoadingPlaceholder } from './CultureFitModule.style';
import { getCultureFitVideoId } from 'utils/module-identificators';

import VideoComponent from 'components/shared/VideoComponent/VideoComponent';
import CultureFitIntroduction from './CultureFitIntroduction/CultureFitIntroduction';

import { RotateSpinLoader } from 'react-css-loaders';
const videoId = getCultureFitVideoId();

export class CultureFitModule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            introductionAccepted: false,
        };

        this.acceptIntroduction = this.acceptIntroduction.bind(this);
        this.getPreloadedVideo = this.getPreloadedVideo.bind(this);
    }

    getPreloadedVideo() {
        const { preloader } = this.props;

        if (
            !this.state.videoUrl &&
            preloader &&
            videoId &&
            preloader[videoId] &&
            preloader[videoId].ready
        ) {
            this.setState({
                videoUrl: preloader[videoId].url,
            });
        }
    }

    componentDidMount() {
        this.getPreloadedVideo();
    }

    componentDidUpdate() {
        this.getPreloadedVideo();
    }

    acceptIntroduction() {
        this.setState({
            introductionAccepted: true,
        });
    }

    render() {
        const { introductionAccepted, videoUrl } = this.state;
        return (
            <React.Fragment>
                {!introductionAccepted && (
                    <CultureFitIntroduction
                        onNext={() => this.acceptIntroduction()}
                    />
                )}
                {introductionAccepted && videoUrl && (
                    <VideoComponent
                        videoId={videoId}
                        videoUrl={videoUrl}
                        playAutomatically={true}
                        onStartPlaying={this.props.onStartRecording}
                        onComplete={this.props.onComplete}
                        isVideoFullscreen={true}
                    />
                )}
                {introductionAccepted && !videoUrl && (
                    <VideoLoadingPlaceholder visible={!videoUrl}>
                        <RotateSpinLoader color="#fff" size="2" />
                    </VideoLoadingPlaceholder>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    preloader: state.preloader.videos,
});

export default connect(mapStateToProps)(CultureFitModule);
