import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import nl from './translations/nl.json';

const resources = {
    'en-US': {
        translation: en,
    },
    'nl-NL': {
        translation: nl,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en-US',
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'a'],
        interpolation: {
            escapeValue: false,
        },
    },
    interpolation: {
        escapeValue: false,
    },
});

document.documentElement.lang = i18n.language.substring(0, 2);
i18n.on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng.substring(0, 2));});

export default i18n;
