import styled from 'styled-components';
import {breakpoints} from "../../App.style";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 650px;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    margin: auto;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        justify-content: flex-start;
        padding-top: 2em;
    }
`;

export const Text = styled.span`
    color: ${(props) => props.theme.textColor};
    margin: 10px 0;
    font-weight: normal;
    text-align: left;

    h1 {
        margin: 0;
        padding: 0;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1.5em;
        }
    }

    h2 {
        font-size: 1em;
        color: ${(props) => props.theme.textColor};
        margin-top: 15px;
        font-weight: normal;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1em;
        }
    }
`;

export const ProgressionContainer = styled.div`
    width: 100%;
    padding: 20px;
`;
