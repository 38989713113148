import { put, call } from 'redux-saga/effects';
import configTypes from './config.types';
import NeurolyticsAPI from 'services/NeurolyticsAPI';
import Logger from "../../services/Logger";

export function* fetchCompany(action) {
    const { companySlug, navigate } = action.payload;
    try {
        const { name, logoUrl, language } = yield call(
            [NeurolyticsAPI, 'fetchCompanyApi'],
            companySlug,
        );
        yield put({
            type: configTypes.FETCH_COMPANY_SUCCESS,
            payload: {
                companySlug,
                companyName: name,
                defaultLanguage: language,
                logoUrl,
                // country,
            },
        });
    } catch (error) {
        yield put({
            type: configTypes.FETCH_COMPANY_FAILURE,
            payload: {
                error,
            },
        });
        navigate('/404');
    }
}

export function* fetchConfig(action) {
    try {
        const { assessmentId, targetGroupId, invitationId } = action.payload;
        const {
            companySlug,
            companyName,
            companyLogoUrl,
            targetGroupName,
            config,
            modules,
            language,
            assessmentName,
            description,
            autoAnalysis,
            dataCollection,
            isCompleted,
        } = yield call([NeurolyticsAPI, 'fetchConfigApi'], assessmentId, invitationId);

        yield put({
            type: configTypes.FETCH_COMPANY_SUCCESS,
            payload: {
                companySlug,
                companyName,
                defaultLanguage: language,
                logoUrl: companyLogoUrl,
                language: 'en-US', // todo: update this after adding language to companies on backend
                // country,
            },
        });

        yield put({
            type: configTypes.FETCH_CONFIG_SUCCESS,
            payload: {
                assessmentId,
                invitationId,
                targetGroupId,
                targetGroupName,
                companySlug,
                config,
                modules,
                language,
                assessmentName,
                description,
                autoAnalysis,
                dataCollection,
                isCompleted,
            },
        });
    } catch (error) {
        yield put({
            type: configTypes.FETCH_CONFIG_FAILURE,
            payload: {
                error,
            },
        });
    }
}

export function* finishScan(action) {
    const { assessmentId, invitationId } = action.payload;

    try {
        yield call([NeurolyticsAPI, 'finishScanApiCall'], assessmentId, invitationId);
        Logger.logInfo(
          `AssessmentController - Scan completion requested for invitationId: ${invitationId}`,
        );
        Logger.logInfo('Assessment DONE');
        yield put({
            type: configTypes.FINISH_SCAN_SUCCESS,
        });
    } catch (error) {
        Logger.logError(
          `AssessmentController - Failed to request scan completion for invitationId: ${invitationId}`, error
        );

        yield put({
            type: configTypes.FINISH_SCAN_FAILURE,
            payload: {
                error,
            },
        });
    }
}

