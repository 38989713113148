import { put, select } from 'redux-saga/effects';
import { preloaderActionTypes } from './preloader.actions';

import { preloadResource } from 'utils/preloader';

export function* preloadVideo(action) {
    try {
        const state = yield select((state) => state.preloader);

        if (state.currentlyLoading) {
            const resource = {
                resourceType: 'video',
                resourceId: action.payload.videoId,
                resourceUrl: action.payload.videoUrl,
            };

            yield put({
                type: preloaderActionTypes.PRELOAD_VIDEO_IN_QUEUE,
                payload: { resource },
            });
        } else {
            yield put({
                type: preloaderActionTypes.PRELOAD_VIDEO_FETCHING,
                payload: { videoId: action.payload.videoId, videoUrl: action.payload.videoUrl },
            });
        }
    } catch (error) {
        yield put({
            type: preloaderActionTypes.PRELOAD_VIDEO_FAILURE,
            payload: { resourceId: action.payload.videoId, error },
        });
    }
}

export function* fetchingVideo(action) {
    try {
        const response = yield preloadResource(action.payload.videoUrl);

        const blobUrl = URL.createObjectURL(response);
        yield put({
            type: preloaderActionTypes.PRELOAD_VIDEO_DONE,
            payload: { videoId: action.payload.videoId, videoUrl: blobUrl },
        });
        const state = yield select((state) => state.preloader);
        if (state.queue.length > 0) {
            const nextVideoToPreload = state.queue[0];
            yield put({
                type: preloaderActionTypes.PRELOAD_VIDEO,
                payload: {
                    videoId: nextVideoToPreload.resourceId,
                    videoUrl: nextVideoToPreload.resourceUrl,
                    loadedFromQueue: true,
                },
            });
        }
    } catch (error) {
        yield put({
            type: preloaderActionTypes.PRELOAD_VIDEO_FAILURE,
            payload: { resourceId: action.payload.videoId, error },
        });
    }
}
