import styled from 'styled-components';

export const Title = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
`;

export const CrossIconButton = styled.span`
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
    svg {
        color: #fff;
    }
`;
