import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    Header,
    Message,
    Container,
    FormContainer,
    FadeIn,
    Title,
    ButtonContainer,
    Footer,
    ForgotPassword,
} from './Login.style';

import Skeleton from '@material-ui/lab/Skeleton';

import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import poweredByNeurolyticsLogo from 'assets/images/powered-by-neurolytics.png';
import { initLogin } from '../../../store/auth/auth.actions';
import withMediaQuery from '../../../hoc/MediaQuery/withMediaQuery';
import CompanyLogo from '../../shared/CompanyLogo/CompanyLogo';

const Login = (props) => {
    const { t, isMobileSize, companySlug, invitationId } = props;
    const companyConfig = useSelector((state) => state.config.companies);
    const logoUrl =
        companyConfig[companySlug] && companyConfig[companySlug].logoUrl
            ? companyConfig[companySlug].logoUrl
            : null;
    const companyName =
        companyConfig[companySlug] && companyConfig[companySlug].companyName
            ? companyConfig[companySlug].companyName
            : null;

    const [defaultCompanyName, setDefaultCompanyName] = useState('');
    const [defaultLogoUrl, setDefaultLogoUrl] = useState('');
    const { error } = useSelector((state) => state.auth);
    const query = new URLSearchParams(useLocation().search);
    const auth0success = query.get('success');
    const auth0message = query.get('message');
    const dispatch = useDispatch();

    useEffect(() => {
        if (!companyName && !logoUrl) {
            setDefaultCompanyName('Neurolytics');
            setDefaultLogoUrl(
                'https://s3.eu-west-1.amazonaws.com/neurolytics.public/neurolytics/images/neurolytics-logo.png',
            );
        }
    }, [companyName, logoUrl]);

    const onLogin = (event) => {
        event.preventDefault();
        if (companySlug && invitationId) {
            dispatch(initLogin(companySlug, invitationId));
        } else {
            dispatch(initLogin('', ''));
        }
    };

    const forgotPassword = (event) => {
        event.preventDefault();
        if (companySlug && invitationId) {
            dispatch(initLogin(companySlug, invitationId, 'forgotPassword'));
        } else {
            dispatch(initLogin('', '', 'forgotPassword'));
        }
    };

    return (
        <Container>
            <Header>
                <CompanyLogo logoUrl={logoUrl ? logoUrl : defaultLogoUrl} />
            </Header>
            <FormContainer>
                {error && error.error_description && (
                    <Message>{error.error_description}</Message>
                )}
                {auth0message && (
                    <Message>
                        {auth0message.includes('Your email was verified') &&
                            auth0success === 'true' &&
                            t('LOGIN.EMAIL_WAS_VERIFIED')}
                        {auth0message.includes(
                            'This URL can be used only once',
                        ) &&
                            auth0success === 'false' &&
                            t('LOGIN.URL_CAN_ONLY_BE_USED_ONCE')}
                        {auth0message.includes(
                            'You can now login to the application',
                        ) &&
                            auth0success === 'true' &&
                            t('LOGIN.YOU_CAN_LOGIN')}
                        {auth0message.includes('verifyEmail') &&
                            t('LOGIN.VERIFY_EMAIL')}
                        {auth0message.includes('registeredAndVerified') &&
                            t('LOGIN.REGISTERED_AND_VERIFIED')}
                    </Message>
                )}
                <Title>
                    {companyName ? (
                        <FadeIn>
                            {t('LOGIN.TITLE', { company_name: companyName })}
                        </FadeIn>
                    ) : defaultCompanyName ? (
                        <FadeIn>
                            {t('LOGIN.TITLE', {
                                company_name: defaultCompanyName,
                            })}
                        </FadeIn>
                    ) : (
                        <Skeleton />
                    )}
                </Title>
                <ButtonContainer>
                    <ShadowButton
                        onClick={onLogin}
                        size={isMobileSize ? 'small' : 'default'}
                        disabled={
                            auth0message && auth0message.includes('verifyEmail')
                        }>
                        {companyName || defaultCompanyName ? (
                            <FadeIn>{t('LOGIN.CONTINUE_BUTTON')}</FadeIn>
                        ) : (
                            <span>&nbsp;</span>
                        )}
                    </ShadowButton>
                </ButtonContainer>
                <ForgotPassword onClick={forgotPassword}>
                    Forgot password?
                </ForgotPassword>
            </FormContainer>
            <Footer>
                <img src={poweredByNeurolyticsLogo} alt="Neurolytics Logo" />
            </Footer>
        </Container>
    );
};

export default withMediaQuery(withTranslation()(Login));
