import styled from 'styled-components';
import { breakpoints } from '../../App.style';

export const Container = styled.div`
    position: relative;
    margin: 0 5vw;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .img-footer {
        width: 200px;
        padding-bottom: 16px;
        @media only screen and (max-width: ${breakpoints.mobile}) {
            width: 180px;
            padding: 1em 0;
        }
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
        flex-wrap: nowrap;
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
        justify-content: flex-start;
    }
    .spacer {
        flex-grow: 1;
    }
`;

export const TwoColumnsLayout = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 40px;
    gap: 3em;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        flex-direction: column;
        margin-bottom: 0;
        gap: 0;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1em;
`;

export const ContentColumn = styled.div`
    position: relative;
    flex: 1;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.textColor};
    @media only screen and (max-width: ${breakpoints.tablet}) {
        min-width: 200px;
        padding-top: 1em;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.textColor};
    @media only screen and (max-width: ${breakpoints.mobile}) {
        text-align: left;
    }

    h1 {
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1.4em;
        }
    }

    h1,
    p {
        margin: 0;
        padding: 0;
    }

    p {
        margin-top: 15px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1em;
        }
    }
`;

export const InfoBlock = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding: 15px;
    margin-bottom: 15px;
    background: #f4f4f4;
    align-items: center;
    overflow-y: auto;

    @media only screen and (max-width: ${breakpoints.tablet}) {
        margin-top: 1em;
    }
    p {
        font-size: 0.9em;
        margin: 0 0 0 15px;
        @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 1em;
        }
    }
`;

export const ContentFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        justify-content: center;
        align-items: center;
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
        padding-bottom: 1em;
    }
`;

export const ButtonContainer = styled.div`
    width: 200px;
    margin-top: 20px;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        margin-top: 0;
        width: 100%;
    }
`;

export const TextFieldContainer = styled.div`
    display: block;
    position: relative;
    margin-top: 15px;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        textarea {
            font-size: 0.9em;
        }
    }
`;

export const GridContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    color: ${(props) => props.theme.textColor};
`;

export const Question = styled.h3`
    font-weight: bold;
    color: ${(props) => props.theme.textColor};
    margin: 0;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 1em;
    }
    &:nth-child(2) {
        padding-top: 40px;
    }
    &.feedback-rate {
        padding-top: 2em;
    }
`;

export const RateWrapper = styled.div`
    padding-top: 1em;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    .feedback-rate {
        margin: 0 1.3em;
        @media only screen and (max-width: ${breakpoints.desktop}) {
            margin: 0 0.9em;
        }
        @media only screen and (max-width: ${breakpoints.tablet}) {
            margin: 0 0.8em;
        }
        @media only screen and (max-width: ${breakpoints.smallMobile}) {
            margin: 0 0.6em;
        }
        svg {
            width: 100%;
        }
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
`;

export const BoldItalic = styled.span`
    font-weight: bold;
    font-style: italic;
    color: ${(props) => props.theme.mainColor};
    margin: 0;
`;
