import styled from 'styled-components';
import {breakpoints} from "../../../App.style";

export const Container = styled.div`
    width: 60vw;
    background: none;
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: auto;
        overflow-y: auto;
    }
`;

export const StepContainer = styled.div`
    position: relative;
`;

export const StepLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 1em;
        gap: 1em;
    }
`;

export const StepImage = styled.img`
    margin-top: 25px;
    width: 100%;
    max-width: 275px;
    height: auto;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        max-width: 200px;
        margin-top: 0;
    }
`;
