import styled from 'styled-components';
import {breakpoints} from "../../App.style";

export const Container = styled.div`
    position: relative;
    min-height: 100vh;
    margin: 0 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4vw;
    }
`;

export const TwoColumnsLayout = styled.div`
    display: flex;
    flex-flow: row-nowrap;
`;

export const ImageColumn = styled.div`
    display: flex;
    position: relative;
    width: 45vw;
    min-width: 400px;
    max-width: 900px;
    height: auto;
    justify-content: center;
    align-items: center;
`;

export const ModuleImage = styled.img`
    width: 100%;
    height: auto;
`;

export const ContentColumn = styled.div`
    position: relative;
    flex: 1;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 5vw;
    color: ${(props) => props.theme.textColor};
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;

    color: ${(props) => props.theme.textColor};

    h1,
    p {
        margin: 0;
        padding: 0;
    }

    p {
        margin-top: 15px;
    }
`;

export const ContentFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const ButtonContainer = styled.div`
    width: 200px;
    margin-top: 50px;
`;

export const List = styled.ol`
    margin-top: 20px;
    line-height: 1.5em;
    padding-left: 20px;
`;

export const ListItem = styled.li`
    font-weight: bold;
`;
