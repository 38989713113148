import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import {
    Container,
    Header,
    Message,
    Footer,
    TextContainer,
} from './AssessmentEndingView.style';

import neurolyticsLogo from 'assets/images/neurolytics-logo.png';
import poweredByNeurolyticsLogo from 'assets/images/powered-by-neurolytics.png';
import CompanyLogo from '../../components/shared/CompanyLogo/CompanyLogo';
import withMediaQuery from '../../hoc/MediaQuery/withMediaQuery';
import SuccessAnimation from '../../components/SuccessAnimation/SuccessAnimation';

function AssessmentEndingView(props) {
    const { modules, companySlug, onScanFinished, isMobileSize } = props;
    const { companies } = useSelector((state) => state.config);
    const { logoUrl } = companies[companySlug];

    useEffect(() => {
        onScanFinished();
    }, []);

    return (
        <>
            <SuccessAnimation />
            <Container>
                <Header>
                    <CompanyLogo logoUrl={logoUrl ? logoUrl : neurolyticsLogo} />
                </Header>
                <TextContainer>
                    <Message>
                        <h1>{props.t('ENDING_VIEW.TITLE')}</h1>
                        <h3>
                            <Trans t={props.t} i18nKey="ENDING_VIEW.MESSAGE_1" />
                        </h3>
                        {modules[modules.length - 1].customText1 && (
                            <h3> {modules[modules.length - 1].customText1}</h3>
                        )}
                        {modules[modules.length - 1].customText2 && (
                            <h3> {modules[modules.length - 1].customText3}</h3>
                        )}
                        <h3>
                            <Trans t={props.t} i18nKey="ENDING_VIEW.MESSAGE_2" />
                        </h3>
                        <h3>
                            <Trans t={props.t} i18nKey="ENDING_VIEW.MESSAGE_3" />
                        </h3>
                    </Message>
                </TextContainer>
                {isMobileSize && <div className="spacer"></div>}
                <Footer>
                    <img src={poweredByNeurolyticsLogo} alt="Neurolytics Logo" />
                </Footer>
            </Container>
        </>
    );
}

export default withMediaQuery(withTranslation()(AssessmentEndingView));
