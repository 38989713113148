import { preloaderActionTypes } from './preloader.actions';

export const preloaderInitialState = {
    queue: [],
    currentlyLoading: undefined,
    videos: {},
};

export default (state = preloaderInitialState, action) => {
    switch (action.type) {
        case preloaderActionTypes.PRELOAD_VIDEO_FETCHING: {
            const { videoId } = action.payload;
            let queue = [...state.queue].filter((resource) => resource.resourceId !== action.payload.videoId);

            const resource = {
                resourceType: 'video',
                resourceId: videoId
            }
            return {
                ...state,
                queue,
                currentlyLoading: resource,
                videos: {
                    ...state.videos,
                    [action.payload.videoId]: {
                        loading: true,
                        ready: false,
                    },
                },
            };
        }

        case preloaderActionTypes.PRELOAD_VIDEO_IN_QUEUE: {
            const { resource } = action.payload;
            const updatedQueue = [...state.queue, resource];

            return {
                ...state,
                queue: updatedQueue,
                videos: {
                    ...state.videos,
                    [action.payload.resource.resourceId]: {
                        loading: true,
                        ready: false,
                    },
                },
            };
        }

        case preloaderActionTypes.PRELOAD_VIDEO_DONE: {
            const { videoId, videoUrl } = action.payload;

            return {
                ...state,
                currentlyLoading: undefined,
                videos: {
                    ...state.videos,
                    [videoId]: {
                        loading: false,
                        ready: true,
                        url: videoUrl,
                    },
                },
            };
        }

        case preloaderActionTypes.PRELOAD_VIDEO_FAILURE: {
            const { videoId, error } = action.payload;

            return {
                ...state,
                currentlyLoading: undefined,
                videos: {
                    ...state.videos,
                    [videoId]: {
                        loading: false,
                        ready: false,
                        error,
                    },
                },
            };
        }

        default:
            return state;
    }
};
