import styled from 'styled-components';
import { breakpoints } from '../../../App.style';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    @keyframes slideInForward {
        from {
            transform: translateY(100%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes slideOutForward {
        from {
            transform: translateY(0);
            opacity: 1;
        }
        to {
            transform: translateY(-100%);
            opacity: 0;
        }
    }

    @keyframes slideInBackward {
        from {
            transform: translateY(-100%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes slideOutBackward {
        from {
            transform: translateY(0);
            opacity: 1;
        }
        to {
            transform: translateY(100%);
            opacity: 0;
        }
    }

    &.entering.forward {
        animation: slideInForward 0.5s forwards;
    }

    &.exiting.forward {
        animation: slideOutForward 0.5s forwards;
    }

    &.entering.backward {
        animation: slideInBackward 0.5s forwards;
    }

    &.exiting.backward {
        animation: slideOutBackward 0.5s forwards;
    }
`;

export const GridContainer = styled.div`
    width: ${(props) => (props.isNotMentalTest ? '70%' : '100%')};
    display: flex;
    flex-direction: column;
    margin: ${(props) => (props.isNotMentalTest ? 'auto' : '10px 0')};
    color: ${(props) => props.theme.textColor};
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
    }
    &.landscape {
        width: 100%;
    }
`;

export const Question = styled.h3`
    font-weight: bold;
    color: ${(props) =>
        props.isNotMentalTest ? props.theme.textColor : props.theme.mainColor};
    font-size: ${(props) => (props.isNotMentalTest ? '1.4em' : '1em')};
    margin: 0 0 10px 0;
    @media only screen and (max-width: ${breakpoints.mobile}) {
        color: ${(props) => props.theme.textColor};
        font-size: 1.3em;
    }
    .question-number {
        font-size: 0.8em;
        padding-right: 8px;
    }
`;

export const QuestionDescription = styled.div`
    margin: 0 0 10px 0;
    h1,
    h2,
    h3 {
        font-weight: bold;
        color: ${(props) => props.theme.mainColor};
        margin: 5px 0;
    }
    span {
        font-weight: 500;
        color: ${(props) => props.theme.secondaryTextColor};
    }
    table {
        border: 1px solid ${(props) => props.theme.secondaryTextColor};
        border-collapse: collapse;
        tr,
        th,
        td {
            border: 1px solid ${(props) => props.theme.secondaryTextColor};
        }
        th,
        td {
            padding: 15px;
            text-align: left;
        }
        tr:nth-child(even) {
            background-color: ${(props) => props.theme.backgroundColor};
        }
    }
`;

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const HorizontalList = styled(List)`
    width: 100%;
    flex-direction: row;
    align-items: center;

    li {
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0 10px;
    }
`;

export const MultipleChoiceList = styled(List)`
    flex-flow: wrap;
    margin-top: 16px;
`;

export const TextFieldContainer = styled.div`
    display: block;
    max-width: 600px;
    position: relative;
`;

export const QuestionSection = styled.li`
    list-style: none;
`;
export const MultipleOptionsItemContainer = styled.li`
    display: flex;
    flex-flow: row nowrap;
    color: ${(props) => props.theme.textColor};
    align-items: flex-start;
    width: 300px;
    padding: 0 20px 20px 0;
    ${(props) =>
        props.isNotMentalTest
            ? `
        flex-direction: column;
        width:100%;
        padding: 0;
      `
            : ''};
    @media only screen and (max-width: ${breakpoints.mobile}) {
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    &.is-landscape {
        width: 100%;
    }
    > span {
        transform: translateY(-2px);
    }
`;

export const MultipleOptionsList = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    li {
        padding: ${(props) => (props.isNotMentalTest ? '0' : '0 0 5px 15px')};
    }
`;

export const DropdownContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 500px;
    flex-direction: column;
    margin-top: 10px;
`;

export const MultipleChoiceRadioLabel = styled.label`
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    span {
        padding-left: ${(props) => (props.isNotMentalTest ? '0' : '10px')};
        @media only screen and (max-width: ${breakpoints.mobile}) {
            padding-left: 0;
        }
    }
    ${(props) =>
        props.isNotMentalTest
            ? `
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 8px;
        padding: 0 8px;
        .MuiButtonBase-root{
           @keyframes blink {
            50% {
                opacity: 0.4;
                }
            }               
        }
      `
            : ''};
    @media only screen and (max-width: ${breakpoints.smallMobile}) {
        font-size: 0.8em;
    }
`;

export const AnswerWrapper = styled.li`
    list-style: none;
    ${(props) =>
        props.isNotMentalTest
            ? `
        border-radius: 6px;
        margin: 8px 0;
        height: 7vh;
        min-height: 56px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: flex-start;
        background-color: ${props.checked ? props.theme.mainColor : 'white'};
        color: ${props.checked ? 'white' : props.theme.textColor};
        border: 1px solid ${
            props.checked ? props.theme.mainColor : 'rgba(0, 0, 0, 0.54)'
        };
        &:hover{
            opacity: 0.8;
        }
        @media only screen and (max-width: ${breakpoints.mobile}) {
            height: 9vh;
            margin: 5% 0;
        }
        @media only screen and (max-width: ${breakpoints.smallMobile}) {
            height: 10vh;
            margin: 5% 0;
        }
      `
            : ''};
`;
export const QuestionnaireTitle = styled.h1`
    color: ${(props) => props.theme.mainColor};
`;
