import styled from 'styled-components';

export const Question = styled.h1`
    color: ${(props) => props.theme.textColor};
    font-size: 2.7vh;
    font-weight: bold;
    margin-top: 5px;
`;

export const RecorderDescription = styled.span`
    color: ${(props) => props.theme.textColor};
    font-size: 1.6vh;
`;

export const WatchVideoAgainContainer = styled.div`
    display: flex;
    &svg  {
        cursor: pointer;
    }
`;

export const WatchVideoAgainMessage = styled.span`
    color: ${(props) => props.theme.mainColor};
    font-size: 1.7vh;
    margin-left: 15px;
    cursor: pointer;
`;

export const RecorderContainer = styled.div`
    margin: 20px 0;
    margin-bottom: 0;
`;

export const RecorderFooter = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
`;

export const ButtonContainer = styled.div`
    width: 300px;
`;

export const VideoLoadingPlaceholder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(67, 89, 112, 0.8);
    opacity: 1;
    transition: 0.3s all ease-in-out;
`;
