import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Container,
    TwoColumnsLayout,
    ImageColumn,
    ContentColumn,
    ModuleImage,
    Text,
    TasksList,
    Task,
    TaskLabel,
    InfoBlock,
    RoundedIcon,
    ContentFooter,
    ButtonContainer,
} from './CultureFitIntroduction.style';
import culturalFitImage from 'assets/images/cultural_fit_module.png';
import laptopIcon from 'assets/images/laptop_icon.png';
import { VolumeUpIcon, MobileVideoIcon } from 'evergreen-ui';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';

const CultureFitIntroduction = (props) => {
    const { t } = useTranslation();
    const { onNext } = props;

    return (
        <Container>
            <TwoColumnsLayout>
                <ImageColumn>
                    <ModuleImage src={culturalFitImage} />
                </ImageColumn>
                <ContentColumn>
                    <Text>
                        <h1>{t('CULTURE_FIT_INTRODUCTION.TITLE')}</h1>
                        <p>{t('CULTURE_FIT_INTRODUCTION.VIDEO_MESSAGE')}</p>
                    </Text>
                    <TasksList>
                        <Task>
                            <TaskLabel>
                                {t(
                                    'CULTURE_FIT_INTRODUCTION.TASK_1_CULTURAL_FIT',
                                )}
                            </TaskLabel>
                            <img alt="Laptop icon" src={laptopIcon} />
                        </Task>
                    </TasksList>
                    <InfoBlock>
                        <RoundedIcon>
                            <VolumeUpIcon size={25} color="white" />
                        </RoundedIcon>
                        <p>{t('CULTURE_FIT_INTRODUCTION.BLOCK_SOUND_ON')}</p>
                    </InfoBlock>
                    <InfoBlock>
                        <RoundedIcon>
                            <MobileVideoIcon size={25} color="white" />
                        </RoundedIcon>
                        <p>
                            {t(
                                'CULTURE_FIT_INTRODUCTION.BLOCK_RECORDING_DURING_TASK',
                            )}
                        </p>
                    </InfoBlock>
                    <ContentFooter>
                        <ButtonContainer>
                            <ShadowButton onClick={() => onNext()}>
                                {t(
                                    'CULTURE_FIT_INTRODUCTION.START_TASK_BUTTON',
                                )}
                            </ShadowButton>
                        </ButtonContainer>
                    </ContentFooter>
                </ContentColumn>
            </TwoColumnsLayout>
        </Container>
    );
};

export default CultureFitIntroduction;
