import React, { useState } from 'react';
import {
    Controls,
    ButtonsContainer,
    VideoContainer,
    RecordingLabel,
    CameraContainer,
    VideoCenter,
} from './InterviewRecorder.styled';
import CameraPreview from 'components/shared/CameraPreview';
import VideoComponent from 'components/shared/VideoComponent/VideoComponent';
import { ReactComponent as RecordButtonIcon } from 'assets/icons/video-controls/record-button.svg';
import { ReactComponent as ReplayButtonIcon } from 'assets/icons/video-controls/replay-button.svg';
import { ReactComponent as StopButtonIcon } from 'assets/icons/video-controls/stop-button.svg';
import Timer from 'react-compound-timer';
import { Skeleton } from '@material-ui/lab';

const defaultTimerFormat = (value) =>
    `${value < 10 ? `0${value}:` : `${value}:`}`;

const InterviewRecorder = (props) => {
    const { onRecord, onStopRecording, videoStream, blob, cameraState } = props;

    const { blobDuration, isCameraRecording, isCapturing, videoURL } =
        cameraState;

    const [replayRecording, setReplayRecording] = useState(false);

    return (
        <React.Fragment>
            {/* Loading */}
            {!blob && !isCapturing && (
                <VideoCenter>
                    <CameraContainer>
                        <Skeleton variant="rect" width={1000} height={450} />
                    </CameraContainer>
                </VideoCenter>
            )}
            {/* Live View & Recording - Live feed from user's camera */}
            {!blob && (isCapturing || isCameraRecording) && (
                <VideoCenter>
                    <CameraContainer>
                        <CameraPreview videoStream={videoStream}>
                            {isCameraRecording && (
                                <RecordingLabel
                                    role="timer"
                                    aria-label="recorded duration">
                                    <Timer>
                                        <Timer.Hours
                                            formatValue={defaultTimerFormat}
                                        />
                                        <Timer.Minutes
                                            formatValue={defaultTimerFormat}
                                        />
                                        <Timer.Seconds
                                            formatValue={(value) =>
                                                `${
                                                    value < 10
                                                        ? `0${value}`
                                                        : `${value}`
                                                }`
                                            }
                                        />
                                    </Timer>
                                </RecordingLabel>
                            )}
                        </CameraPreview>
                    </CameraContainer>
                </VideoCenter>
            )}
            {/* Playback - A video blob is already recorded */}
            {!!blob && videoURL && !isCameraRecording && (
                <VideoCenter>
                    <VideoContainer>
                        <VideoComponent
                            videoId={'interview-video'}
                            videoUrl={videoURL}
                            showControls={true}
                            videoDuration={blobDuration}
                            replayRecording={replayRecording}
                            onStopPlayback={() => {
                                setReplayRecording(false);
                            }}
                            isVideoFullscreen={false}
                        />
                    </VideoContainer>
                </VideoCenter>
            )}
            <Controls>
                {/* Button: Record */}
                {!isCameraRecording && (
                    <ButtonsContainer
                        role="button"
                        aria-label="record button"
                        onClick={() => onRecord()}>
                        <RecordButtonIcon />
                        <span>Record</span>
                    </ButtonsContainer>
                )}
                {/* Button: Replay */}
                {!!blob && !isCameraRecording && (
                    <ButtonsContainer
                        role="button"
                        aria-label="replay button"
                        onClick={() => {
                            setReplayRecording(true);
                        }}>
                        <ReplayButtonIcon />
                        <span>Re-play</span>
                    </ButtonsContainer>
                )}
                {/* Button: Stop recording */}
                {isCameraRecording && (
                    <ButtonsContainer
                        role="button"
                        aria-label="stop recording button"
                        onClick={() => {
                            onStopRecording();
                        }}>
                        <StopButtonIcon />
                        <span>Stop</span>
                    </ButtonsContainer>
                )}
            </Controls>
        </React.Fragment>
    );
};

export default InterviewRecorder;
