import styled from 'styled-components';
import {breakpoints} from "../../../App.style";

export const Container = styled.div`
    position: relative;
    height: 100vh;
    margin: 0 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-height: 668px) {
        height: fit-content;
    }
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4vw;
    }
`;

export const TwoColumnsLayout = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;

export const ImageColumn = styled.div`
    display: flex;
    position: relative;
    width: 45vw;
    min-width: 400px;
    max-width: 900px;
    height: auto;
    justify-content: center;
    align-items: center;
`;

export const ModuleImage = styled.img`
    width: 100%;
    height: auto;
`;

export const ContentColumn = styled.div`
    position: relative;
    flex: 1;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
    color: ${(props) => props.theme.textColor};
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.textColor};

    h1,
    p {
        margin: 0;
        padding: 0;
    }

    p {
        margin-top: 15px;
    }
`;

export const TasksList = styled.ul`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    list-style: none;
    margin: 35px 0;
    padding: 0;
`;

export const Task = styled.li`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    max-width: 120px;
    &:first-child {
        margin-right: 50px;
    }
    img {
        height: 100px;
    }
`;

export const TaskLabel = styled.span`
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
    max-width: 120px;
    line-height: 1.2em;
`;

export const InfoBlock = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding: 15px;
    margin-bottom: 15px;
    background: #f4f4f4;
    align-items: center;

    p {
        font-size: 0.9em;
        margin: 0;
        margin-left: 15px;
    }
`;

export const RoundedIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    background: ${(props) => props.theme.mainColor};
`;

export const ContentFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const ButtonContainer = styled.div`
    width: 200px;
    margin-top: 20px;
`;
